import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus-circle.svg';
import { ReactComponent as PenIcon } from '../../assets/icons/pen-mini.svg';

import SidebarBox from '../../components/SidebarBox/SidebarBox';
import EditContactField from '../EditContactField/EditContactField';
import Answer from '../../components/Answer/Answer';
import RichText from '../RichText/RichText';
import Input from '../Input/Input';
import Toggle from "../Toggle/Toggle";
import {updateSceneAnswer, updateAnswer, removeAnswer, updateScene, updateSceneProp} from '../../actions/scenesActions';
import { useTranslation } from '../../utils/useTranslation';
import { validateEmail } from '../../utils/validateEmail';

import styles from './ContactBox.module.scss';
import {hideModal, showModal} from "../../actions/modalActions";
import Button from "../Button/Button";

// Called by SceneTab.js.
function ContactBox({ selectedScene, token, scenes }) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const [addFieldForm, setAddFieldForm] = useState(false);
	const [editFieldForm, setEditFieldForm] = useState({});
	const project = useSelector(state => state.projects.data[0]);
	const projectHasDraftScenes = project.hasDraftScenes;
	const projectHasWebHook = project.webhookURL ? true : false;

	const submitField = (field, index) => {
		const newContact = selectedScene.contact;
		const updatedFields = newContact.fields || [];
		updatedFields[index] = field;
		newContact.fields = updatedFields;
		dispatch(
			updateScene(token, selectedScene._id, { contact: newContact }, projectHasDraftScenes)
		);
		setAddFieldForm(false);
		setEditFieldForm({});
		// setEditFieldForm({[index]: false});
	}

	const deleteField = (index) => {
		const newContact = selectedScene.contact;
		const updatedFields = newContact.fields || [];
		updatedFields.splice(index, 1)
		newContact.fields = updatedFields;
		dispatch(
			updateScene(token, selectedScene._id, { contact: newContact }, projectHasDraftScenes)
		);
	}

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;  // if user drags outside of DragDropContext, do nothing
		const fields = [...selectedScene.contact?.fields];
		const [reorderedField] = fields.splice(result.source.index, 1);
		fields.splice(result.destination.index, 0, reorderedField);
		dispatch(
			updateSceneProp(selectedScene._id, 'contact', {
				...selectedScene.contact,
				fields: fields,
			})
		);
	}

	const SendEmailOption = () => {
		const currentState = selectedScene?.contact?.webhookOnly;

		return (
			<div className={styles.checkboxWrapper}>
				<Toggle
					checked={currentState}
					single={true}
					onChange={() => {
						dispatch(updateScene(
							token,
							selectedScene._id,
							{ contact: { ...selectedScene.contact, webhookOnly: !currentState } },
							projectHasDraftScenes
						));
						if (!currentState && !projectHasWebHook) { // currentState still carries the old value here...
							dispatch(
								showModal('POPUP_MODAL', {
									title: t.sceneTab.webhookMissing,
									subtitle: t.sceneTab.pleaseAddWebhook,
									buttonPrimary: {
										label: t.popup.okay,
										action: () => {
											dispatch(hideModal());
										},
									},
								})
							);
						}
					}}
				/>
				<label className={styles.checkboxLabel}>{t.sceneTab.webhookOnly}</label>
			</div>
		);
	}

	if(!selectedScene.contact) {  // add contact to old scenes without it
		dispatch(
			updateScene(token, selectedScene._id, { contact: { emailLink: ''} }, projectHasDraftScenes)
		);
		return null;
	} else {
		return (
			<SidebarBox>
				{/* temporary style copied from ImageTextBox */}
				<div className={styles.inputGroupDescription}>
					<label className={styles.descriptionLabel}>{t.sceneTab.description}</label>
					<RichText
						selectedScene={selectedScene}
						onChange={(e) => {
							dispatch(
								updateSceneProp(selectedScene._id, 'contact', {
									...selectedScene.contact,
									description: e,
								})
							);
						}}
						onBlur={() => {
							dispatch(
								updateScene(
									token,
									selectedScene._id,
									{ contact: selectedScene.contact },
									projectHasDraftScenes
								)
							);
						}}
						defaultValue={selectedScene.contact?.description || t.sceneTab.description}
					/>
				</div>

				<div className={styles.box}>
					<label className={styles.label}>{t.sceneTab.contactSection.contactFields}</label>
					<hr style={{margin: 0 }}/>
					<DragDropContext onDragEnd={handleOnDragEnd}>
						<Droppable droppableId="proFields">
    					{(provided) => (<div {...provided.droppableProps} ref={provided.innerRef}>
								{selectedScene.contact?.fields && selectedScene.contact.fields.length > 0 &&
									selectedScene.contact.fields.map((field, idx) => {
										return (
											<Draggable key={field.name + idx} draggableId={field.name} index={idx}>
												{(provided) => (
													<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
														<div className={styles.fieldRow}>
																<div className={styles.label}>{field.name}</div>
															{!editFieldForm[idx] ?
																<PenIcon
																	alt="PenIcon"
																	className={styles.icon}
																	onClick={() => setEditFieldForm({[idx]: true})}
															/>
															: <Button secondary small label={t.sceneTab.save}
																									onClick={()=> submitField(field, idx)}
																									// style={{ marginTop: '5px', marginLeft: '102px', fontSize: '12px', padding: '2px'}}
																/>
															}
																<MinusIcon
																	className={styles.minusIcon}
																	onClick={() => deleteField(idx)}
																/>
														</div>

														{editFieldForm[idx] &&
															<div style={{marginBottom: 15, marginTop: 10}}>
																<EditContactField
																	className={styles.editFieldRow}
																	selectedScene={selectedScene}
																	submitField={submitField}
																	currentField={field}
																	fieldIndex={idx}
																/>
															</div>
														}
													</div>
												)}
											</Draggable>)
								})}
								{provided.placeholder}
							</div>)}
						</Droppable>
					</DragDropContext>
					<div className={styles.addFieldInputGroup}>
						<label className={styles.addFieldLabel}>{t.sceneTab.addContactField}</label>
						<PlusIcon className={styles.plusIcon} onClick={() => setAddFieldForm(true)} />
					</div>
					{addFieldForm &&
						<EditContactField
							selectedScene={selectedScene}
							submitField={submitField}
							fieldIndex={selectedScene.contact?.fields ? selectedScene.contact.fields.length : 0}
							showSaveBtn
						/>
					}

					<SendEmailOption />
					<div>
						<label className={classNames(styles.label, styles.lightLabel)}>{t.sceneTab.contactSection.email}:</label>
						<Input
							type="text"
							name="contact"
							value={selectedScene.contact.emailLink}
							onChange={(e) =>
								dispatch(
									updateSceneProp(selectedScene._id, e.target.name, {
										...selectedScene.contact,
										emailLink: e.target.value,
									})
								)
							}
							onBlur={() => {
								if (
									selectedScene.contact.emailLink.length === 0 ||
									validateEmail(selectedScene.contact.emailLink)
								) {
									dispatch(
										updateScene(
											token,
											selectedScene._id,
											{ contact: selectedScene.contact },
											projectHasDraftScenes
										)
									);
								}
							}}
							ternary
							error={
								selectedScene?.contact?.emailLink?.length > 0 &&
								!validateEmail(selectedScene.contact.emailLink) &&
              t.login.emailInvalid
							}
						/>
					</div>

					<div>
						<label className={classNames(styles.label, styles.lightLabel)}>{t.sceneTab.contactSection.ccEmail}:</label>
						<Input
							type="text"
							name="contact"
							placeholder={selectedScene.contact.ccEmail || t.sceneTab.contactSection.ccEmailPlaceholder}
							value={selectedScene.contact.ccEmail}
							onChange={(e) =>
								dispatch(
									updateSceneProp(selectedScene._id, e.target.name, {
										...selectedScene.contact,
										ccEmail: e.target.value,
									})
								)
							}
							onBlur={() => {
								if (
									(selectedScene.contact.ccEmail && selectedScene.contact.ccEmail.length === 0) ||
									validateEmail(selectedScene.contact.ccEmail)
								) {
									dispatch(
										updateScene(
											token,
											selectedScene._id,
											{ contact: selectedScene.contact },
											projectHasDraftScenes
										)
									);
								}
							}}
							ternary
							error={
								selectedScene?.contact?.ccEmail?.length > 0 &&
								!validateEmail(selectedScene.contact.ccEmail) &&
              t.login.emailInvalid
							}
						/>
					</div>
					<div className={styles.inputGroup}>
						<Answer
							key={0}
							index={0}
							answer={selectedScene.answers[0]}
							scenes={scenes}
							selectedScene={selectedScene}
							token={token}
							updateSceneAnswer={updateSceneAnswer}
							updateAnswer={updateAnswer}
							removeAnswer={removeAnswer}
							contact
						/>
					</div>
				</div>
			</SidebarBox>
		);
	}
}

export default ContactBox;
