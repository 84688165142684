import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, showModal } from '../../actions/modalActions';

import SceneBasicSettings from '../../components/SceneBasicSettings/SceneBasicSettings';
import AnswersBox from '../../components/AnswersBox/AnswersBox';
import ImageTextBox from '../../components/ImageTextBox/ImageTextBox';
import AudioBox from '../../components/AudioBox/AudioBox';
import VideoBox from '../../components/VideoBox/VideoBox';
import ContactBox from '../../components/ContactBox/ContactBox';
import JsonBox from '../../components/JsonBox/JsonBox';
import CodeSnippetBox from '../../components/SceneCodeSnippet/SceneCodeSnippet';
import CustomCssBox from '../../components/CustomCSSBox/CustomCSSBox';
import SceneEditFooterBox from '../../components/FooterBox/SceneEditFooterBox';
import { updateAllScenes, updateScene, deleteScenes, updateSceneProp } from '../../actions/scenesActions';
import { useTranslation } from '../../utils/useTranslation';

import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';
import HtmlBox from '../../components/HtmlBox/HtmlBox';
import { checkForFirstScene } from '../../utils/checkForFirstScene';
import FreeTextBox from '../../components/FreeTextBox/FreeTextBox';

function SceneTab(props) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const token = useSelector((state) => state.auth.token);
	const scenes = useSelector((state) => state.scenes.data);
	const selectedScene = props.selectedSceneIds.length === 1 && scenes.filter((scene) => scene._id === props.selectedSceneIds[0])[0];
	const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);
	const response = useSelector((state) => state.scenes.response);
	const [warning, setWarning] = useState(false);

	return (
		<React.Fragment>
			<SceneBasicSettings  //renders the dropdown, the question and the first-scene checkbox
				selectedScene={selectedScene}
			/>
			{(selectedScene.template === 'text_image' ||
				selectedScene.template === 'images' ||
				selectedScene.template === 'text' ||
				selectedScene.template === 'json') && (
				<ImageTextBox  //renders 'add picture', upload + conditions, alt meta tag for 'text_image' templates,
					dispatch={dispatch}  // and scene description and price for all 4 templates.
					updateScene={updateScene}
					token={token}
					selectedScene={selectedScene}
					updateSceneProp={updateSceneProp}
				/>
			)}
			{selectedScene &&
				(selectedScene.template === 'text' ||
				selectedScene.template === 'text_image' ||
				selectedScene.template === 'images' ||
				selectedScene.template === 'columns' ||
				selectedScene.template === 'freeText') && (
					<AudioBox
						token={token}
						selectedScene={selectedScene}
						autoplay={false}
					/>
			)}
			{selectedScene && selectedScene.template === 'video' && (
				<VideoBox
					token={token}
					selectedScene={selectedScene}
					autoplay={false}
				/>
			)}
			{selectedScene &&
				selectedScene.template !== 'contact' &&
				selectedScene.template !== 'json' &&
				selectedScene.template !== 'freeText' && (
					<AnswersBox
						dispatch={dispatch}
						updateScene={updateScene}
						token={token}
						selectedScene={selectedScene}
						scenes={scenes}
					/>
			)}
			{selectedScene.template === 'contact' && (
				<ContactBox
					token={token}
					selectedScene={selectedScene}
					scenes={scenes}
				/>
			)}
			{selectedScene.template === 'json' && (
				<JsonBox
					token={token}
					selectedScene={selectedScene}
					scenes={scenes}
				/>
			)}
			{ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) &&
				selectedScene.template === 'freeText' && (
					<FreeTextBox
						dispatch={dispatch}
						updateScene={updateScene}
						updateSceneProp={updateSceneProp}
						token={token}
						selectedScene={selectedScene}
						scenes={scenes}
					/>
			)}
			{ifUserRole(ROLES.ADMIN) &&
				selectedScene.template === 'html' && (
					<HtmlBox
						dispatch={dispatch}
						updateScene={updateScene}
						updateSceneProp={updateSceneProp}
						token={token}
						selectedScene={selectedScene}
						scenes={scenes}
					/>
			)}


			{ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) && (
				<>
					<div style={{marginTop: 20, paddingTop: 20, borderTop: '1px solid #ccc'}}>
						<h4 style={{paddingLeft: 30, fontSize: 15, fontWeight: 'bold', color: '#555', marginBottom: 20}}>{t.sceneTab.advancedSceneSettings}</h4>
					</div>
					<CustomCssBox
						dispatch={dispatch}
						scope={'scene'}
						updateSceneOrProject={updateScene}
						token={token}
						selectedSceneOrProject={selectedScene}
						updateSceneOrProjectProp={updateSceneProp}
					/>
					<CodeSnippetBox
						dispatch={dispatch}
						updateScene={updateScene}
						token={token}
						selectedScene={selectedScene}
						updateSceneProp={updateSceneProp}
					/>
				</>
			)}

			{/*This shows scene Edit Additional settings*/}
			<SceneEditFooterBox
				deleteSceneLinkLabel={t.sceneTab.deleteScene}
				onDeleteLinkClick={() => {
					dispatch(
						showModal('POPUP_MODAL', {
							title: t.sceneTab.confirmDelete,
							buttonPrimary: {
								label: t.popup.yes,
								action: () => {
									dispatch(deleteScenes(token, scenes[0].projectId, props.selectedSceneIds, projectHasDraftScenes));
									props.setSelectedSceneIds([]);
									dispatch(hideModal());
								},
							},
							buttonSecondary: {
								label: t.popup.no,
								action: () => {
									dispatch(hideModal());
								},
							},
						})
					);
				}}
				buttonLabel={t.sceneTab.save}
				onButtonClick={() => {
					dispatch(updateAllScenes(token, scenes, projectHasDraftScenes));
					props.setSelectedSceneIds([]);
					setWarning(true);
					checkForFirstScene(scenes, dispatch, t);
				}}
				warning={warning && response}
			/>
		</React.Fragment>
	);
}

export default SceneTab;
