import React from "react";
import { SUPPORTED_FORMATS } from '../../utils/constants/mediaFormats'
import LottiePlayer from "../Lottie/LottiePlayer";

/* called from ColumnsTemplate.js and ImageUpload.js
* analyzes URL and if it has a lottie extension it renders the lottie,
* otherwise it renders the normal image
* */
const ImageRender = ({ imageUrl, className, alt }) => {

  const isLottie = SUPPORTED_FORMATS.LOTTIE.includes(imageUrl.split('.').pop());

  return (<>
    {isLottie ? (
      <LottiePlayer src={imageUrl} />
    ) : (
      <img src={imageUrl} className={className} alt={alt} />
    )}
  </>)
}
export default ImageRender;
