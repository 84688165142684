import axios from "axios";
import { getUserAction } from './userActions';
import {authHeaders} from "../utils/authHeaders";
export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';
export const AUTH_USER_RESPONSE = 'AUTH_USER_RESPONSE';
export const LOGOUT_USER = 'LOGOUT_USER';

const apiUrl = process.env.REACT_APP_BE_URL;

/**
 * Sets auth state with auth data
 * @param {Object} data containing authenticated and jwt token
 * @param {boolean} data.isAuth determining if user is authenticated
 * @param {string} data.token jwt token
 * @returns {void}
 */
export const authUser = (data) => {
  return {
    type: AUTH_USER,
    payload: data,
  };
};

/**
 *
 * @param {string} data containing error
 * @returns {void}
 */
export const setAuthError = (data) => {
  return {
    type: AUTH_USER_ERROR,
    payload: data,
  };
};

export const setAuthResponse = (response) => {
  return {
    type: AUTH_USER_RESPONSE,
    payload: response,
  };
};

/**
 * @param {string} email
 * @param {string} password
 * @returns {Promise<Object>} user object or undefined
 */
export const fetchLogin = async (email, password) => {
  try {
    const res = await fetch(apiUrl + '/users/login', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ email: email, password: password }),
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Checks user credentials and sets authorization in localstorage and Redux.
 * @param {string} email
 * @param {string} password
 * @returns {function}
 */
export const loginUser = (email, password) => {
  return (dispatch) => {
    fetchLogin(email, password).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          if (typeof resJSON.error === "string") {
            dispatch(setAuthError(resJSON.error));
          } else {
            dispatch(setAuthError("network error"));
          }
        } else {
          localStorage.setItem('token', resJSON.token);
          localStorage.setItem('username', resJSON.user.username);
          localStorage.setItem('id', resJSON.user._id.toString());
          dispatch(authUser({ isAuth: true, token: resJSON.token }));
          dispatch(getUserAction(resJSON.user));
        }
      } else {
        console.error(resJSON);
        dispatch(setAuthError("network error"));
      }
    });
  };
};

/**
 * Called by LoginFromEmail.js
 * Checks link token and if all OK sets authorization in localstorage and Redux.
 * @param {string} email
 * @param {string} password
 * @returns {Object} { message: 'ok'} or { error }
 */
export const loginFromEmailLink =  (urlToken) => {
  // console.log('urlToken:', urlToken)
  return async (dispatch) => {
    let result;
    await axios
      .post(
        `${apiUrl}/users/login-from-email-link`,
        { urlToken },
      ).then(res => {
        // console.log('res.data:', res.data)
        if (res.data.error) {
          console.error(res.data.error);
          result = { error: res.data.error };
        } else {
          const { token, user } = res.data;
          localStorage.setItem('token', token);
          localStorage.setItem('username', user.username);
          localStorage.setItem('id', user._id.toString());
          dispatch(authUser({ isAuth: true, token }));
          dispatch(getUserAction(user));
          result = { message: 'ok' };
        }
      }).catch(err => {
        console.error(err);
        result = { error: err.toString() };
      })
    return result;
  };
};

/**
 * Checks if user with email exists.
 * If so, returns success message while BE sends email with login link.
 * If not, returns error message
 * @param {string} email
 */
export const loginViaEmailLink = async (email) => {
  let result;
  await axios
    .post(
      `${apiUrl}/users/login-via-email-link`,
      { email },
    ).then(res => {
      // console.log('res.data:', res.data)
      if (res.data.error) {
        console.error(res.data.error);
        result = { error: res.data.error };
      } else {
        result = { message: res.data.message };
      }
    }).catch(err => {
      console.error(err);
      result = { error: err.toString() };
    })
  return result;
};

/**
 * @param {string} username
 * @param {string} email
 * @param {string} password
 * @param {boolean} acceptedTOS has user accepted Terms of Service
 * @returns {Promise<Object>} user object or undefined
 */
export const fetchRegister = async (username, email, password, acceptedTOS, brandname) => {
  try {
    const res = await fetch(apiUrl + '/users', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        email: email,
        password: password,
        acceptedTOS: acceptedTOS,
        belongsTo: brandname,
      }),
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * @param {string} username
 * @param {string} email
 * @param {string} password
 * @param {boolean} acceptedTOS has user accepted Terms of Service
 * @returns {Promise<any>} user object or undefined
 */
export const registerUser = (username, email, password, acceptedTOS, brandname) => {
  return (dispatch) => {
    fetchRegister(username, email, password, acceptedTOS, brandname).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setAuthError(resJSON.error));
        } else {
          console.log(resJSON);
          dispatch(setAuthResponse(resJSON.message));
        }
      } else {
        console.error(resJSON);
        dispatch(setAuthError(resJSON));
      }
    });
  };
};
/**
 *
 * @param {string} email
 */
export const fetchForgotPassword = async (email) => {
  try {
    const res = await fetch(apiUrl + '/users/forgot-password', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
/**
 * Sends email with link to reset password
 * @param {string} email of a user that forgot password
 */
export const sendForgotPasswordEmail = (email) => {
  return (dispatch) => {
    fetchForgotPassword(email).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setAuthError(resJSON.error));
        } else {
          dispatch(setAuthResponse(resJSON.message));
        }
      } else {
        console.error(resJSON);
        dispatch(setAuthError(resJSON));
      }
    });
  };
};

/**
 * Resets a non-logged-in user's password
 * @param {string} urlToken of the user from forgot-password mailjet email
 * @param {string} newPassword of the user that forgot their password
 */
export const resetPassword = (urlToken, newPassword) => {
  return (dispatch) => axios
    .patch(
      `${apiUrl}/users/reset-password/${urlToken}`,
      { password: newPassword },
    ).then(res => {
      if (res.data.error) {
        console.error(res.data.error);
        dispatch(setAuthError(res.data.error));
      } else {
        dispatch(setAuthResponse(res.data.message))
      }
    }).catch(err => {
      console.error(err);
      dispatch(setAuthError(err));
    })
};

/**
 * Allows a logged-in user to change their password
 * @param {string} oldPassword
 * @param {string} newPassword
 */
export const changePassword = async (oldPassword, newPassword) => {
  let result;
  await axios
    .patch(
      `${apiUrl}/users/change-password`,
      { oldPassword, newPassword },
      authHeaders()
    ).then(res => {
      console.log('res.data:', res.data)
      if (res.data.error) {
        console.error(res.data.error);
        result = { error: res.data.error };
      } else {
        result = { message: res.data.message };
      }
    }).catch(err => {
      console.error(err);
      result = { error: err.toString() };
    })
  return result;
};

/**
 *
 * @param {string} email
 */
export const fetchVerifyAccount = async (token) => {
  try {
    const res = await fetch(apiUrl + '/users/verifyEmail/' + token, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Verifies account
 * @param {string} token
 */
export const verifyAccount = (token) => {
  return (dispatch) => {
    fetchVerifyAccount(token).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setAuthError(resJSON.error));
        } else {
          dispatch(setAuthResponse(resJSON.message));
        }
      } else {
        console.error(resJSON);
        dispatch(setAuthError(resJSON));
      }
    });
  };
};

export const fetchLogout = async (token) => {
  try {
    const res = await fetch(apiUrl + '/users/logout', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const logoutUser = (token) => {
  return (dispatch) => {
    fetchLogout(token).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setAuthError(resJSON.error));
          dispatch({
            type: 'LOGOUT_USER',
          });
        } else {
          dispatch(setAuthResponse(resJSON.message));
          dispatch({
            type: 'LOGOUT_USER',
          });
        }
      } else {
        console.error(resJSON);
        dispatch(setAuthError(resJSON));
        dispatch({
          type: 'LOGOUT_USER',
        });
      }
    });
  };
};
