import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import Button from '../../components/Button/Button';
import { useTranslation } from '../../utils/useTranslation';
import { verifyAccount } from '../../actions/authActions';

import styles from './AccountVerified.module.scss';
import { ReactComponent as Logo } from '../../assets/images/logo-clickmaster.svg';

function AccountVerified() {
	const t = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	let query = useQuery();

	useEffect(() => {
		dispatch(verifyAccount(query.get('token')));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<div className={styles.textWrapper}>
					<h1 className={styles.title}>
						{t.accountVerified.registry}
						<br /> {t.accountVerified.success}
					</h1>
				</div>
			</div>
			<div className={styles.form}>
				<Logo className={styles.logo} alt="logo" />
				<h2 className={classNames(styles.text, { [styles.error]: auth.error })}>
					{auth.error === '' ? t.accountVerified.accountActive : t.accountVerified.error}
				</h2>
				<div className={styles.submitWrapper}>
					<Button label={t.register.loginButtonLabel} onClick={() => history.push('/login')} primary big />
				</div>
			</div>
		</div>
	);
}

export default AccountVerified;
