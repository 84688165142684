import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ReactComponent as PenIcon } from '../../assets/icons/pen-mini.svg';
import SidebarBox from '../SidebarBox/SidebarBox';
import Input from '../Input/Input';
import SelectInput from '../SelectInput/SelectInput';
import Toggle from '../Toggle/Toggle'
import { useTranslation } from '../../utils/useTranslation';
import { updateScene, updateSceneProp } from '../../actions/scenesActions';
import { createSceneTemplateOptions } from "./createSceneTemplateOptions";
import {TEMPLATE_DATA, TEMPLATE_DATA_ADMIN, TEMPLATE_DATA_PROFESSIONAL} from "../../constants/SceneConstants";
import {ifUserRole} from "../../utils/ifUserRole";
import {ROLES} from "../../utils/constants/roles";
import styles from './SceneBasicSettings.module.scss';

// Renders the dropdown, the question and the first-scene checkbox
// Called by SceneTab.js
function SceneBasicSettings( { selectedScene }) {
  const t = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const scenes = useSelector((state) => state.scenes.data);
  const token = useSelector((state) => state.auth.token);
  const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);
  const [showInput, setShowInput] = useState(false);

  let templateData = TEMPLATE_DATA;
  if (ifUserRole(ROLES.PROFESSIONAL)) templateData = TEMPLATE_DATA_PROFESSIONAL;
  if (ifUserRole(ROLES.ADMIN)) templateData = TEMPLATE_DATA_ADMIN;

  useEffect(() => {
    if (showInput) {
      document.getElementById('sceneTitle').focus();
    }
    document.documentElement.setAttribute('data-color-mode', 'light'); // this one sets light mode on for <CodeEditor>
  }, [showInput]);

  function setFirstScene(isChecked) {
    selectedScene.isFirstScene = isChecked;
    return selectedScene;
  }

  function checkAnyOtherFirstScene() {
    for (let scene of scenes) {
      if (scene._id === selectedScene._id) {
        continue;
      }
      if (scene.isFirstScene) {
        scene.isFirstScene = false;
        dispatch(updateScene(token, scene._id, scene, projectHasDraftScenes));
      }
    }
  }

  function updateSceneTitle () {
    dispatch(
      updateScene(
        token,
        selectedScene._id,
        { title: selectedScene.title },
        projectHasDraftScenes
      )
    );
    setShowInput(false);
  }

  const StartSceneCheckbox = () =>  {
    return (<div className={styles.checkboxWrapper}>
        <Toggle
          checked={selectedScene?.isFirstScene}
          single={true}
          onChange={() => {
            dispatch(
              updateScene(
                token,
                selectedScene._id,
                setFirstScene(!selectedScene?.isFirstScene),
                projectHasDraftScenes
              )
            );
            checkAnyOtherFirstScene();
          }}
        />
      <label className={styles.checkboxLabel}>{t.sceneTab.setFirstScene}</label>
    </div>
    )}

  const BackButton = () => {
    return (
      <div className={styles.checkboxWrapper}>
        <Toggle
          checked={selectedScene?.hasBackButton}
          single={true}
          onChange={() => {
            dispatch(updateScene(token, selectedScene._id, { hasBackButton: !selectedScene.hasBackButton }, projectHasDraftScenes));
          }}
        />
        <label className={styles.checkboxLabel}>{t.sceneTab.backButton}</label>
      </div>
    );
  }

  const SceneTitle = () => {
    return (
      <div className={styles.titleWrapper}>
        { showInput ? (
        <input
          id="sceneTitle"
          type="text"
          value={selectedScene && selectedScene.title}
          name="title"
          onChange={(e) => {
            if (e.target.value.length <= 12) {
              dispatch(updateSceneProp(selectedScene._id, e.target.name, e.target.value));
            }
          }}
          onBlur={() => updateSceneTitle()}
          className={styles.titleInput}
          autoFocus
        />
      ):(<h2 className={styles.sceneTitle}>
        {selectedScene?.title}
        <PenIcon
          alt="PenIcon"
          className={styles.icon}
          onClick={() => {
            setShowInput(true);
          }}
        />
      </h2>)}
    </div>
    )
  }

  return (
    <SidebarBox>
      <SceneTitle />
      <StartSceneCheckbox />
      <BackButton />
      <div className={classNames(styles.inputGroup, styles.zIndex)}>
         <label className={styles.label}>{t.sceneTab.template}</label>
        <SelectInput
          options={createSceneTemplateOptions(templateData, selectedScene, projectHasDraftScenes, user, token, dispatch)}
          selected={selectedScene ? selectedScene.template : 'json'}
          ternary
          noScroll
          disabled={!selectedScene._id}
        />
      </div>
      <div className={styles.inputGroup}>
        <label className={styles.label}>{t.sceneTab.question}</label>
        <Input
          type="textarea"
          value={selectedScene && selectedScene.question}
          name="question"
          onChange={(e) => {
            if (e.target.value.length <= 100) {
              dispatch(updateSceneProp(selectedScene._id, e.target.name, e.target.value));
            }
          }}
          onBlur={() =>
            dispatch(
              updateScene(
                token,
                selectedScene._id,
                { question: selectedScene.question },
                projectHasDraftScenes
              )
            )
          }
          secondary
          rows="3"
          error={selectedScene && selectedScene.question.length >= 100 && t.sceneTab.errors.question}
          errorstyle={{ top: '78px', left: '15px', textAlign: 'left', fontSize: '12px' }}
        />
      </div>
    </SidebarBox>
  );
}

export default SceneBasicSettings;
