import { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from '../../utils/useTranslation';
import styles from './RichText.module.scss';

const modules = {
	toolbar: [['bold', 'italic', 'underline', 'strike', { list: 'ordered' }, { list: 'bullet' }]],
};

const allowedFormats = ['bold', 'italic', 'underline', 'strike', 'list'];

const modulesLight = {
	toolbar: [['bold', 'italic', 'underline', 'strike'], ['link']],
};

function RichText(props) {
	const t = useTranslation();
	const reactQuillRef = useRef(null);
	const [error, setError] = useState(false);


	useEffect(() => {
		const toolbar = document.querySelector('.ql-toolbar');
		const handle = (e) => {
			e.preventDefault();
		};
		toolbar.addEventListener('mousedown', handle);
		return () => toolbar.removeEventListener('mousedown', handle);
	}, []);

	/* Old version (before 14.11.2022):
	useEffect(() => {
		if (
			props.selectedScene.template === 'columns' ||
			props.selectedScene.template === 'text_image' ||
			props.selectedScene.template === 'contact' ||
			props.selectedScene.template === 'freeText' ||
			props.selectedScene.template === 'video'
		) {
			const toolbar = document.querySelector('.ql-toolbar');
			const handle = (e) => {
				e.preventDefault();
			};
			toolbar.addEventListener('mousedown', handle);
			return () => toolbar.removeEventListener('mousedown', handle);
		}
	}, [props.selectedScene.template]);
	*/

	function checkCharacterCount(e) {
		const editor = reactQuillRef.current.editor;
		if (props.light && editor.getLength() > 135 && e.key !== 'Backspace') {
			e.preventDefault();
			setError(true);
		} else if (editor.getLength() > 200 && e.key !== 'Backspace') {
			e.preventDefault();
			setError(true);
		} else {
			setError(false);
		}
	}

	function onChange(e) {
		if (e.includes('<a href=')) {
			props.onBlur(e);
		} else {
			if (e === '<p><br></p>') props.onChange('');
			else props.onChange(e);
		}
	}

	return (
		<div
			className={styles.container}
			onBlur={() => {
				props.onBlur();
			}}
		>
			<ReactQuill
				key={props.selectedScene._id}
				className={styles.richText}
				defaultValue={props.defaultValue}
				onChange={onChange}
				modules={props.light ? modulesLight : modules}
				formats={ allowedFormats }
				ref={reactQuillRef}
				onKeyDown={checkCharacterCount}
			/>
			<div className={styles.error}>{error && t.sceneTab.errors.answer}</div>
		</div>
	);
}

export default RichText;
