import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Dashboard from "../../components/Dashboard/Dashboard";
import SelectInput from "../../components/SelectInput/SelectInput";
import Button from "../../components/Button/Button";
import {checkSubscription, updateUser} from "../../actions/userActions";
import {useTranslation} from "../../utils/useTranslation";
import {DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES} from "../../utils/constants/languages";
import {ROLES} from "../../utils/constants/roles";
import styles from "../UserSettings/UserSettings.module.scss";

function UserSettings() {
  const t = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.auth.token);
  const [subStatus, setSubStatus] = useState('unknown');
  const [showChangeEmailBtn, setShowChangeEmailBtn] = useState(false);

  useEffect(()=>{
    if (user.username) {
      const checkStatus = async () => {
        const status = await checkSubscription(user?._id, user?.stripe?.subscription);
        if (status) setSubStatus(status);
      }
      checkStatus().then();
    }
  },[user])
  const getLanguageOptions = () => {
    const options = SUPPORTED_LANGUAGES.map( lang => {
      return {
        value: lang,
        label: lang,
        onClick: () => dispatch(updateUser(token, { ...user, language: lang})),
      }
    })
    return options
  }

  const subscriptionBtnLabel = () => {
    return user?.stripe?.subscription ? t.userSettings.manageSubscription
      : t.userSettings.upgrade
  }
  /* returns a boolean */
  const subscriptionBtnPrimary = () => {
    return !!(user?.stripe?.subscription || user.role === ROLES.FREE || user.role === ROLES.PREMIUM);
  }
  const subscriptionBtnOnClick = () => {
    return user?.stripe?.subscription ?
      () => window.location.href=`https://billing.stripe.com/p/login/test_9AQ6qL8FydEH9sAbII/?prefilled_email=${user.email}`
      : () => history.push('/upgrade')
  }
  /* returns a boolean */
  const subscriptionBtnDisabled = () => {
    return (!user?.stripe?.subscription && (user.role === ROLES.PROFESSIONAL || user.role === ROLES.ADMIN));
  }

  return (
    <Dashboard>
      <div className={styles.container}>
        <h1 className={styles.title}>{t.userSettings.myProfile}</h1>

        <div className={styles.settingsContainer}>
          <div className={styles.language}>
            <label className={styles.languageLabel}>
              {t.userSettings.changeLanguage}:
            </label>
            <div className={styles.languageSelect}>
              <SelectInput
                options={getLanguageOptions()}
                selected={user.language ? user.language : DEFAULT_LANGUAGE}
                ternary
                noScroll
              />
            </div>
          </div>
          <div className={styles.subscriptionBox}>
            <label className={styles.languageLabel}>
              {t.navbar.mySubscription}:
            </label>
            <div className={styles.subscription}>
              <div className={styles.role}>
                {user.role}
              </div>
              <div className={styles.subscriptionStatus}>
                {subStatus}
              </div>
              <Button small label={subscriptionBtnLabel()}
                      primary={subscriptionBtnPrimary()}
                      onClick={subscriptionBtnOnClick()}
                      disabled={subscriptionBtnDisabled()}
              />
            </div>
          </div>

          <div className={styles.passwordBox}>
            <label className={styles.languageLabel}>
              {t.userSettings.password}:
            </label>
            <Button small primary label={t.userSettings.changePassword}
                    onClick={() => history.push('/change-password')}
            />
          </div>

          <div className={styles.passwordBox}>
            <label className={styles.languageLabel}>
              {t.userSettings.email}:
            </label>
            <div
              className={styles.emailOrBtnBox}
              // use timeouts to avoid fluttering at the edge of the button and to avoid occasional unwanted resets to true on mouseOut
              onMouseOver={()=> setTimeout(()=> setShowChangeEmailBtn(true), 100)}
              onMouseOut={()=> setTimeout(()=> setShowChangeEmailBtn(false), 100)}
            >
              {showChangeEmailBtn ?
                  <Button small primary label={t.userSettings.changeEmail}
                           onClick={() => history.push('/change-email')}
                  />
                :
                <label className={styles.languageLabel} >
                  {user.email}
                </label>
              }
            </div>
          </div>

        </div>

      </div>
    </Dashboard>
  );
}

export default UserSettings;
