import React, { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-bgcolor.svg';
import { ReactComponent as DoneIco } from '../../assets/icons/done.svg';
import SidebarBox from '../SidebarBox/SidebarBox';
import VideoUpload from '../VideoUpload/VideoUpload';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import ProgressBar from '../ProgressBar/ProgressBar';
import RichText from '../RichText/RichText';
import { useTranslation } from '../../utils/useTranslation';
import { updateScene, updateSceneProp } from "../../actions/scenesActions";
import styles from './VideoBox.module.scss';

function VideoBox(props) {
  const t = useTranslation();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isVideoUploaded, setVideoUploaded] = useState(false);
  const [videoName, setVideoName] = useState('');
  const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

  function getProgress(progress) {
    if (progress > 0 && progress < 100) {
      setIsUploading(true);
    } else {
      setIsUploading(false);
    }
    if (progress === 100) {
      setVideoUploaded(true);
      setIsUploading(false);
    }
    setProgress(progress);
  }

  function getVideoName(videoName) {
    setVideoName(videoName);
  }

  const deleteVideo = async () => {
    const scene = props.selectedScene;
    // await deleteCloudinaryVideo(scene.videoFilePublicId, dispatch); // obsolete with draft/publish
    const token = props.token;
    const sceneID = scene._id;
    scene.videoFileUrl = "";
    scene.videoFileName = "";
    scene.videoFilePublicId = "";
    dispatch(updateScene(token, sceneID, scene, projectHasDraftScenes));
  }

  return (
    <SidebarBox>
      <div>
        <div className={styles.inputGroup}>
          <label className={styles.label}>{t.sceneTab.description}</label>
          <RichText
            selectedScene={props.selectedScene}
            onBlur={() =>
              dispatch(
                updateScene(
                  props.token,
                  props.selectedScene._id,
                  { richText: props.selectedScene.richText },
                  projectHasDraftScenes
                )
              )
            }
            onChange={(e) => {
              dispatch(updateSceneProp(props.selectedScene._id, 'richText', e));
            }}
            defaultValue={props.selectedScene.richText}
          />
        </div>

        <div className={styles.inputGroup}>
          {isVideoUploaded && props.selectedScene.videoFileUrl ? (
            <div className={styles.videoUploadedBox}>
              <span className={styles.videoUploadedLabel}>{videoName}</span>
              <DoneIco className={styles.doneIcon} />
            </div>
          ) : (
            <div>
              <label className={styles.addVideoLabel}>{t.videoTemplate.addVideo}</label>
              {isUploading === false && (
                <div className={styles.imageRow}>
                  <VideoUpload
                    token={props.token}
                    selectedScene={props.selectedScene}
                    getProgress={getProgress}
                    isUploading={isUploading}
                    getVideoName={getVideoName}
                  />
                </div>
              )}
              {progress > 0 && progress < 100 && (
                <div className={styles.uploadingContainer}>
                  <h6 className={styles.uploadFileLabel}>
                    {t.audioVoiceover.uploadingFile} {parseInt(progress)}%
                  </h6>
                  <ProgressBar percentage={progress} />
                </div>
              )}
            </div>
          )}
        </div>

        {props.selectedScene.videoFileUrl ? (
          <div>
            <div className={styles.titleBox}>
              <h2 className={styles.title}>{props.selectedScene.videoFileName}</h2>
                <span className={styles.delete}>
                  <DeleteIcon
                   className={styles.deleteIcon}
                   onClick={deleteVideo}
                   title={t.videoTemplate.deleteVideo}
                  />
                </span>
            </div>
            <div className={styles.inputGroup}>
              <div className={styles.imageRow}>
                <VideoPlayer videoUrl={props.selectedScene.videoFileUrl} sidebar={true} />
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </SidebarBox>
  );
}

export default VideoBox;
