/* TODO: remove the unused action creators and corresponding reducers.
	 Then consider whether it makes sense to keep fetchGetSubmissions and
	 fetchDownloadSubmissions here, or if it would be better to move them
	 either into Submissions.js, into the Submissions folder or into a new
	 folder called 'API calls', for example.
*/
export const GET_SUBMISSIONS = 'GET_SUBMISSIONS';
export const CAN_ACCESS = 'CAN_ACCESS';
export const SUBMISSIONS_ERROR = 'SUBMISSIONS_ERROR';

const apiUrl = process.env.REACT_APP_BE_URL;
const token = localStorage?.token;

export function getSubmissionsAction(data) {
	return {
		type: GET_SUBMISSIONS,
		payload: data,
	};
}

export const setSubmissionsError = (data) => {
	return {
		type: SUBMISSIONS_ERROR,
		payload: data,
	};
};

export const fetchGetSubmissions = async (projectId, startDate, endDate, offset, maxPerPage) => {
	try {
		const submissions = await fetch(apiUrl + `/getSubmissions/${projectId}`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({
				startDate,
				endDate,
				offset,
				maxPerPage
			}),
		});
		if (submissions) {
			let resJSON = await submissions.json();
			return resJSON;
		}
	} catch (error) {
		setSubmissionsError(error);
	}
};

/* Is this function used for anything anymore?
	 Submissions.js now calls fetchGetSubmissions directly without sending
	 the submissions to Redux.
*/
export const getSubmissions = (projectId, startDate, endDate, offset, maxPerPage) => {
	return (dispatch) => {
		fetchGetSubmissions(projectId, startDate, endDate, offset, maxPerPage).then((resJSON) => {
			if (resJSON) {
				if (resJSON.sessionData === null) {
					dispatch({
						type: CAN_ACCESS,
						payload: resJSON.submissionsData,
					});
				}
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setSubmissionsError(resJSON));
				} else {
					dispatch(getSubmissionsAction(resJSON));
				}
			} else {
				dispatch(setSubmissionsError(resJSON));
			}
		});
	};
};

export const fetchDownloadSubmissions = async (projectId, startDate, endDate) => {
	try {
		const submissions = await fetch(apiUrl + `/getSubmissions/download/id/${projectId}`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({
				startDate: startDate,
				endDate: endDate,
			}),
		});

		return submissions && (await submissions.json());
	} catch (error) {
		console.log('Something went wrong getting submissions for download. Error: ', error);
	}
};
