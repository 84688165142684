import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";
import Button from "../../components/Button/Button";
import Dashboard from "../../components/Dashboard/Dashboard";
import Input from "../../components/Input/Input";
import SubtitleOneBtnToast from "../../components/ToastJsx/SubtitleOneBtnToast";
import {useTranslation} from "../../utils/useTranslation";
import {validateEmail} from "../../utils/validateEmail";

import styles from "../ChangeEmail/ChangeEmail.module.scss";
import {changeEmail} from "../../actions/userActions";

function ChangeEmail() {
  const t = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const onSubmit = () => {
    console.log('email:', email)
    if (!validateEmail(email)) {
      setError(t.login.emailInvalid);
      return;
    }
    let toastTitle, toastSubtitle;
    changeEmail(email).then( res => {
      console.log('res:', res)
      if (res.message) {
        toastTitle = t.changeEmail.emailChangeSubmitted;
        toastSubtitle = t.changeEmail.checkInboxAndConfirm;
      } else {
        setError(res.error || t.changeEmail.error)
        toastTitle = t.changeEmail.error;
        toastSubtitle = res.error;
      }
    }).catch(err => {
      setError(err)
      toastTitle = t.changeEmail.error;
      toastSubtitle = err;
    }).finally( () => {
        toast(toastMsg => <SubtitleOneBtnToast
          title={toastTitle}
          subtitle={toastSubtitle}
          btn1Label={t.popup.ok}
          btn1Fn={() => setTimeout(() => toast.dismiss(toastMsg.id), 200)}
        />, {
          duration: 8000
        });
      }
    );
    // update de.json, es.json and hr.json
  }

  return (
    <Dashboard>
      <div className={styles.container}>
        <h1 className={styles.title}>{t.userSettings.changeEmail}</h1>

        <div className={styles.emailContainer}>
          <label className={styles.inputLabel}>
            {t.changeEmail.enterNewEmail}
          </label>
          <Input
            type="email"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError('');
            }}
            // primary
            secondary
            error={error}
            // errorstyle={{ fontSize: 12, marginTop: -6, left: 0}}
          />
          <div className={styles.btn}>
            <Button small primary label={t.userSettings.changeEmail}
                     onClick={onSubmit}
            />
          </div>

        </div>

      </div>
    </Dashboard>
  );
}

export default ChangeEmail;
