import React from 'react';

import styles from './SidebarBox.module.scss';

function SidebarBox({children, hr}) {
	return (
		<React.Fragment>
			{hr && <hr className={styles.hr} />}
			<div className={styles.box}>{children}</div>
		</React.Fragment>
	);
}

export default SidebarBox;
