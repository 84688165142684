import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Dashboard from '../../components/Dashboard/Dashboard';
import Button from '../../components/Button/Button';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import plusIcon from '../../assets/icons/plus.svg';
import FirstProjectBadge from '../../assets/images/FirstProjectBadge.png';
import fallbackPreviewImg from '../../assets/images/FallbackPreviewIMG.jpg';
import {
	getProjects,
	getProjectsByEmail,
	createProject,
	deleteProject,
	copyProject,
} from '../../actions/projectsActions';
import { showModal, hideModal } from '../../actions/modalActions';
import { useTranslation } from '../../utils/useTranslation';
import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';
import { GENERAL_PROJECT_SETTINGS } from '../../utils/constants/generalProjectSettings';
import styles from './ProjectList.module.scss';

function ProjectList() {
	const t = useTranslation();
	const dispatch = useDispatch();
	const token = useSelector((state) => state.auth.token);
	const reduxProjects = useSelector((state) => state.projects.data);
	const history = useHistory();
	const params = useParams();
	const email = params.email;
	const PREMIUM_USER_MAX_PROJECTS = 3;
	const PROFESSIONAL_USER_MAX_PROJECTS = 10;

	const projects = [...reduxProjects].reverse();

	useEffect(() => {
		if (email) {
			dispatch(getProjectsByEmail(token, email));
		} else {
			dispatch(getProjects(token));
		}
	}, [dispatch, token, email]);

	useEffect(() => {
		dispatch({
			type: 'GET_SCENES',
			payload: [],
		});
	}, [dispatch]);

	useEffect(()=>{   // remove any 'wait for copy/delete' popups
		dispatch(hideModal())
	},[dispatch, projects])

	function showPopup(title, subtitle) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				subtitle: subtitle,
				buttonPrimary: {
					label: t.popup.okay,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	const projectList = projects.map((project) => {
		const hasUnpublishedChanges = (project.hasDraft || project.hasDraftScenes) ? true : false;
		return (
			<ProjectCard
				key={project._id}
				_id={project._id}
				projectImage={project.general?.previewImage || project.general?.backgroundImage || fallbackPreviewImg}
				title={project.title}
				description={project.description}
				views={project.views}
				deleteProject={() => dispatch(deleteProject(token, project._id))}
				copyProject={() => dispatch(copyProject(token, project._id))}
				showPopup={() => showPopup(t.projectList.onlyLargeScreen)}
				projectCreatorId={project.userId}
				saveSubmissionsToDB={project.saveSubmissionsToDB}
				hasUnpublishedChanges={hasUnpublishedChanges}
			/>
		);
	});

	function checkingProjectNumberRestriction() {
		if (ifUserRole(ROLES.PREMIUM, ROLES.FREE)) {
			if (projects.length >= PREMIUM_USER_MAX_PROJECTS) {
				showPopup(
					t.projectList.maxNumProjects,
					t.projectList.maxNumProjectsReached + ` (Max: ${PREMIUM_USER_MAX_PROJECTS})`
				);
				return false;
			}
			return true;
		}
		if (ifUserRole(ROLES.PROFESSIONAL)) {
			if (projects.length >= PROFESSIONAL_USER_MAX_PROJECTS) {
				showPopup(
					t.projectList.maxNumProjects,
					t.projectList.maxNumProjectsReached + ` (Max: ${PROFESSIONAL_USER_MAX_PROJECTS})`
				);

				return false;
			}
			return true;
		}
		//TODO: check number of projects for free role
		return true;
	}

	return (
		<Dashboard>
			<div className={styles.projectListContainer}>
				<div className={styles.buttonWrapper}>
					{ifUserRole(ROLES.ADMIN) && email ? (
						<h1 className={styles.title}>{t.projectList.viewAs + email}</h1>
					) : (
						<Button
							label={t.projectList.newProject}
							onClick={() => {
								if (window.innerWidth < 992) {
									showPopup(t.projectList.onlyLargeScreen);
								} else {
									if (checkingProjectNumberRestriction()) {
										dispatch(
											createProject(
												token,
												t.projectList.noTitle,
												t.projectList.description,
												GENERAL_PROJECT_SETTINGS,
												(id) => {
													history.push('/ProjectEditor/' + id);
												}
											)
										);
									}
								}
							}}
							primary
							icon={plusIcon}
						/>
					)}
				</div>
				{projects && projects.length === 0 ? (
					<img src={FirstProjectBadge} className={styles.firstProjectBadge} alt="First project badge" />
				) : (
					<ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 600: 2, 1200: 3 }}>
						<Masonry columnsCount={3} gutter={'15px'}>
							{projectList}
						</Masonry>
					</ResponsiveMasonry>
				)}
			</div>
		</Dashboard>
	);
}

export default ProjectList;
