import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SelectInput from '../../components/SelectInput/SelectInput';
import { logoutUser } from '../../actions/authActions';
import { useTranslation } from '../../utils/useTranslation';

import styles from './Navbar.module.scss';

import {ReactComponent as Logo } from '../../assets/images/logo-clickmaster.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user-circle.svg';

import { ifUserRole } from '../../utils/ifUserRole'
import { ROLES } from '../../utils/constants/roles'

// const apiUrl = process.env.REACT_APP_BE_URL;  // only used for goToUserSubscriptions() -> Obsolete?

/**
 * TODO: check if obsolete and remove
 * Sends userID to the backend
 * Backend is going to send back redirect URL with autogenerated token
 */
// async function goToUserSubscriptions() {
//   let userID = localStorage.getItem('id');
//   if (userID) {
//     try {
//       const getLoginURL = await fetch(apiUrl + `/users/paymentLogin/${userID}`, {
//         method: 'GET',
//         mode: 'cors',
//         headers: {
//           Accept: 'application/json',
//           'Content-type': 'application/json',
//         },
//       });
//       if (getLoginURL) {
//         let redirectURL = await getLoginURL.json();
//         window.location.href = redirectURL;
//       }
//     } catch (error) {
//       console.log('Something went wrong. Something: ', error);
//     }
//   } else {
//     console.log('Something went wrong with userID');
//   }
// }

function Navbar() {
  const t = useTranslation();
  const dispatch = useDispatch();
  // const location = useLocation();
  const history = useHistory();
  const username = localStorage.getItem('username');
  const token = useSelector((state) => state.auth.token);

  const userDropdownOptions = () => {

    let linkOptions = [
      // {
      //   label: t.navbar.mySubscription,
      //   onClick: () => {
      //     goToUserSubscriptions();
      //   },
      // },
      {
        label: t.navbar.settings,
        onClick: () => history.push('/user-settings'),
      },
      {
        label: t.navbar.logout,
        onClick: () => dispatch(logoutUser(token)),
      },
    ];
    // add link to admin if admin role
    ifUserRole(ROLES.ADMIN) && linkOptions.splice(1,0, {
      label: t.navbar.admin,
      onClick: () => history.push('/users'),
    });

    return linkOptions;
  }

  return (
    <div className={styles.navbar}>
      <Logo
        alt="logo"
        className={styles.logo}
        onClick={() => {
          if (token) return history.push('/projectList');
          else return history.push('/login');
        }}
      />

      <ul className={styles.list}>
        {/*<li className={styles.item} onClick={() => history.push('/ersteschritte')}>*/}
        {/*  {t.navbar.firstSteps}*/}
        {/*</li>*/}
        {/*<li*/}
        {/*  className={classNames(styles.item, { [styles.active]: location.pathname === '/projectList' })}*/}
        {/*  onClick={() => history.push('/projectList')}*/}
        {/*>*/}
        {/*  {t.navbar.myProjects}*/}
        {/*</li>*/}
        {/*<li className={styles.item} onClick={() => history.push('/downloads')}>*/}
        {/*  {t.navbar.downloads}*/}
        {/*</li>*/}

        <li className={styles.item}>
          <UserIcon alt="userIcon" className={styles.userIcon} />
          <SelectInput
            options={userDropdownOptions()}
            selected={username}
            navbar
          />
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
