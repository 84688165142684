import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Popup from 'reactjs-popup';
import { withRouter } from 'react-router-dom';
import { ReactComponent as MinusIco } from '../../assets/icons/minus-circle.svg';
import { fetchDownloadSubmissions, fetchGetSubmissions } from '../../actions/submissionsActions';
import Dashboard from '../../components/Dashboard/Dashboard';
import { formatDate } from '../../utils/formatDate';
import { DATE_FORMAT } from '../../utils/constants/dateFormat';
import { useTranslation } from '../../utils/useTranslation';
import styles from './Submissions.module.scss';

const MAX_SUBMISSIONS_PER_PAGE = 50;
const primaryDownloadExtension = 'CSV';
const secondaryDownloadExtension = 'JSON';

const Submissions = (props) => {
  const t = useTranslation();
  const projectId = props.match.params.projectID;

  const [submissions, setSubmissions] = useState({
    data: [],
    pagination: {
      totalSessions: 0,
      currentPage: 0,
      pages: 0,
    },
  });
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    (async () => {
      const submissionsInfo = await fetchGetSubmissions(
        projectId, filterStartDate, filterEndDate, offset, MAX_SUBMISSIONS_PER_PAGE
      );
      setSubmissions(submissionsInfo);
    })();
  }, [filterStartDate, filterEndDate, offset, projectId]);

  const exportFile = async (fileType) => {
    const sessionsForDownload = await fetchDownloadSubmissions(
      projectId, filterStartDate, filterEndDate
    );
    return downloadFile({
      data: JSON.stringify(sessionsForDownload),
      fileName: `submissions.${fileType}`,
      fileType: `text/${fileType}`,
    });
  }

  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  function organizeSteps(steps) {
    return steps.length ? (
      <div className={styles.popup}>
        <h1 className={styles.popupText}>{t.sessions.steps}</h1>
        {steps.length > 0 && (
          <ol className={styles.stepsList}>
            {steps.map((step, index) => {
              return (
                <li className={styles.popupText} key={index}>
                  {step}
                </li>
              );
            })}
          </ol>
        )}
      </div>
    ) : (
      <div className={styles.popup}>
        <h1 className={styles.popupText}>There aren't any Steps in this session</h1>
      </div>
    );
  }

  const tableRows = submissions.data.length &&
    submissions.data.map((submission, index) => {
      return (
        <tr className={styles.tr} key={index}>
          <td className={styles.td}>{formatDate(submission.created)}</td>
          <td className={styles.td}>{submission.client_email || ''}</td>
          <td className={styles.td}>{submission.host ? submission.host : '/'}</td>
          <td className={styles.td}>{submission.sourceUrl ? submission.sourceUrl : '/'}</td>
          <td className={styles.td}>
            <Popup
              trigger={
                <Button variant="secondary" size={'sm'}>
                  {t.sessions.open}
                </Button>
              }
              modal
            >
              {organizeSteps(submission.steps)}
            </Popup>
          </td>
        </tr>
      );
    });

  const goToNextPage = () => {
    if (submissions.pagination.currentPage < submissions.pagination.pages) {
      let newOffset = offset + MAX_SUBMISSIONS_PER_PAGE;
      setOffset(newOffset);
    }
  }

  const goToPreviousPage = () => {
    const FIRST_PAGE = 1;
    if (submissions.pagination.currentPage > FIRST_PAGE) {
      let newOffset = offset - MAX_SUBMISSIONS_PER_PAGE;
      setOffset(newOffset);
    }
  }

  return (<Dashboard>
    <div className={styles.container}>
      <div className={styles.divContainer}>
        <h1 className={styles.title}>
          {t.submissions.submissions} {submissions.data.length && submissions.data[0].project.title}:
        </h1>
        <div className={styles.datePickerContainer}>
          <h6 className={styles.datePickerLabel}>{t.submissions.filterSubmissionsByDate}:</h6>
          <div className={styles.pickerAndClearButtonContainer}>
            <DateRangePicker
              startDate={filterStartDate}
              startDateId="StartDateFilterSessions"
              endDate={filterEndDate}
              endDateId="EndDateFilterSessions"
              onDatesChange={({ startDate, endDate }) => {
                setFilterStartDate(startDate);
                setFilterEndDate(endDate);
                setOffset(0);
              }}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              isOutsideRange={() => false}
              displayFormat={DATE_FORMAT}
              small
            />
            <MinusIco
              className={styles.minusIco}
              onClick={() => {
                setFilterStartDate(null);
                setFilterEndDate(null);
                setOffset(0);
              }}
            />
          </div>
        </div>
        <div>
          <Dropdown as={ButtonGroup}>
            <Button variant="success" onClick={() => exportFile(primaryDownloadExtension)}>
              {t.submissions.downloadAs} {primaryDownloadExtension}
            </Button>

            <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => exportFile(secondaryDownloadExtension)}>
                {t.submissions.downloadAs} {secondaryDownloadExtension}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {submissions.data.length ? (
        <>
          <table className={styles.table}>
            <thead>
            <tr className={styles.tr}>
              <th className={styles.th}>{t.sceneTab.date}</th>
              <th className={styles.th}>{t.submissions.clientEmail}</th>
              <th className={styles.th}>{t.submissions.host}</th>
              <th className={styles.th}>{t.submissions.sourceURL}</th>
              <th className={styles.th}>{t.sessions.steps}</th>
            </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
          <ul className={styles.navigation}>
            <li>
              <Button variant="primary" size={'sm'} className={styles.navigationButton} onClick={() => goToPreviousPage()}>
                {t.sessions.back}
              </Button>
            </li>
            <li className={styles.numberOfPages}>
              {t.submissions.page} {submissions.pagination.currentPage} {t.submissions.of} {submissions.pagination.pages}
            </li>
            <li>
              <Button variant="primary" size={'sm'} className={styles.navigationButton} onClick={() => goToNextPage()}>
                {t.submissions.next}
              </Button>
            </li>
          </ul>
        </>
      ) : (
        <div>{t.submissions.noSubmissionsFound}</div>
      )}
    </div>
  </Dashboard>)
}

export default withRouter(Submissions);
