import { store } from '../index';
import de from './translations/de.json';
import en from './translations/en.json';
import es from './translations/es.json';
import hr from './translations/hr.json';
import { DEFAULT_LANGUAGE } from "./constants/languages";

// Note: when adding a new language, also update the constant SUPPORTED_LANGUAGES in "./constants/languages".
const t = {
	DE: de,
	EN: en,
	ES: es,
	HR: hr,
};

export const useTranslation = () => {
	let language = store?.getState()?.user?.language;
	if (!language) language = DEFAULT_LANGUAGE;  // 'EN' or 'DE', for example
	return t[language];
};
