import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import Dashboard from "../../components/Dashboard/Dashboard";
import styles from "../PriceTable/PriceTable.module.scss";
import { useTranslation } from "../../utils/useTranslation";

const STRIPE_PRICING_TABLE = process.env.REACT_APP_STRIPE_PRICING_TABLE;
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

function PriceTable() {
  const t = useTranslation();
  const user = useSelector((state) => state.user);

  return (
    <Dashboard>
        <Helmet>
          <script src="https://js.stripe.com/v3/pricing-table.js"></script>
        </Helmet>
      <div className={styles.priceTableContainer}>
        <h1 className={styles.pageTitle}>{t.userSettings.upgradePage.title}</h1>
        <div className={styles.subtitle}>{t.userSettings.upgradePage.subtitle}</div>
        <stripe-pricing-table
          pricing-table-id={STRIPE_PRICING_TABLE}
          publishable-key={STRIPE_PUBLISHABLE_KEY}
          client-reference-id={user._id}
          customer-email={user.email}
        >
        </stripe-pricing-table>
      </div>
    </Dashboard>
  );
}

export default PriceTable;
