import axios from "axios";
import {getProject, UPDATE_PROJECT} from '../actions/projectsActions';
import {authHeaders} from "../utils/authHeaders";
export const GET_SCENES = 'GET_SCENES';
export const CAN_ACCESS = 'CAN_ACCESS';
export const CREATE_SCENE = 'CREATE_SCENE';
export const UPDATE_SCENE = 'UPDATE_SCENE';
export const DELETE_SCENE = 'DELETE_SCENE';
export const SCENE_ERROR = 'SCENE_ERROR';
export const SCENE_RESPONSE = 'SCENE_RESPONSE';
export const UPDATE_SCENE_PROP = 'UPDATE_SCENE_PROP';
export const ADD_SCENE_ANSWER = 'ADD_SCENE_ANSWER';
export const UPDATE_SCENE_ANSWER = 'UPDATE_SCENE_ANSWER';
export const UPDATE_SCENE_CONTACT = 'UPDATE_SCENE_CONTACT';

const apiUrl = process.env.REACT_APP_BE_URL;

/**
 * Sets scene error
 * @param {string} data
 * @returns { type: string, payload: string }
 */
export const setSceneError = (data) => {
  return {
    type: SCENE_ERROR,
    payload: data,
  };
};

/**
 * Sets scene response
 * @param {string} response
 * @returns { type: string, payload: string }
 */
export const setSceneResponse = (response) => {
  return {
    type: SCENE_RESPONSE,
    payload: response,
  };
};

// obsolete with axios (see below):
// export const fetchScenes = async (token, projectId) => {
//   try {
//     const res = await fetch(apiUrl + '/scenes/draft/' + projectId, {
//       method: 'GET',
//       mode: 'cors',
//       headers: {
//         Accept: 'application/json',
//         'Content-type': 'application/json',
//         Authorization: 'Bearer ' + token,
//       },
//     });
//     if (res) {
//       let resJSON = await res.json();
//       return resJSON;
//     } else {
//       console.error(res);
//       return undefined;
//     }
//   } catch (error) {
//     console.error(error);
//     return undefined;
//   }
// };

/**
 * Get all scenes
 * @param {string} projectId
 * @param {Dispatch<any>} dispatch
 * Updates redux with scenes and refreshed project
 */
export const getScenes = async (projectId, dispatch) => {
  await axios
    .get(
      `${apiUrl}/scenes/draft/${projectId}`,
      authHeaders()
    ).then((res) => {
      // console.log(res)
      if (res.data.project === null) {
        dispatch({
          type: CAN_ACCESS,
          payload: res.data.project,
        });
      }
      if (res.data.error) {
        console.error(res.data.error);
        dispatch(setSceneError(res.data.error));
      } else {
        dispatch({
          type: GET_SCENES,
          payload: res.data.scenes,
        });
        if (res.data.project) {
          dispatch(getProject(res.data.project));
        }
      }
    }).catch(err => console.error(err))
}
// obsolete with axios (see above):
// export const getScenes = (token, projectId) => {
//   return (dispatch) => {
//     fetchScenes(token, projectId).then((resJSON) => {
//       if (resJSON) {
//         if (resJSON.project === null) {
//           dispatch({
//             type: CAN_ACCESS,
//             payload: resJSON.project,
//           });
//         }
//         if (resJSON.error) {
//           dispatch(setSceneError(resJSON.error));
//         } else {
//           dispatch({
//             type: GET_SCENES,
//             payload: resJSON.scenes,
//           });
//           if (resJSON.project) {
//             dispatch(getProject(resJSON.project));
//           }
//         }
//       } else {
//         console.error(resJSON);
//         dispatch(setSceneError(resJSON));
//       }
//     });
//   };
// };

export const fetchCreateScene = async (token, scene, projectHasDraftScenes) => {
  try {
    const res = await fetch(apiUrl + '/scenes', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ scene, projectHasDraftScenes }),
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Create scene
 * @param {string} token
 * @param {object} scene: {
 *   projectId: string
 *   title: string
 *   question: string
 *   answers: [object]
 *   template: string
 *   hasBackButton: boolean
 *   imageSize: string
 * }
 * @param {bool} projectHasDraftScenes
 */
export const createScene = (token, scene, projectHasDraftScenes ) => {
  // console.log('create scene from copy, scene:', scene);
  return (dispatch) => {
    fetchCreateScene(token, scene, projectHasDraftScenes).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setSceneError(resJSON.error));
        } else {
          dispatch(setSceneResponse(resJSON.message));
          dispatch({
            type: CREATE_SCENE,
            payload: resJSON.scene,
          });
          if (resJSON.project) dispatch({  // flag hasDraftScenes = true on project unless it is true already
            type: UPDATE_PROJECT,
            payload: resJSON.project,
          });
        }
      } else {
        console.error(resJSON);
        dispatch(setSceneError(resJSON));
      }
    });
  };
};

export const fetchCopyScene = async (token, sceneId, newScenePosition, projectHasDraftScenes) => {
  try {
    const res = await fetch(apiUrl + `/scenes?sourceId=${sceneId}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ scene: {position: newScenePosition}, projectHasDraftScenes }),
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const copySceneToCanvas = (token, sceneId, newScenePosition, projectHasDraftScenes) => {
  return (dispatch) => {
    fetchCopyScene(token, sceneId, newScenePosition, projectHasDraftScenes).then(resJSON => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setSceneError(resJSON.error));
        } else {
          dispatch(setSceneResponse(resJSON.message));
          dispatch({
            type: CREATE_SCENE,
            payload: resJSON.scene,
          });
          if (resJSON.project) dispatch({  // flag hasDraftScenes = true on project unless it is true already
            type: UPDATE_PROJECT,
            payload: resJSON.project,
          });
        }
      } else {
        console.error(resJSON);
        dispatch(setSceneError(resJSON));
      }
    });
  };
};

export const fetchUpdateAllScenes = async (token, scenes, projectHasDraftScenes) => {
  try {
    const res = await fetch(apiUrl + '/scenes', {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ scenes, projectHasDraftScenes }),
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Update all scenes
 * @param {string} token
 * @param {[object]} scenes
 * @param {boolean} projectHasDraftScenes
 */
export const updateAllScenes = (token, scenes, projectHasDraftScenes) => {
  return (dispatch) => {
    fetchUpdateAllScenes(token, scenes, projectHasDraftScenes).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setSceneError(resJSON.error));
        } else {
          dispatch(setSceneResponse(resJSON.message));
          dispatch({
            type: GET_SCENES,
            payload: resJSON.scenes,
          });
          if (resJSON.project) dispatch({  // flag hasDraftScenes = true on project unless it is true already
            type: UPDATE_PROJECT,
            payload: resJSON.project,
          });
        }
      } else {
        console.error(resJSON);
        dispatch(setSceneError(resJSON));
      }
    });
  };
};

export const fetchDeleteScenes = async (token, projectId, sceneIds, projectHasDraftScenes) => {
  try {
    const res = await fetch(apiUrl + '/scenes', {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ projectId, sceneIds, projectHasDraftScenes }),
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Delete scene
 * @param {string} token auth token
 * @param {string} sceneId
 */
export const deleteScenes = (token, projectId, sceneIds, projectHasDraftScenes) => {
  return (dispatch) => {
    fetchDeleteScenes(token, projectId, sceneIds, projectHasDraftScenes).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setSceneError(resJSON.error));
        } else {
          dispatch(setSceneResponse(resJSON.message));
          dispatch({
            type: GET_SCENES,
            payload: resJSON.scenes,
          });
          if (resJSON.project) dispatch({  // flag hasDraftScenes = true on project unless it is true already
            type: UPDATE_PROJECT,
            payload: resJSON.project,
          });
        }
      } else {
        console.error(resJSON);
        dispatch(setSceneError(resJSON));
      }
    });
  };
};

/**
 * Deletes video from cloudinary
 * @param {*} videoFilePublicId
 * @param dispatch
 *
 * Obsolete with draft/publish feature (video files are deleted by 'publish' route)
 */
// export const deleteCloudinaryVideo = async (videoFilePublicId, dispatch) => {
//   const URLEncodedVideoFilePublicId = videoFilePublicId.replace("/","%2F");
//   console.log('videoFilePublicId:', URLEncodedVideoFilePublicId)
//   await axios
//     .delete(
//       `${apiUrl}/scenes/delete/video/${URLEncodedVideoFilePublicId}`,
//       authHeaders()
//     ).then((res) => {
//       // console.log(res)
//       if (res.data.error) {
//         console.error(res.data.error);
//         dispatch(setSceneError(res.data.error));
//       } else {
//         dispatch(setSceneResponse(res.data.message))
//       }
//     }).catch(err => console.error(err))
// };
// Pre-axios version:
// export const deleteCloudinaryVideo = (token, videoFilePublicId) => {
//   return (dispatch) => {
//     fetchDeleteCloudinaryVideo(token, videoFilePublicId).then((resJSON) => {
//       if (resJSON) {
//         if (resJSON.error) {
//           console.error(resJSON.error);
//           dispatch(setSceneError(resJSON.error));
//         } else {
//           dispatch(setSceneResponse(resJSON.message));
//         }
//       } else {
//         console.error(resJSON);
//         dispatch(setSceneError(resJSON));
//       }
//     });
//   };
// };
//
// export const fetchDeleteCloudinaryVideo = async (token, videoFilePublicId) => {
//   let encodedPublicId = encodeURIComponent(videoFilePublicId);
//   try {
//     const res = await fetch(apiUrl + '/scenes/delete/video/' + encodedPublicId, {
//       method: 'DELETE',
//       mode: 'cors',
//       headers: {
//         Accept: 'application/json',
//         'Content-type': 'application/json',
//         Authorization: 'Bearer ' + token,
//       },
//     });
//     if (res) {
//       let resJSON = await res.json();
//       return resJSON;
//     } else {
//       console.error(res);
//       return undefined;
//     }
//   } catch (error) {
//     console.error(error);
//     return undefined;
//   }
// };

/**
 * Deletes audio file from cloudinary
 * @param {string} token auth token
 * @param {string} audioFilePublicId
 *
 * Obsolete with draft/publish feature (audio files are deleted by 'publish' route)
 */
// export const deleteCloudinaryAudio = (token, audioFilePublicId) => {
//   return (dispatch) => {
//     fetchDeleteCloudinaryAudio(token, audioFilePublicId).then((resJSON) => {
//       if (resJSON) {
//         if (resJSON.error) {
//           console.error(resJSON.error);
//           dispatch(setSceneError(resJSON.error));
//         } else {
//           dispatch(setSceneResponse(resJSON.message));
//         }
//       } else {
//         console.error(resJSON);
//         dispatch(setSceneError(resJSON));
//       }
//     });
//   };
// };
//
// export const fetchDeleteCloudinaryAudio = async (token, audioFilePublicId) => {
//   let encodedPublicId = encodeURIComponent(audioFilePublicId);
//   try {
//     const res = await fetch(apiUrl + '/scenes/delete/audio/' + encodedPublicId, {
//       method: 'DELETE',
//       mode: 'cors',
//       headers: {
//         Accept: 'application/json',
//         'Content-type': 'application/json',
//         Authorization: 'Bearer ' + token,
//       },
//     });
//     if (res) {
//       let resJSON = await res.json();
//       return resJSON;
//     } else {
//       console.error(res);
//       return undefined;
//     }
//   } catch (error) {
//     console.error(error);
//     return undefined;
//   }
// };

export const fetchUpdateScene = async (token, sceneId, scene, projectHasDraftScenes) => {
  try {
    const res = await fetch(apiUrl + '/scenes/' + sceneId, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ scene, projectHasDraftScenes }),
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Update scene
 * @param {string} token
 * @param {string} sceneId
 * @param {object} scene partial/full scene object
 * @param {boolean} projectHasDraftScenes
 */
export const updateScene = (token, sceneId, scene, projectHasDraftScenes) => {
  return (dispatch) => {
    fetchUpdateScene(token, sceneId, scene, projectHasDraftScenes).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setSceneError(resJSON.error));
        } else {
          dispatch(setSceneResponse(resJSON.message));
          if (scene.key)
            dispatch({
              type: UPDATE_SCENE,
              payload: { ...resJSON.scene, key: scene.key },
            });
          else
            dispatch({
              type: UPDATE_SCENE,
              payload: resJSON.scene,
            });
          if (resJSON.project) dispatch({  // flag hasDraftScenes = true on project unless it is true already
            type: UPDATE_PROJECT,
            payload: resJSON.project,
          });
        }
      } else {
        console.error(resJSON);
        dispatch(setSceneError(resJSON));
      }
    });
  };
};

export const fetchUpdateAnswer = async (token, sceneId, answerIndex, answer, projectHasDraftScenes) => {
  try {
    const res = await fetch(apiUrl + '/scenes/answer/' + sceneId, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ answerIndex, answer, projectHasDraftScenes }),
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Update scene answer
 * @param {string} token auth token
 * @param {string} sceneId
 * @param {number} answerIndex
 * @param {object} answer partial/full answer object (partial with name)
 * @param {boolean} projectHasDraftScenes
 */
export const updateAnswer = (token, sceneId, answerIndex, answer, projectHasDraftScenes) => {
  return (dispatch) => {
    fetchUpdateAnswer(token, sceneId, answerIndex, answer, projectHasDraftScenes).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setSceneError(resJSON.error));
        } else {
          dispatch(setSceneResponse(resJSON.message));
          dispatch({
            type: UPDATE_SCENE,
            payload: resJSON.scene,
            key: Math.random() * 100000,
          });
          if (resJSON.project) dispatch({  // flag hasDraftScenes = true on project unless it is true already
            type: UPDATE_PROJECT,
            payload: resJSON.project,
          });
        }
      } else {
        console.error(resJSON);
        dispatch(setSceneError(resJSON));
      }
    });
  };
};

export const saveToStorage = (id, key, value) => {
  let tmp = { updated: new Date() };
  tmp[key] = value;
  window.localStorage.setItem(id, JSON.stringify(tmp));
  return (dispatch) => {};
};

export const fetchRemoveAnswer = async (token, sceneId, answerIndex, projectHasDraftScenes) => {
  try {
    const res = await fetch(apiUrl + '/scenes/answer/' + sceneId, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({ answerIndex, projectHasDraftScenes }),
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Remove scene answer
 * @param {string} token auth token
 * @param {string} sceneId
 * @param {number} answerIndex
 * @param {boolean} projectHasDraftScenes
 */
export const removeAnswer = (token, sceneId, answerIndex, projectHasDraftScenes) => {
  return (dispatch) => {
    fetchRemoveAnswer(token, sceneId, answerIndex, projectHasDraftScenes).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setSceneError(resJSON.error));
        } else {
          dispatch(setSceneResponse(resJSON.message));
          dispatch({
            type: UPDATE_SCENE,
            payload: resJSON.scene,
            key: Math.random() * 100000,
          });
          if (resJSON.project) dispatch({  // flag hasDraftScenes = true on project unless it is true already
            type: UPDATE_PROJECT,
            payload: resJSON.project,
          });
        }
      } else {
        console.error(resJSON);
        dispatch(setSceneError(resJSON));
      }
    });
  };
};

/**
 * Delete image from scenes or preview image from project general settings
 * @param {string} token auth token
 * @param {string} imageUrl
 *
 * Obsolete with draft/publish feature (images are deleted by 'publish' route)
 */
// export const fetchDeleteImage = async (token, imagePublicId) => {
//   try {
//     const res = await fetch(apiUrl + '/scenes/deleteImage/', {
//       method: 'DELETE',
//       mode: 'cors',
//       headers: {
//         Accept: 'application/json',
//         'Content-type': 'application/json',
//         Authorization: 'Bearer ' + token,
//       },
//       body: JSON.stringify({ imagePublicId }),
//     });
//     if (res) {
//       let resJSON = await res.json();
//       return resJSON;
//     } else {
//       console.error(res);
//       return undefined;
//     }
//   } catch (error) {
//     console.error(error);
//     return undefined;
//   }
// };

/* Scene FE actions */
export const updateSceneProp = (sceneId, name, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SCENE_PROP,
      sceneId: sceneId,
      name: name,
      value: value,
    });
  };
};

export const addSceneAnswer = (sceneId, answer) => {
  return (dispatch) => {
    dispatch({
      type: ADD_SCENE_ANSWER,
      sceneId: sceneId,
      answer: answer,
    });
  };
};

export const updateSceneAnswer = (sceneId, answerIndex, name, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SCENE_ANSWER,
      sceneId: sceneId,
      answerIndex: answerIndex,
      name: name,
      value: value,
    });
  };
};

/**
 * Deletes json file from cloudinary
 * @param {*} token auth token
 * @param {*} audioFilePublicId
 *
 * Obsolete with draft/publish feature (json files are deleted by 'publish' route)
 */
// export const deleteCloudinaryFile = (token, jsonFilePublicId) => {
//   return (dispatch) => {
//     fetchDeleteCloudinaryFile(token, jsonFilePublicId).then((resJSON) => {
//       if (resJSON) {
//         if (resJSON.error) {
//           console.error(resJSON.error);
//           dispatch(setSceneError(resJSON.error));
//         } else {
//           dispatch(setSceneResponse(resJSON.message));
//         }
//       } else {
//         console.error(resJSON);
//         dispatch(setSceneError(resJSON));
//       }
//     });
//   };
// };

// export const fetchDeleteCloudinaryFile = async (token, jsonFilePublicId) => {
//   let encodedPublicId = encodeURIComponent(jsonFilePublicId);
//   try {
//     const res = await fetch(apiUrl + '/scenes/delete/json/' + encodedPublicId, {
//       method: 'DELETE',
//       mode: 'cors',
//       headers: {
//         Accept: 'application/json',
//         'Content-type': 'application/json',
//         Authorization: 'Bearer ' + token,
//       },
//     });
//     if (res) {
//       let resJSON = await res.json();
//       return resJSON;
//     } else {
//       console.error(res);
//       return undefined;
//     }
//   } catch (error) {
//     console.error(error);
//     return undefined;
//   }
// };
