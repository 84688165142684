import Button from "../Button/Button";
import styles from './ToastJsx.module.scss';

/**
 * Returns JSX for a two-button toast, such as cancel-confirm
 * @param {string} title
 * @param {string} btn1Label
 * @param {function} btn1Fn
 * @param {string} btn2Label
 * @param {function} btn2Fn
 * @returns {JSX.Element}
 * @constructor
 */
const TwoBtnToast = ({ title, btn1Label, btn1Fn, btn2Label, btn2Fn }) => {
  return <div>
    <div className={styles.title}>{title}</div>
      <div className={styles.btnRow}>
        <Button small secondary label={btn1Label} onClick={btn1Fn} />
        <Button small primary label={btn2Label} onClick={btn2Fn} />
      </div>
  </div>
}
export default TwoBtnToast;
