import { GET_SUBMISSIONS, CAN_ACCESS, SUBMISSIONS_ERROR } from '../actions/submissionsActions';

const initialState = [];

export default function submissionsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SUBMISSIONS:
			return action.payload;
		case CAN_ACCESS:
			return { ...state, submissionsData: action.payload };
		case SUBMISSIONS_ERROR:
			return initialState;
		default:
			return state;
	}
}
