import { useDispatch } from 'react-redux';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-bgcolor.svg';
import ImageUpload from '../ImageUpload/ImageUpload';
import SidebarBox from '../SidebarBox/SidebarBox';
import Toggle from '../Toggle/Toggle';
import { useTranslation } from '../../utils/useTranslation';
import { ifUserRole } from '../../utils/ifUserRole';
import { deleteImage } from "../../utils/deleteImage";
import { ROLES } from '../../utils/constants/roles';
import styles from './ImageBox.module.scss';

/* Called by GeneralTab.js */
function ImageBox({ selectedProject, token, updateProject, index, label }) {
	const t = useTranslation();
	const dispatch = useDispatch();

	return (
		<SidebarBox>
			<div className={styles.inputGroup}>
				<label className={styles.label}>{label}</label>
				<div className={styles.imageRow}>
					<ImageUpload
						onUpload={(newImageUrl, imagePublicId) =>
							dispatch(
								updateProject(token, {
									...selectedProject,
									general: { ...selectedProject.general, previewImage: newImageUrl, imagePublicId },
								})
						)}
						imagePublicId={selectedProject?.general?.imagePublicId}
						imageUrl={selectedProject?.general?.previewImage}
						index={index}
						token={token}
						allowLottie={false}
					/>
					<span className={styles.condition}>{t.imageUpload.previewImageSize}</span>
					{selectedProject?.general?.previewImage &&
						<span className={styles.delete}>
							<DeleteIcon
								className={styles.deleteIcon}
								onClick={() => deleteImage(token, 'project_preview', selectedProject, updateProject, dispatch)}
								title={t.generalTab.deleteImage}
							/>
						</span>
					}
				</div>

				{ifUserRole(ROLES.ADMIN) && <div className={styles.inputToggle}>
					<Toggle
						single
						checked={selectedProject.general.retrieveOrigSize}
						onChange={() =>
							dispatch(
								updateProject(token, {
									...selectedProject,
									general: { ...selectedProject.general, retrieveOrigSize: !selectedProject.general.retrieveOrigSize },
								})
						)}
					/>
					<label className={styles.label}>{t.imageUpload.retrieveOrigSize}</label>
				</div>}

			</div>
		</SidebarBox>
	);
}

export default ImageBox;
