import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-circle.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus-circle.svg';
import Input from '../../components/Input/Input';
import SidebarBox from '../../components/SidebarBox/SidebarBox';
import Toggle from '../Toggle/Toggle';
import { useTranslation } from '../../utils/useTranslation';
import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';
import styles from './GeneralBox.module.scss';

function GeneralBox({ selectedProject, updateProject, updateProjectProp }) {
	const [hiddenFieldsArray, setHiddenFieldsArray] = useState(
		selectedProject.hiddenFields || [{ key: '', value: '' }]
	);
	const [addFieldErrorMessage, setAddFieldErrorMessage] = useState(false);
	const t = useTranslation();

	// if at least one assigned hidden field already exists,
	// remove any empty hidden fields on initial render
	useEffect(() => {
		const oldArrayLength = hiddenFieldsArray.length;
		let newArray = [...hiddenFieldsArray].filter((field) => field.key !== '' || field.value !== '');
		if (newArray.length === 0) newArray = [{ key: '', value: '' }];
		setHiddenFieldsArray(newArray);
		if (newArray.length !== oldArrayLength) {
			const event = { target: { name: 'hiddenFields', value: newArray } };
			updateProjectProp(event);
			updateProject();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleHiddenFieldChange(idx, keyOrValue, eTargetValue) {
		let newArray = hiddenFieldsArray.map((object, i) => {
			if (i === idx) {
				return { ...object, [keyOrValue]: eTargetValue };
			}
			return object;
		});
		setHiddenFieldsArray(newArray);
		const event = { target: { name: 'hiddenFields', value: newArray } };
		updateProjectProp(event);
	}
	function removeField(idx) {
		let newArray = [...hiddenFieldsArray];
		newArray.splice(idx, 1);
		setHiddenFieldsArray(newArray);
		const event = { target: { name: 'hiddenFields', value: newArray } };
		updateProjectProp(event);
		updateProject();
	}
	function addField() {
		// only allow new hidden fields if the last one has already been assigned.
		if (hiddenFieldsArray[hiddenFieldsArray.length - 1].key !== '') {
			let newArray = [...hiddenFieldsArray];
			newArray.push({ key: '', value: '' });
			setHiddenFieldsArray(newArray);
			const event = { target: { name: 'hiddenFields', value: newArray } };
			updateProjectProp(event);
			updateProject();
		} else {
			setAddFieldErrorMessage(true);
		}
	}

	const handleToggleChange = (type) => {
		const project = selectedProject;
		switch (type) {
			case 'saveSubmissionsToDB':
				project.saveSubmissionsToDB = !project.saveSubmissionsToDB;
				break
			case 'analyticsActivated':
				project.analyticsActivated = !project.analyticsActivated;
		}
		updateProject(project);
	}

	return (
		<SidebarBox>
			<h2 className={styles.title}>{t.generalTab.tabTitle}</h2>
			<div className={styles.inputGroup}>
				<label className={styles.label}>{t.generalTab.projectTitle}</label>
				<Input
					type="textarea"
					value={selectedProject && selectedProject.title}
					name="title"
					onChange={updateProjectProp}
					onBlur={updateProject}
					secondary
					rows="3"
				/>
			</div>
			<div className={styles.inputGroup}>
				<label className={styles.label}>{t.generalTab.description}</label>
				<Input
					type="textarea"
					value={selectedProject && selectedProject.description}
					name="description"
					onChange={updateProjectProp}
					onBlur={updateProject}
					secondary
					rows="6"
				/>
			</div>
			{ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) && (
				<div className={styles.inputGroup}>
					<label className={styles.label}>{t.generalTab.webhookURL}</label>
					<Input
						type="textarea"
						value={selectedProject && selectedProject.webhookURL}
						name="webhookURL"
						onChange={updateProjectProp}
						onBlur={updateProject}
						secondary
						rows="3"
					/>
				</div>
			)}
			{ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) && (
				<div className={styles.inputGroup}>
					<label className={styles.label}>{t.generalTab.hiddenFields}</label>
					{selectedProject &&
						hiddenFieldsArray &&
						hiddenFieldsArray.map((field, idx) => {
							return (
								<Row key={idx} className={`${styles.hiddenFieldsRow} gx-2`}>
									<Col xs={6}>
										<Input
											type="text"
											value={selectedProject && field.key}
											placeholder={t.generalTab.key}
											onChange={(e) => handleHiddenFieldChange(idx, 'key', e.target.value)}
											onBlur={updateProject}
											ternary
										/>
									</Col>
									<Col xs={6}>
										<Input
											type="text"
											value={selectedProject && field.value}
											placeholder={t.generalTab.value}
											onChange={(e) => handleHiddenFieldChange(idx, 'value', e.target.value)}
											onBlur={updateProject}
											ternary
										/>
									</Col>
									<Col>{idx > 0 && <MinusIcon className={styles.minusIcon} onClick={() => removeField(idx)} />}</Col>
								</Row>
							);
						})}
					<div className={styles.addFieldInputGroup}>
						<label className={styles.addFieldLabel}>{t.generalTab.addHiddenField}</label>
						<PlusIcon className={styles.plusIcon} onClick={() => addField()} />
					</div>
					{addFieldErrorMessage && (
						<div className={styles.addFieldError}>
							{t.generalTab.pleaseFillOutExistingFieldsFirst}
							<span className={styles.addFieldErrorOK} onClick={() => setAddFieldErrorMessage(false)}>
								OK
							</span>
						</div>
					)}
				</div>
			)}
			<div>
				<h6 className={styles.label}>{t.generalTab.saveSubmissionsToDB}</h6>
				<div className={styles.inputToggle}>
					<Toggle single checked={selectedProject?.saveSubmissionsToDB} onChange={() => handleToggleChange('saveSubmissionsToDB')} />
					<label className={styles.toggleLabels}>{t.generalTab.yes}</label>
				</div>
				<h6 className={styles.label}>{t.generalTab.activateAnalytics}</h6>
				<div className={styles.inputToggle}>
					<Toggle single checked={selectedProject?.analyticsActivated} onChange={() => handleToggleChange('analyticsActivated')} />
					<label className={styles.toggleLabels}>{t.generalTab.yes}</label>
				</div>
			</div>
		</SidebarBox>
	);
}

export default GeneralBox;
