import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import AccountVerified from './containers/AccountVerified/AccountVerified';
import Analytics from './components/Analytics/Analytics';
import ChangeEmail from "./containers/ChangeEmail/ChangeEmail";
import ChangePassword from './containers/ChangePassword/ChangePassword';
import Dashboard from './components/Dashboard/Dashboard';
import EmailChanged from "./containers/EmailChanged/EmailChanged";
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import Login from './containers/Login/Login';
import LoginFromEmail from "./containers/LoginFromEmail/LoginFromEmail";
import Modal from './containers/Modal/Modal';
import PaySuccess from "./containers/PaySuccess/PaySuccess";
import Preview from './containers/Preview/Preview';
import PriceTable from "./containers/PriceTable/PriceTable";
import PrivateRoute from './utils/PrivateRoute';
import ProjectEditor from './containers/ProjectEditor/ProjectEditor';
import ProjectList from './containers/ProjectList/ProjectList';
import Register from './containers/Register/Register';
import Sessions from './components/Sessions/Sessions';
import Submissions from './components/Submissions/Submissions';
import UserSettings from "./containers/UserSettings/UserSettings";
import Users from './containers/Users/Users';

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/">
					<Redirect to="/login" />
				</Route>
				<Route path="/register/:brandname">
					<Register />
				</Route>
				<Route path="/register">
					<Register />
				</Route>
				<Route path="/login-from-email-link">
					<LoginFromEmail />
				</Route>
				<Route path="/login">
					<Login />
				</Route>
				<Route path="/accountVerified">
					<AccountVerified />
				</Route>
				<Route path="/previewPublic/:id/:widget">
					<Preview public widget />
				</Route>
				<Route path="/previewPublic/:id">
					<Preview public />
				</Route>
				<Route path="/forgot-password">
					<ForgotPassword />
				</Route>
				<Route path="/change-password">
					<ChangePassword />
				</Route>
				<Route path="/downloads">
					<Dashboard noScroll>
						<iframe
							src="https://www.five-digital.de/downloads"
							title="downloads"
							width="100%"
							height={window.innerHeight}
						/>
					</Dashboard>
				</Route>
				<Route path="/ersteschritte">
					<Dashboard noScroll>
						<iframe
							src="https://www.five-digital.de/erste-schritte"
							title="ersteschritte"
							width="100%"
							height={window.innerHeight}
						/>
					</Dashboard>
				</Route>
				<PrivateRoute path="/analytics">
					<Analytics />
				</PrivateRoute>
				<PrivateRoute path="/change-email">
					<ChangeEmail />
				</PrivateRoute>
				<PrivateRoute path="/email-changed">
					<EmailChanged />
				</PrivateRoute>
				<PrivateRoute path="/sessions/:projectID">
					<Sessions />
				</PrivateRoute>
				<PrivateRoute path="/submissions/:projectID">
					<Submissions />
				</PrivateRoute>
				<PrivateRoute path="/projectList">
					<ProjectList />
				</PrivateRoute>
				<PrivateRoute path="/ProjectEditor/:id">
					<ProjectEditor />
				</PrivateRoute>
				<PrivateRoute path="/preview/:id">
					<Preview />
				</PrivateRoute>
				<PrivateRoute path="/user-settings">
					<UserSettings />
				</PrivateRoute>
				<PrivateRoute path="/upgrade">
					<PriceTable />
				</PrivateRoute>
				<PrivateRoute path="/payment-success">
					<PaySuccess />
				</PrivateRoute>
				<PrivateRoute path="/users">
					<Users />
				</PrivateRoute>
				<PrivateRoute path="/:email/projectList">
					<ProjectList />
				</PrivateRoute>

				<Redirect to="/" />
			</Switch>
			<Modal />
		</Router>
	);
}

export default App;
