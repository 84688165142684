import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import toast from "react-hot-toast";
import classNames from 'classnames';

// import logo from '../../assets/images/logo-clickmaster-portrait.svg';
import { ReactComponent as Logo } from '../../assets/images/logo-clickmaster-portrait.svg';
import mailIcon from '../../assets/icons/mail.svg';
import passwordIcon from '../../assets/icons/password.svg';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import {loginUser, loginViaEmailLink, setAuthError, setAuthResponse} from '../../actions/authActions';
import { useTranslation } from '../../utils/useTranslation';
import { validateEmail } from '../../utils/validateEmail';
import styles from './Login.module.scss';

function Login() {
	const auth = useSelector((state) => state.auth);
	const t = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [showErrors, setShowErrors] = useState(false);
	const [password, setPassword] = useState('');
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [formType, setFormType] = useState('password');

	useEffect(() => {
		setEmailError('');
		setShowErrors(false)
		dispatch(setAuthError(''));
	}, [dispatch, email]);

	useEffect(() => {
		setPasswordError('');
		setShowErrors(false)
		dispatch(setAuthError(''));
	}, [dispatch, password]);

	useEffect(() => {
		dispatch(setAuthResponse(''));
		dispatch(setAuthError(''));
	}, [dispatch]);

	useEffect(() => {
		if(auth.error !== "") {
			setShowErrors(true)
		}
	}, [auth.error]);

	useEffect(() => {
		if (auth.isAuth) {
			history.push('/projectList');
		}
	}, [auth.isAuth, history]);

	async function handleSubmit(e) {
		e.preventDefault();
		let error = false;
		// email field is empty
		if (email.length === 0) {
			setShowErrors(true)
			error = true;
			setEmailError(t.login.fieldRequired);
		}
		// email format is incorrect
		else if (!validateEmail(email)) {
			setShowErrors(true)
			error = true;
			setEmailError(t.login.emailInvalid);
		}
		// password field is empty
		if (formType === 'password' && password.length === 0) {
			setShowErrors(true)
			error = true;
			setPasswordError(t.login.fieldRequired);
		}
		if (!error) {
			if (formType === 'password') {
				dispatch(loginUser(email, password));
			} else {
				const { message, error } = await loginViaEmailLink(email);
				if (message) toast.success(message)
				if (error) toast.error(error)
			}
			setShowErrors(false)
		}
	}

	function renderWarning() {
		if (auth.error !== '') {
			return auth.error;
		}
		if (auth.response !== '') {
			return auth.response;
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<div className={styles.textWrapper}>
					<Logo className={styles.logoPortrait} alt="logo" />
					<h1 className={styles.title}>{t.login.welcomeTitle}</h1>
				</div>
			</div>
			<div className={styles.formContainer}>
				<form onSubmit={handleSubmit}>
				<h2 className={styles.title}>
					{formType === 'password' ? t.login.loginTitle : t.login.loginViaEmailLinkTitle}
				</h2>
					<br/>
				<div className={styles.inputWrapper}>
					<Input
						type="text"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						error={emailError}
						placeholder={t.login.emailPlaceholder}
						icon={mailIcon}
						primary
					/>
				</div>
					{formType === 'password' && <div className={styles.inputWrapper}>
						<Input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							error={passwordError}
							placeholder={t.login.passwordPlaceholder}
							icon={passwordIcon}
							primary
						/>
					</div>}

				{ showErrors &&
					<div className={classNames(styles.warning, { [styles.success]: auth.response !== '' })}>
						{renderWarning()}
					</div>
				}
				<div className={styles.submitWrapper}>
					<Button type="submit" primary
													label={formType === 'password' ? t.login.loginButtonLabel : t.login.sendLoginLink}
					/>
				</div>
				<Link to="/forgot-password" className={styles.forgotPasswordlink}>
					{t.login.forgotPasswordLink}
				</Link>
			</form>
			<div className={styles.submitWrapper}>
				<Button primary outline small
												key={formType} // ensure re-render of button after clicking
												label={formType === 'password' ? t.login.loginViaEmailBtnLabel : t.login.loginViaPwBtnLabel}
												onClick={() => setFormType(formType === 'password' ? 'emailLink' : 'password')}
				/>
			</div>
				<div className={styles.noAccountContainer}>
					<h3 className={styles.noAccountLabel}>
						{t.login.noAccountLabel}
					</h3>
					<Link to="/register" className={styles.link}>
						{t.register.registerButtonLabel}
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Login;
