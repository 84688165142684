import React from 'react';
// import { setMuteButton } from '../../actions/audioplayerMuteButtonActions';

// import styles from './AudioPlayer.module.scss';
import ReactAudioPlayer from 'react-audio-player'

// TODO: check whether we still need this component ot whether it is simpler to
// call ReactAudioPlayer directly from wherever it is needed...
// In that case, also delete './AudioPlayer.module.scss'.
const AudioPlayer = (props) => {
	// const dispatch = useDispatch();
	// const [showPlayButton, setShowPlayButton] = useState(props.isFirstScene);
	// const [play, setPlay] = useState(props.isFirstScene ? false : true);
	// const isMuted = useSelector((state) => state.muteButton.isMuted);

	// useEffect(() => {
	// 	setShowPlayButton(props.isFirstScene);
	// }, [props.isFirstScene]);

	// useEffect(() => {
	// 	setPlay(!props.isFirstScene);
	// }, [props.isFirstScene]);

	return (
			<ReactAudioPlayer
				src={props.audioUrl}
				autoPlay={false}
				controls
			/>
	);
};

export default AudioPlayer;
