import { useTranslation } from "./useTranslation";

const t = useTranslation;

export const getTemplateData = (projectId, templateType, { scenesLength, deltaX, deltaY, email }) => {
	switch (templateType) {
		case 'text':
		case 'text_image':
		case 'images':
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				question: `${t().addTab.question} ${scenesLength + 1}`,
				answers: [{ answer: t().generalTab.yes }, { answer: t().generalTab.no } ],
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
		case 'columns':
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				question: `${t().addTab.question} ${scenesLength + 1}`,
				answers: [{ answer: t().generalTab.yes }, { answer: t().generalTab.no }, { answer: t().template.maybe } ],
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
		case 'html':
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				question: `${t().addTab.question} ${scenesLength + 1} - HTML`,
				answers: [{ answer: 'Ja, bitte!' }],
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
		case 'video':
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				question: '',
				answers: [{ answer: t().generalTab.yes }, { answer: t().generalTab.no }],
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
		case 'contact': {
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				answers: [{ answer: t().template.submit }],
				question: `${t().addTab.contactForm} ${scenesLength + 1}`,
				contact: {
					emailLink: email,
					ccEmail: '',
					fields: [
						{
							fieldWidth: "50%",
							name: "name",
							placeholder:	"name",
							requiredField:	true,
							type:	"shortText"
						},
						{
							fieldWidth: "50%",
							name: "email",
							placeholder:	"email",
							requiredField:	true,
							type:	"shortText"
						},
						{
							fieldWidth: "100%",
							name: "message",
							placeholder:	"message",
							requiredField:	false,
							type:	"textarea"
						},
					]
				},
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
		}
		case 'json':
			return {
				projectId,
				title: `${t().sceneTab.scene} ${scenesLength + 1}`,
				question: `${t().addTab.json} ${scenesLength + 1}`,
				answers: [{ answer: '' }],
				template: templateType,
				hasBackButton: !!scenesLength > 0,
				position: {
					x: deltaX,
					y: deltaY,
				},
			};
			case 'freeText':
				return {
					projectId,
					title: `${t().sceneTab.scene} ${scenesLength + 1}`,
					question: `${t().addTab.freeText} ${scenesLength + 1}`,
					freeText: {
						description: '',
						inputType: 'text',
						placeholder: t().sceneTab.placeholder,
						required: false,
						defaultAnswer: t().sceneTab.noAnswer
					},
					answers: [{ answer: t().jsonTemplate.continue }],
					template: templateType,
					hasBackButton: !!scenesLength > 0,
					position: {
						x: deltaX,
						y: deltaY,
					},
				};
		default:
			return {};
	}
};
