import React from 'react';
import { TEMPLATE_DATA, TEMPLATE_DATA_ADMIN, TEMPLATE_DATA_PROFESSIONAL } from '../../constants/SceneConstants';
import { useTranslation } from '../../utils/useTranslation';
import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';
import styles from './AddSceneTab.module.scss';

const AddSceneTab = () => {
	const t = useTranslation();

	let templateData = TEMPLATE_DATA;
	if (ifUserRole(ROLES.PROFESSIONAL)) templateData = TEMPLATE_DATA_PROFESSIONAL;
	if (ifUserRole(ROLES.ADMIN)) templateData = TEMPLATE_DATA_ADMIN;

	return (
		<div className={styles.addSceneTabContainer}>
			<h2 className={styles.title}>{t.addTab.tabTitle}</h2>
			<label className={styles.description}>{t.addTab.tabDescription}</label>
			{templateData.map(({ img, transKey, templateName }) => {
				return (
					<div key={templateName}>
						<img
							src={img}
							alt={transKey}
							className={styles.image}
							draggable={true}
							onDragStart={(e) => {
								e.dataTransfer.setData('templateType/reactflow', templateName)
							}}
						/>
						<p className={styles.sceneTypeTitle}>{t.addTab[transKey]}</p>
					</div>
				);
			})}
		</div>
	);
};

export default AddSceneTab;
