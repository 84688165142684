import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner-svg';
import 'react-loader-spinner-svg/dist/loader/css/react-spinner-loader.css';
import ImageRender from "../ImageRender/ImageRender";
import { ReactComponent as ImageUploadIco } from '../../assets/icons/image-upload.svg';
import { showModal, hideModal } from '../../actions/modalActions';
import { MAX_IMAGE_UPLOAD_SIZE_BYTES } from '../../utils/constants/maxUploadSize';
import { useTranslation } from '../../utils/useTranslation';
import { SUPPORTED_FORMATS } from '../../utils/constants/mediaFormats'
import styles from './ImageUpload.module.scss';

const ENVIRONMENT = process.env.REACT_APP_CURRENT_ENV;
const CLOUDINARY_PROD_PRESET = process.env.REACT_APP_CLOUDINARY_PROD_PRESET;
const CLOUDINARY_DEV_PRESET = process.env.REACT_APP_CLOUDINARY_DEV_PRESET;
const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;

/* called by Answer.js, ImageBox.js and ImageTextBox.js */
function ImageUpload({ onUpload, imagePublicId, imageUrl, token, index, allowLottie= true }) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	let SUPPORTED_UPLOAD_FORMATS = SUPPORTED_FORMATS.IMAGE;
	if(allowLottie) SUPPORTED_UPLOAD_FORMATS += ", " + SUPPORTED_FORMATS.LOTTIE

	function showPopup(title) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				buttonPrimary: {
					label: t.popup.okay,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function handleChange(e) {
		if (e.target.files.length) {
			const file = e.target.files[0]
			if (file.size > MAX_IMAGE_UPLOAD_SIZE_BYTES) {
				showPopup(t.imageUpload.imageMaxSizeTitle);
				return
			}
			handleUpload(file)
				.then((res) => {
					res.json().then((resJSON) => {
						onUpload(resJSON.secure_url, resJSON.public_id);
						setLoading(false);
					});
				})
				.catch(() => {
					setLoading(false);
					alert('Upload failed');
				});
		}
	}

	const isProbablyLottie = (type) => {
		// if not 'image/...', it is a lottie
		return type.split("/")[0] !== "image"
	}

	/**
		* Returns correct url for uploading image or other file types (Cloudinary has different upload
		* @param fileType
		* @returns {string}
		*/
	function getFileUploadUrl(fileType) {
		if (isProbablyLottie(fileType)) {
			// upload url for raw files
			return `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload`;
		}
		// upload url for images
		return `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload`;
	}

	async function handleUpload(file) {
		const formData = new FormData();
		formData.append('file', file);
		if (ENVIRONMENT === 'development') {
			formData.append('upload_preset', CLOUDINARY_DEV_PRESET);
		} else {
			formData.append('upload_preset', CLOUDINARY_PROD_PRESET);
		}
		formData.append('cloud_name', CLOUDINARY_CLOUD_NAME);
		console.log("Upload file.type:", file.type)

		if (isProbablyLottie(file.type)) {
			formData.append('resource_type', 'raw');
		}
		setLoading(true);
		let url = getFileUploadUrl(file.type);
		let upload =  await fetch(url, {
			method: 'POST',
			body: formData,
		});
		// clear input value so user can re-upload same image if desired
		document.getElementById('upload-button' + index).value= null;
		// console.log("Cloudinary 'upload' object:", upload)
		if (upload.ok) {
			// if (imagePublicId) await fetchDeleteImage(token, imagePublicId)  // obsolete with draft/publish
			// 		.catch((error) => console.log('error: ', error));
			return upload;
		}
	}

	return (
		<React.Fragment>
			<label className={styles.inputLabel} htmlFor={'upload-button' + index}>
				{imageUrl ? (
					<>
						{!loading && (
							<ImageRender imageUrl={imageUrl} className={styles.imagePreview} alt="sceneImage" />
						)}
						<Loader type="TailSpin" color="#00BFFF" height={40} width={40} visible={loading} />
					</>
				) : (
					<>
						{!loading && (
							<>
								<ImageUploadIco className={styles.icon} />
								<span className={styles.span}>Upload</span>
							</>
						)}
						<Loader type="TailSpin" color="#00BFFF" height={40} width={40} visible={loading} />
					</>
				)}
			</label>
			<input type="file" id={'upload-button' + index} accept={SUPPORTED_UPLOAD_FORMATS} style={{ display: 'none' }} onChange={handleChange} />
		</React.Fragment>
	);
}

export default ImageUpload;
