import React, {useEffect, useState} from 'react';
import Dashboard from "../../components/Dashboard/Dashboard";
import {useTranslation} from "../../utils/useTranslation";

import styles from "../EmailChanged/EmailChanged.module.scss";
import {useLocation} from "react-router-dom";
import {confirmEmailChanged} from "../../actions/userActions";

function EmailChanged() {
  const t = useTranslation();
  const [ error, setError] = useState('');
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    confirmEmailChanged(token).then(res => {
      if (res.error) setError(res.error);
    })
  },[]);

  return (
    <Dashboard>
      <div className={styles.container}>
        {error ? <h3 className={styles.error}>{t.changeEmail.emailChangeError}</h3>
          :
          <h1 className={styles.title}>{t.changeEmail.emailChangeSuccessful}</h1>}
      </div>
    </Dashboard>
  );
}

export default EmailChanged;
