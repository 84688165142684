import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import toast from "react-hot-toast";
import { ReactComponent as Logo } from '../../assets/images/logo-clickmaster-portrait.svg';
import { loginFromEmailLink } from '../../actions/authActions';
import { useTranslation } from '../../utils/useTranslation';
import styles from './LoginFromEmail.module.scss';

/**
	* Landing page from login via email link
	* Checks token and if all OK it sets authorization in Redux
	* and redirects to /projectList.
	* Otherwise, it renders an error message.
	*/
const LoginFromEmail = () => {
	const auth = useSelector(state => state.auth);
	const t = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [authResult, setAuthResult] = useState({});

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	const query = useQuery();
	const urlToken = query.get('token');
	// console.log('urlToken:', urlToken)

	useEffect(()=>{
		const authCheck = async () => dispatch(loginFromEmailLink(urlToken));
		authCheck().then(res => {
			// console.log('res:', res)
			if (res.error) toast.error(res.error);
			setAuthResult(res)
		});
	},[])

	useEffect(() => {
		if (auth.isAuth && authResult.message === 'ok') {
			history.push('/projectList');
		}
	}, [auth.isAuth, authResult]);

	return (
		<div className={styles.container}>
				<div className={styles.textWrapper}>
					<Logo className={styles.logoPortrait} alt="logo" />
					<h1 className={styles.title}>{t.login.welcomeTitle}</h1>
				</div>
				<div className={styles.noAccountContainer}>
					<h3 className={styles.noAccountLabel}>
						{t.login.noAccountLabel}
					</h3>
					<Link to="/register" className={styles.link}>
						{t.register.registerButtonLabel}
					</Link>
				</div>
		</div>
	);
}

export default LoginFromEmail;
