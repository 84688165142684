// called by ProjectCard.js
import toast from "react-hot-toast";

export const copyToClipboard = (text, message) => {
	/* new code to handle 'TypeError: document.execCommand is not a function' */
	navigator.clipboard.writeText(text).then(  // see: https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
		() => {
			/* clipboard successfully set */
			toast.success(message)
		},
		() => {
			/* clipboard write failed */
			console.error('Error writing to clipboard')
		}
	);

	/* old code (until 3.1.22):
	var dummy = document.createElement('textarea');
	document.body.appendChild(dummy);
	dummy.value = text;
	dummy.select();
	// let result = document.execCommand('copy'); // 'document.execCommand()' is obsolete code
	document.body.removeChild(dummy);
	if (result) {
		showPopup(message);
	}
	*/
};
