import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import Input from '../../components/Input/Input';
import { templateBackground } from '../../utils/templateBackground';
import styles from './ContactTemplate.module.scss';

/* Called by Template.js to show a symbolic preview of the contact form on the canvas. */
function ContactTemplate(props) {

	return (
		<React.Fragment>
			<div
				className={classNames(styles.template)}
				style={templateBackground(props.project?.general?.backgroundImage, props.project?.general?.backgroundColor)}
			>
				<span className={classNames(styles.templateRow)}>
					<div className={styles.titleWrapper}>
							<span className={styles.title}>{props.scene && props.scene.title}</span>
					</div>

					<h1
						className={classNames(styles.question)}
						style={{ color:	props.project?.general?.color?.hex }}
					>
						{props.scene && props.scene.question}{' '}
					</h1>
					<div className={styles.inputRow}>
						<div className={styles.inputWrapper}>
							<Input
								type="text"
								value=""
								name="name"
								onChange={() => {}}
								squared
								template
								placeholder="name *"
								/>
						</div>
						<div className={classNames(styles.inputWrapper, styles.margin)} >
							<Input
								type="text"
								value=""
								name="email"
								onChange={() => {}}
								squared
								template
								placeholder="email *"
							/>
						</div>

					</div>
					<span className={styles.inputRow} style={{ display: 'block' }}>
						<Input
							type="text"
							value=""
							name="message"
							onChange={() => {}}
							squared
							template
							placeholder="message"
						/>
					</span>

					<span className={classNames(styles.buttonsContainer)} style={{ display: 'block' }}>
						<div
							className={styles.buttonWrapper}
							style={{ marginTop: 10 }}
						>
							<Button
								label={props.scene?.answers[0].answer || "Submit"}
								secondary
								onClick={() => {}}
								style={{
									paddingTop: 7,
									paddingBottom: 7,
									minWidth: props.scene.answers.length === 4 && '80px',
									color: props.project?.general?.buttonColor?.hex,
									backgroundColor: props.project?.general?.buttonBackgroundColor?.hex
								}}
							/>
						</div>

					</span>
				</span>
			</div>
		</React.Fragment>
	);
}

export default ContactTemplate;
