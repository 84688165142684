/**
 * checks if a first scene has been set for project and if not, gives a popup warning
 * called by Canvas.js, ProjectCard.js and SceneTab.js
 */

import { hideModal, showModal } from "../actions/modalActions";
import { getScenes } from "../actions/scenesActions";

export const checkForFirstScene = async (scenes, dispatch, t, token, projectId) => {
	// ProjectCard.js does not have access to scenes, so we must get them first
	if (!scenes) {
		const scenesData = await getScenes(projectId, dispatch);
		scenes = scenesData.scenes;
	};
	for (let scene of scenes) {
		if (scene.isFirstScene) return true;
	}
	dispatch(
		showModal('POPUP_MODAL', {
			title: t.preview.startSceneNotDefined,
			subtitle: t.preview.chooseStartScene,
			buttonPrimary: {
				label: t.popup.ok,
				action: () => {
					dispatch(hideModal());
				},
			},
		})
	);
	return null;
}
