import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import toast from "react-hot-toast";
import Canvas from '../Canvas/Canvas';
import Dashboard from '../../components/Dashboard/Dashboard';
import Sidebar from '../Sidebar/Sidebar';
import SubtitleOneBtnToast from "../../components/ToastJsx/SubtitleOneBtnToast";
import { getScenes } from '../../actions/scenesActions';
import {ROLES} from "../../utils/constants/roles";
import {useTranslation} from "../../utils/useTranslation";

// TODO: remove console.log

/**
	* Renders Canvas.js and Sidebar.js for editing a project
	* Called by App.js.
	*/
function ProjectEditor() {
	const t = useTranslation();
	const params = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const project = useSelector(state => state.projects.data[0]);
	const scenes = useSelector(state => state.scenes);
	const user = useSelector(state => state.user);
	console.log('scenes:', scenes)
	const [selectedSceneIds, setSelectedSceneIds] = useState([]);

	useEffect(() => {
		console.log('params.id', params.id)
		if (params.id) getScenes(params.id, dispatch).then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	useEffect(() => {
		// if user and project have been loaded into Redux,
		// but current user is neither the author nor an admin,
		// disable viewing this project
		if (user?._id && project?.userId && (project.userId !== user._id) && (user.role !== ROLES.ADMIN)) {
			// console.log('user._id:', user._id)
			// console.log('project.userId:', project.userId)
			// console.log('user.role:', user.role)
			history.push('/projectList');
			toast( toastMsg => <SubtitleOneBtnToast
				title={t.projectEditor.sorry}
				subtitle={t.projectEditor.onlyAuthor}
				btn1Label={t.popup.ok}
				btn1Fn={()=> setTimeout( () => toast.dismiss(toastMsg.id), 200)}
			/>, {
				duration: 5000
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project, user]);

	return (scenes.data !== null) ? (
		<Dashboard>
			<Canvas
				scenesData={scenes.data}
				// sceneKey={scenes.key}  // ensures re-rendering after scene or answer update
				                       		// do we still need this? - commented out on 14.12.22
				setSelectedSceneIds={setSelectedSceneIds}
				selectedSceneIds={selectedSceneIds}
			/>
			<Sidebar selectedSceneIds={selectedSceneIds} setSelectedSceneIds={setSelectedSceneIds} projectId={params.id} />
		</Dashboard>
	) : (
		(window.location.href = '/projectList')
	);
}

export default ProjectEditor;
