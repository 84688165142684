import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
} from 'reactflow';
import 'reactflow/dist/style.css';
import toast from "react-hot-toast";

import RenderScene from './RenderScene';
import TwoBtnToast from "../../components/ToastJsx/TwoBtnToast";
import SubtitleOneBtnToast from "../../components/ToastJsx/SubtitleOneBtnToast";
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-bgcolor.svg';
import { createScene, updateScene, updateAnswer, deleteScenes, copySceneToCanvas } from '../../actions/scenesActions';
import { getTemplateData } from '../../utils/SceneUtils';
import { useTranslation } from '../../utils/useTranslation';
import { HANDLE_ID_LETTERS } from '../../utils/constants/flowNodeHandles';
import { baseBlueColor } from "../../utils/constants/colors";
import styles from "./Canvas.module.scss";

// const nodeTypes = {
// 	SceneNode: RenderScene,
// };

// const initialNodes = [
// 	{
// 		id: '1',
// 		data: { label: 'Text scene' },
// 		position: { x: 330, y: 50 },
// 		type: 'SceneNode',
// 	},
// 	{
// 		id: '2',
// 		data: { label: 'Text mid Bild Links' },
// 		position: { x: 300, y: 400 },
// 	},
// 	{
// 		id: '3',
// 		data: { label: 'Spalten' },
// 		position: { x: 500, y: 400 },
// 	},
// 	{
// 		id: '4',
// 		data: { label: 'Kontakt Pro' },
// 		position: { x: 400, y: 550 },
// 	},
// ];

// const initialEdges = [
// 	{ id: '1-2', source: '1', sourceHandle: 'a', target: '2', labelBgStyle: { fill: "#F5B700" } },
// 	{ id: '1-3', source: '1', sourceHandle: 'b', target: '3', labelBgStyle: { fill: "#E84855" } },
// 	{ id: '2-4', source: '2', target: '4', labelBgStyle: { fill: '#D5ECD4'} },
// 	{ id: '3-4', source: '3', target: '4', labelBgStyle: { fill: '#D5ECD4'} }
// ];

// TODO: remove console.logs

/* Called by Canvas.js.
   Renders the React-Flow chart with the current project's scenes on the canvas. */
function Flow({ scenes, setSelectedSceneIds, selectedSceneIds, project }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.user);
  // const selectedTemplate = useSelector((state) => state.addTab.newScene.templateName);
  const reactFlowWrapper = useRef(null);
  const t = useTranslation();
  const [nodes, setNodes] = useState([]); // useState(initialNodes);
  const [edges, setEdges] = useState([]); // useState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  // const isCopyActive = useRef(null);
  let draggedScenePositions = {}
  // let currentScenePosition = { x: null, y: null }

  const nodeTypes = useMemo(()=>{
    const sceneTypes = {}
    for (const scene of scenes) {
      console.log('scene:', scene)
      sceneTypes[scene._id] = () => <RenderScene
        scene={scene}
        selectedSceneIds={selectedSceneIds}
        setSelectedSceneIds={setSelectedSceneIds}
        copyScene={() => dispatchCopyScene(scene._id, scene.position)}
        deleteScene={()=> dispatchDeleteScenes([scene._id])}
        templateType={scene.template}
        project={project}
        // isSelectedScene={scene._id === selectedSceneId}
      />
    }
    return sceneTypes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, scenes, selectedSceneIds]); // also update changes to project general settings

  useEffect(()=>{
    //setNodes here
    const sceneNodes = []
    let idx = 0  // TODO: clean this up once the draft logic is complete
    for (const scene of scenes) {
      const sceneNode = {
        id: scene._id,
        targetPosition: 'top',
        position: { x: scene.position?.x || 50 * idx, y: scene.position?.y || 50 * idx },
        // position: { x: scene.position.x, y: scene.position.y},
        type: scene._id
      }
      sceneNodes.push(sceneNode)
      idx++
    }
    setNodes(sceneNodes)
    //setEdges here
    const sceneEdges = []
    for (const scene of scenes) {
      for (let i = 0; i < scene.answers?.length; i++) {
        const sceneEdge = {
          id: scene._id + '-' + scene.answers[i].sceneId + '-' + i,
          source: scene._id,
          sourceHandle: HANDLE_ID_LETTERS[i],
          target: scene.answers[i].sceneId,
          style: { strokeWidth: 2, stroke: baseBlueColor },
          // labelBgStyle: { fill: "#F5B700" },
          // markerEnd: {
          // 	type: MarkerType.ArrowClosed,
          // 	color: baseBlueColor,
          // 	width: 15,
          // 	height: 15,
          // },
        }
        sceneEdges.push(sceneEdge)
      }
    }
    setEdges(sceneEdges)
  },[scenes])

  const onNodesChange = useCallback(
    (changes) => {
      console.log('node changes:', changes)
      console.log('nodes:', nodes)
      console.log('scenes:', scenes)
      setNodes((nds) => applyNodeChanges(changes, nds));
      // if node is being dragged, record current position
      if (changes[0].dragging === true) {
        for (const change of changes) {
          draggedScenePositions[change.id] = { x: change.position.x, y: change.position.y };
        }
      }
      // if (changes[0].dragging === true) {
      // 	currentScenePosition = { x: changes[0].position.x, y: changes[0].position.y };
      // }
      console.log('draggedScenePositions:', draggedScenePositions)
      // if node-dragging was done and is now finished, save new position to DB
      if (Object.keys(draggedScenePositions).length && changes[0].dragging === false) {
        for (const scene of scenes) {
          if (draggedScenePositions[scene._id]) {
            dispatch(updateScene(
              token,
              scene._id,
              { position: draggedScenePositions[scene._id] },
              project.hasDraftScenes
            ));
          }
          // if (changes[0].id === scene._id) {
          // 	dispatch(updateScene(token, scene._id, { position: currentScenePosition }));
          // 	currentScenePosition = {};
          // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        draggedScenePositions = {};
        setSelectedSceneIds([]);  // React-Flow multi-select resets after movement, so we must do so also.
      }
    },
    [project]  // update project.hasDraftScenes when project is updated
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
      console.log('onConnect params:', params)
      dispatch(updateAnswer(
        token,
        params.source,
        HANDLE_ID_LETTERS.indexOf(params.sourceHandle),
        { sceneId: params.target },
        project.hasDraftScenes
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project]  // update project.hasDraftScenes when project is updated
  );

  const dispatchCopyScene = (sceneId, position) => {
    console.log('sceneId for copying:', sceneId)
    console.log('position for copying:', position)
    dispatch(copySceneToCanvas(
      token,
      sceneId,
      { x: position.x + 50, y: position.y + 50 },
      project.hasDraftScenes
    ));
  }
  const bulkCopyScenes = () => {
    for (const scene of scenes) {
      if (selectedSceneIds.includes(scene._id)) {
        dispatch(copySceneToCanvas(
          token,
          scene._id,
          { x: scene.position.x + 50, y: scene.position.y + 50 },
          project.hasDraftScenes
        ));
      }
    }
  }

  const dispatchDeleteScenes = (sceneIds) => {
    if (!sceneIds?.length) {
      console.error('No sceneIds for deletion!');
      return;
    }
    const popupMessage = sceneIds.length === 1 ? t.sceneTab.confirmDelete : t.canvas.confirmBulkDelete;
    toast(toastMsg => <TwoBtnToast
        title={popupMessage}
        btn1Label={t.popup.cancel}
        btn1Fn={()=> setTimeout( () => toast.dismiss(toastMsg.id), 200)}
        btn2Label={t.popup.confirm}
        btn2Fn={() => {
          console.log('sceneIds for deletion:', sceneIds)
          dispatch(deleteScenes(token, project._id, sceneIds, project.hasDraftScenes));
          setSelectedSceneIds([]);
          setTimeout( () => toast.dismiss(toastMsg.id), 200);
          // If deleted scene was the first scene, alert with popup message
          let missingFirstSceneAlert = false;
          for (const scene of scenes) {
            if (sceneIds.includes(scene._id) && scene.isFirstScene) missingFirstSceneAlert = true;
          }
          if (missingFirstSceneAlert)
            toast( toastMsg => <SubtitleOneBtnToast
              title={t.preview.startSceneNotDefined}
              subtitle={t.preview.chooseStartScene}
              btn1Label={t.popup.ok}
              btn1Fn={()=> setTimeout( () => toast.dismiss(toastMsg.id), 200)}
              />, {
              duration: 5000
            });
        }}
      />, {
        duration: 6000
    });
    // obsolete with react-hot-toast above:
    // dispatch(
    //   showModal('POPUP_MODAL', {
    //     title: popupMessage,
    //     buttonPrimary: {
    //       label: t.popup.yes,
    //       action: () => {
    //         console.log('sceneIds for deletion:', sceneIds)
    //         dispatch(deleteScenes(token, project._id, sceneIds, project.hasDraftScenes));
    //         setSelectedSceneIds([]);
    //         dispatch(hideModal());
    //         // If deleted scene was the first scene, alert with popup message
    //         let missingFirstSceneAlert = false;
    //         for (const scene of scenes) {
    //           if (sceneIds.includes(scene._id) && scene.isFirstScene) missingFirstSceneAlert = true;
    //         }
    //         if (missingFirstSceneAlert)
    //           dispatch(
    //             showModal('POPUP_MODAL', {
    //               title: t.preview.startSceneNotDefined,
    //               subtitle: t.preview.chooseStartScene,
    //               buttonPrimary: {
    //                 label: t.popup.ok,
    //                 action: () => {
    //                   dispatch(hideModal());
    //                 },
    //               },
    //             })
    //           );
    //       },
    //     },
    //     buttonSecondary: {
    //       label: t.popup.no,
    //       action: () => {
    //         dispatch(hideModal());
    //       },
    //     },
    //   })
    // );
  }

  const onCanvasClick = useCallback((event) => {
    // event.preventDefault();
    console.log('canvas clicked')
    setSelectedSceneIds([]);
    // console.log('isCopyActive:', isCopyActive)
    // if (!isCopyActive.current) return
    // const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    // const position = reactFlowInstance.project({
    // 	x: event.clientX - reactFlowBounds.left,
    // 	y: event.clientY - reactFlowBounds.top,
    // });
    // dispatchCopyScene(position);
    // isCopyActive.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactFlowInstance, selectedSceneIds]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      console.log('onDrop called.')
      // console.log('project:', project)
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const templateType = event.dataTransfer.getData('templateType/reactflow');
      // check if the dropped element is valid
      if (typeof templateType === 'undefined' || !templateType) {
        return;
      }
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      setSelectedSceneIds([]);
      dispatch(
        createScene(
          token,
          getTemplateData(project._id, templateType || 'text', {
            scenesLength: scenes.length,
            deltaX: position.x,
            deltaY: position.y,
            email: user.email,
          }),
          project.hasDraftScenes // if false, this flag needs to be set after creating the new scene
        )
      );
      // dispatch(setSelectedTemplate(null));  // the click-on-template-click-on-canvas + Redux functionality is now obsolete
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlowInstance]
  );

  return (
    <div
      style={{ height: '100%' }}
      ref={reactFlowWrapper}
      onClick={(e) => onCanvasClick(e)}
    >
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onInit={setReactFlowInstance}
        onDrop={onDrop}
        onDragOver={onDragOver}
        minZoom={0.05}
        multiSelectionKeyCode={"Shift"} // enable selection using shift + click
        selectionKeyCode={null} // disable selection by dragging the mouse // if it is rerendering too many times check the docs: https://reactflow.dev/docs/api/react-flow-props/#selectionkeycode
        fitView
      >
        <Background />
        <Controls />
      </ReactFlow>
      {selectedSceneIds.length > 1 && <div>
        <span
          onClick={bulkCopyScenes}
          className={styles.bulkCopyButton}
          title={t.canvas.copyScenes}
        >
         <CopyIcon className={styles.bulkCopyIcon} />
        </span>
        <span
          onClick={()=> dispatchDeleteScenes(selectedSceneIds)}
          className={styles.bulkDeleteButton}
          title={t.canvas.deleteScenes}
        >
          <DeleteIcon className={styles.bulkDeleteIcon} />
        </span>
      </div>}
    </div>
  );
}

export default Flow
