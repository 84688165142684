import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Dashboard from '../../components/Dashboard/Dashboard';
import Button from "../../components/Button/Button";
import { ReactComponent as MinusIco } from '../../assets/icons/minus-circle.svg';
import {getUsers, updateUsers, deleteUsers, syncUsersSubscriptions} from '../../actions/usersActions';
import { showModal, hideModal } from '../../actions/modalActions';
import { useTranslation } from '../../utils/useTranslation';
import {formatDate, formatDateHHMM} from "../../utils/formatDate";
import styles from './Users.module.scss';
import SelectInput from "../../components/SelectInput/SelectInput";
import { ROLES } from "../../utils/constants/roles";
import Toggle from "../../components/Toggle/Toggle";

const STRIPE_SUBSCRIPTION_URL = process.env.REACT_APP_CURRENT_ENV === "production" ?
  "https://dashboard.stripe.com/subscriptions/"
  : "https://dashboard.stripe.com/test/subscriptions/"

function Users() {
  const t = useTranslation();
  const users = useSelector((state) => state.users.data);
  const token = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers(token));
  }, [dispatch, token]);

  const handleSync = () => {
    syncUsersSubscriptions().then(res => {
      if (res === 'ok') dispatch(getUsers(token));
      else console.error("syncUsersSubscriptions() failed!")
    }).catch(err => console.error("syncUsersSubscriptions() error:", err))
  }

  function deleteUser(username, email) {
    dispatch(
      showModal('POPUP_MODAL', {
        title: `${t.users.areYouSureYouWantToDeleteUser} "${username}" ${t.users.fromTheDataBase}`,
        buttonPrimary: {
          label: t.popup.yes,
          action: () => {
            dispatch(deleteUsers(token, email));
            dispatch(hideModal());
          },
        },
        buttonSecondary: {
          label: t.popup.no,
          action: () => {
            dispatch(hideModal());
          },
        },
      })
    );
  }

  function handleRoleChange(user, role) {
    dispatch(updateUsers(token, user.email, { ...user, role: role }));
  }

  /**
   * Options for role change management
   * @param user
   * @returns {[{onClick: (function(): void), label: string},{onClick: (function(): void), label: string},{onClick: (function(): void), label: string},{onClick: (function(): void), label: string}]}
   */
  const roleDropdownOptions = (user) => {

    let linkOptions = [
      {
        label: ROLES.FREE,
        onClick: () => handleRoleChange(user, ROLES.FREE),
      },
      {
        label: ROLES.PREMIUM,
        onClick: () => handleRoleChange(user, ROLES.PREMIUM),
      },
      {
        label: ROLES.PROFESSIONAL,
        onClick: () => handleRoleChange(user, ROLES.PROFESSIONAL),
      },
      {
        label: ROLES.ADMIN,
        onClick: () => handleRoleChange(user, ROLES.ADMIN),
      }
    ];

    return linkOptions;
  }

  const tableRows = users.map((user, index) => {
    return (
      <tr className={styles.tr} key={index}>
        <td className={classNames(styles.td, styles.textLeft)}>{user.email}</td>
        <td className={classNames(styles.td, styles.textLeft)}>{user.username}</td>
        {/*<td className={styles.td}>{user.belongsTo ? user.belongsTo : '-'}</td>*/}
        <td className={styles.td}>{formatDate(user.created)}</td>
        <td className={styles.td}>
          {user.projectCount === 0 ? (
            0
          ) : (
            <Link to={'/' + user.email + '/projectList'} className={styles.link}>
              {user.projectCount}
            </Link>
          )}
        </td>
        <td className={styles.td}>
          <Toggle
            single
            checked={user.active}
            onChange={() => {
              dispatch(updateUsers(token, user.email, { ...user, active: !user.active }));
            }}
          />
        </td>
        <td className={styles.td}>
          <SelectInput
            options={roleDropdownOptions(user)}
            selected={user.role}
            navbar
          />
        </td>
        <td className={styles.td}>
          <a href={user.stripe?.subscription && `${STRIPE_SUBSCRIPTION_URL}${user.stripe?.subscription}`}>
            {user.stripe?.plan?.nickname}
          </a>
        </td>
        <td className={styles.td} title={user.stripe?.last_sync &&`${formatDateHHMM(user.stripe.last_sync)}`}>
          {user.stripe?.subscription_status}
        </td>
        <td className={styles.td}>
          {user.stripe?.current_period_end ? formatDate(user.stripe.current_period_end) : "-----"}
        </td>
        <td className={styles.td}>
          <MinusIco className={styles.minusIco} onClick={() => deleteUser(user.username, user.email)} />
        </td>
      </tr>
    );
  });

  return (
    <Dashboard>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <h1 className={styles.title}>
            User management ({users.length})
          </h1>
          <div className={styles.syncButton}>
            <Button primary small label={t.users.syncSubscriptions}
                    onClick={handleSync}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
            <tr className={styles.tr}>
              <th className={classNames(styles.th, styles.textLeft)}>Email</th>
              <th className={classNames(styles.th, styles.textLeft)}>Username</th>
              {/*<th className={styles.th}>Belongs to</th>*/}
              <th className={styles.th}>Registered</th>
              <th className={styles.th}>Projects</th>
              {/*<th className={styles.th}>Project Views</th>*/}
              <th className={styles.th}>Active</th>
              <th className={styles.th}>Role</th>
              <th className={styles.th}>Subscription Name</th>
              <th className={styles.th}>Subscription Status</th>
              <th className={styles.th}>Period End</th>
              <th className={styles.th} />
            </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
        </div>
      </div>
    </Dashboard>
  );
}

export default Users;
