import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import {changePassword, resetPassword, setAuthError, setAuthResponse} from '../../actions/authActions';
import { useTranslation } from '../../utils/useTranslation';

import styles from './ChangePassword.module.scss';
import logo from '../../assets/images/logo-clickmaster.svg';
import passwordIcon from '../../assets/icons/password.svg';
import { MIN_PASSWORD_LENGTH } from '../../utils/constants/minPwLength';

/**
	* There are two usages for this component:
	* (1) A non-logged-in user lands here via '/change-password?token=<jwt>'
	* from forgot-password email link and can reset their password here.
	* This is the 'reset-password' form type and calls the reset-password route.
	* (2) A logged-in user lands here from UserSettings.js to change their password.
	* This is the 'change-password' form type and calls the change-password route.
	* */
function ChangePassword() {
	const t = useTranslation();
	const dispatch = useDispatch();
	const auth = useSelector(state => state.auth);
	const user = useSelector(state => state.user);
	const [oldPassword, setOldPassword] = useState('');
	const [pwChangeMsg, setPwChangeMsg] = useState({});
	const [password, setPassword] = useState('');
	const [passwordRepeat, setPasswordRepeat] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [passwordRepeatError, setPasswordRepeatError] = useState('');

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	const query = useQuery();
	const urlToken = query.get('token');

	const formtype = urlToken || !user?._id ? "reset-password" : "change-password";

	useEffect(() => {
		setPwChangeMsg({});
	}, [oldPassword]);

	useEffect(() => {
		setPasswordError('');
		dispatch(setAuthError(''));
	}, [password]);

	useEffect(() => {
		setPasswordRepeatError('');
		dispatch(setAuthError(''));
	}, [passwordRepeat]);

	// useEffect(() => {
	// 	dispatch(setAuthResponse(''));
	// 	dispatch(setAuthError(''));
	// }, [dispatch]);

	function handleSubmit(e) {
		e.preventDefault();
		let error = false;
		if (password.length === 0) {
			error = true;
			setPasswordError(t.login.fieldRequired);
		} else if (password.length < MIN_PASSWORD_LENGTH) {
			error = true;
			setPasswordError(t.register.passwordTooShort);
		} else if (password !== passwordRepeat) {
			error = true;
			setPasswordError(t.register.passwordMismatch);
		}
		if (passwordRepeat.length === 0) {
			error = true;
			setPasswordRepeatError(t.login.fieldRequired);
		} else if (password !== passwordRepeat) {
			error = true;
			setPasswordRepeatError(t.register.passwordMismatch);
		}
		if (!error && formtype === 'reset-password') {
			dispatch(resetPassword(urlToken, password));
			setPassword('');
			setPasswordRepeat('');
			setPasswordError('');
			setPasswordRepeatError('');
		} else if (!error && formtype === 'change-password'){
			changePassword(oldPassword, password).then(res => {
				// console.log('changePassword() result object: ', res)
				if (res.message) setPwChangeMsg({ success: res.message });
				else setPwChangeMsg({ error: res.error || t.changePassword.passwordChangeError });
			}).catch(err => {
				console.log('err:', err)
				setPwChangeMsg({ error: err });
			})
		}
	}

	function renderResultMessage() {
		const message = auth.error ? auth.error
			: auth.response ? auth.response
				: pwChangeMsg.error ? pwChangeMsg.error
					: pwChangeMsg.success ? pwChangeMsg.success
						: "";
		return message;
	}

	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<div className={styles.textWrapper}>
					<h1 className={styles.title}>
						{t.changePassword.resetPwText1} <br />
						{t.changePassword.resetPwText2}
					</h1>
					<h2 className={styles.subtitle}>
						{t.changePassword.chooseNewSafePwText1}
						<br /> {t.changePassword.chooseNewSafePwText2}
					</h2>
				</div>
			</div>
			<form className={styles.form} onSubmit={handleSubmit}>
				<img src={logo} className={styles.logo} alt="logo" />
				{formtype === 'change-password' && <div className={styles.inputWrapper}>
					<Input
						type="password"
						value={oldPassword}
						onChange={(e) => setOldPassword(e.target.value)}
						// error={pwChangeMsg.error}
						placeholder={t.changePassword.oldPasswordPlaceholder}
						icon={passwordIcon}
						primary
					/>
				</div>}
				<div className={styles.inputWrapper}>
					<Input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						error={passwordError}
						placeholder={t.changePassword.newPasswordPlaceholder}
						icon={passwordIcon}
						primary
					/>
				</div>
				<div className={styles.inputWrapper}>
					<Input
						type="password"
						value={passwordRepeat}
						onChange={(e) => setPasswordRepeat(e.target.value)}
						error={passwordRepeatError}
						placeholder={t.changePassword.repeatPasswordPlaceholder}
						icon={passwordIcon}
						primary
					/>
				</div>
				<div className={classNames(styles.message, { [styles.success]: auth.response !== '' || pwChangeMsg.success })}>
					{renderResultMessage()}
				</div>
				<div className={styles.submitWrapper}>
					<Button label={t.changePassword.savePassword} type="submit" primary />
				</div>
				<Link to="/login" className={styles.forgotPasswordLink}>
					{t.changePassword.backToLogin}
				</Link>
			</form>
		</div>
	);
}

export default ChangePassword;
