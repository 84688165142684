import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { showModal, hideModal } from '../../actions/modalActions';
import upload from '../../assets/icons/upload.svg';
import { updateScene } from "../../actions/scenesActions";
import { useTranslation } from '../../utils/useTranslation';
import styles from './FileUpload.module.scss';

const ENVIRONMENT = process.env.REACT_APP_CURRENT_ENV;
const CLOUDINARY_PROD_PRESET = process.env.REACT_APP_CLOUDINARY_PROD_PRESET;
const CLOUDINARY_DEV_PRESET = process.env.REACT_APP_CLOUDINARY_DEV_PRESET;
const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;

function FileUpload(props) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

	function showPopup(title) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				buttonPrimary: {
					label: t.popup.okay,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function checkFileExtension(fileName) {
		let fileExtension = fileName.split('.').pop();

		const SUPPORTED_EXTENSION_JSON = 'json';

		if (fileExtension !== SUPPORTED_EXTENSION_JSON) {
			showPopup(t.jsonTemplate.selectJsonFile);
			return false;
		} else {
			return true;
		}
	}

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	async function handleChange(event) {
		let file = event.target.files[0];

		if (!checkFileExtension(file.name)) {
			return;
		}
		const formData = new FormData();
		let reader = new FileReader();
		let fileSize = file.size;
		const THREE_MB_IN_BYTES = 3145728;
		//We need to convert our video file to base64 so we can upload it
		const base = await toBase64(file);

		if (fileSize > THREE_MB_IN_BYTES) {
			showPopup(t.audioVoiceover.audioMaxSizeTitle);
		} else {
			reader.addEventListener(
				'load',
				async function () {
					formData.append('file', base);

					if (ENVIRONMENT === 'development') {
						formData.append('upload_preset', CLOUDINARY_DEV_PRESET);
					} else {
						formData.append('upload_preset', CLOUDINARY_PROD_PRESET);
					}

					formData.append('cloud_name', CLOUDINARY_CLOUD_NAME);
					formData.append('resource_type', 'file');

					let cloudinaryUploadURL = 'https://api.cloudinary.com/v1_1/' + CLOUDINARY_CLOUD_NAME + '/upload';
					let requestObj = {
						url: cloudinaryUploadURL,
						method: 'POST',
						data: formData,
						onUploadProgress: function (progressEvent) {
							//Calculate percentage and send it to the parent component (VideoBox)
							props.getProgress((progressEvent.loaded * 100) / progressEvent.total);
						},
					};

					axios(requestObj)
						.then((res) => {
							let scene = props.selectedScene;
							let token = props.token;
							let sceneID = scene._id;
							scene.jsonFileUrl = res.data.secure_url;
							scene.jsonFileName = file.name;
							scene.jsonFilePublicId = res.data.public_id;
							scene.jsonVariablesDescription = '';
							scene.jsonFileSource = '';
							scene.jsonSearchKey = '';
							dispatch(updateScene(token, sceneID, scene, projectHasDraftScenes));
						})
						.catch((error) => console.log(error));
				},
				false
			);
			if (file && file.name) {
				reader.readAsDataURL(file);
			}
		}
	}

	return (
		<React.Fragment>
			{props?.selectedScene?.jsonFileUrl ? (
				<div>{props.selectedScene.jsonFileName}</div>
			) : (
				<div>
					<div className={styles.uploadBox}>
						<div className={styles.uploadWrapper}>
							<img src={upload} alt="uploadIco" className={styles.uploadIcon} />
							<input
								className={styles.fileUpload}
								type="file"
								id="jsonFile"
								name="jsonFile"
								accept=".json"
								onChange={(event) => handleChange(event)}
							/>
							<span className={styles.inputText}>{t.audioVoiceover.clickToUpload}</span>
						</div>
					</div>
					<p className={styles.fileExtension} style={{ marginTop: '5px' }}>
						JSON
					</p>
					<p className={styles.fileExtension}>{t.audioVoiceover.maxSize}</p>
				</div>
			)}
		</React.Fragment>
	);
}

export default FileUpload;
