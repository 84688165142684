import React from 'react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { useTranslation } from '../../utils/useTranslation';
import { templateBackground } from '../../utils/templateBackground';
import styles from './FreeTextTemplate.module.scss';

function FreeTextTemplate({ project, scene }) {
  const t = useTranslation();

  return (
    <React.Fragment>
      <div
        className={styles.template}
        style={templateBackground(project?.general?.backgroundImage, project?.general?.backgroundColor)}
      >
        <span className={styles.templateRow}>
          <div className={styles.titleWrapper}>
           <span className={styles.title}>{scene?.title}</span>
          </div>
         <h1 className={styles.question} style={{ color: project?.general?.color?.hex }}>
          {scene?.question}
         </h1>
         <span className={styles.inputRow} style={{ display: 'block' }}>
          <Input
            type='textarea'
            value=""
            name='text'
            onChange={() => {}}
            squared
            template
            placeholder="Free text"
          />
         </span>
         <span className={styles.buttonsContainer} style={{ display: 'block' }}>
          <div className={styles.buttonWrapper}>
           <Button
             label={scene?.answers[0].answer || t.jsonTemplate.continue}
             secondary
             onClick={() => {}}
             style={{
               color: project?.general?.buttonColor?.hex,
               backgroundColor: project?.general?.buttonBackgroundColor?.hex,
             }}
           />
          </div>
         </span>
        </span>
      </div>
    </React.Fragment>
  );
}

export default FreeTextTemplate;
