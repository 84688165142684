import axios from "axios";
import {GET_SCENES} from "./scenesActions";
import {authHeaders} from "../utils/authHeaders";

export const GET_PROJECTS = 'GET_PROJECTS';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const COPY_PROJECT = 'COPY_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const PROJECT_ERROR = 'PROJECT_ERROR';
export const PROJECT_RESPONSE = 'PROJECT_RESPONSE';
export const UPDATE_PROJECT_PROP = 'UPDATE_PROJECT_PROP';
export const SET_ADMIN_VIEW = 'SET_ADMIN_VIEW';
export const INCREASE_VIEW_COUNT = 'INCREASE VIEW COUNT';

const apiUrl = process.env.REACT_APP_BE_URL;

/**
 * Sets projects error
 * @param {string} data
 * @returns {void}
 */
export const setProjectError = (data) => {
	return {
		type: PROJECT_ERROR,
		payload: data,
	};
};

/**
 * Sets projects response
 * @param {string} response
 * @returns {void}
 */

export const setProjectResponse = (response) => {
	return {
		type: PROJECT_RESPONSE,
		payload: response,
	};
};

export const getProject = (project) => {
	return {
		type: GET_PROJECTS,
		payload: [project],
	};
};

export const increaseViewCount = (increment, projectId) => {
	return {
		type: INCREASE_VIEW_COUNT,
		payload: {
			increment: increment,
			projectId: projectId,
		},
	};
};

export const fetchProjects = async (token) => {
	try {
		const res = await fetch(apiUrl + '/projects', {
			method: 'GET',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Gets all projects
 * @param {string} token auth token
 */
export const getProjects = (token) => {
	return (dispatch) => {
		fetchProjects(token).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setProjectError(resJSON.error));
				} else {
					dispatch({
						type: GET_PROJECTS,
						payload: resJSON.projects,
					});
					dispatch({
						type: SET_ADMIN_VIEW,
						payload: false,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setProjectError(resJSON));
			}
		});
	};
};

export const fetchCreateProject = async (token, title, description, general) => {
	try {
		const res = await fetch(apiUrl + '/projects', {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({
				title: title,
				description: description,
				general: general,
			}),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Create project
 * @param {string} token auth token
 * @param {string} title
 * @param {string} description
 */
export const createProject = (token, title, description, general, callback) => {
	return (dispatch) => {
		fetchCreateProject(token, title, description, general).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setProjectError(resJSON.error));
				} else {
					dispatch(setProjectResponse(resJSON.message));
					dispatch({
						type: CREATE_PROJECT,
						payload: resJSON.project,
					});
					if (callback) callback(resJSON.project._id.toString());
				}
			} else {
				console.error(resJSON);
				dispatch(setProjectError(resJSON));
			}
		});
	};
};

export const fetchProjectOwnerChange = async (token, projectId, userId) => {
	try {
		const res = await fetch(`${apiUrl}/projects/${projectId}/changeOwner`, {
			method: 'PUT',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({ userId }),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

export const fetchUpdateProject = async (token, project) => {
	try {
		const res = await fetch(apiUrl + '/projects/' + project._id, {
			method: 'PUT',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify(project),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Update project
 * @param {string} token
 * @param {object} project
 */
export const updateProject = (token, project) => {
	return (dispatch) => {
		fetchUpdateProject(token, project).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setProjectError(resJSON.error));
				} else {
					dispatch(setProjectResponse(resJSON.message));
					dispatch({
						type: UPDATE_PROJECT,
						payload: resJSON.project,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setProjectError(resJSON));
			}
		});
	};
};

export const fetchPublishProject = async (token, projectId) => {
	try {
		const res = await fetch(apiUrl + '/projects/publish/' + projectId, {
			method: 'GET',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
	* Converts current draft version into published version of project (same with all its scenes)
	* @param {string} token
	* @param {string} projectId
	* Updates redux with current project and scenes
	*/
export const publishProject = (token, projectId) => {
	return (dispatch) => {
		fetchPublishProject(token, projectId).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setProjectError(resJSON.error));
				} else {
					dispatch(setProjectResponse(resJSON.message));
					dispatch({
						type: UPDATE_PROJECT,
						payload: resJSON.project,
					});
					dispatch({
						type: GET_SCENES,
						payload: resJSON.scenes,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setProjectError(resJSON));
			}
		});
	};
};

/**
	* Delete all unpublished changes of a project and scenes (revert to published version)
	* @param {string} projectId
	* @param {Dispatch<any>} dispatch
	* Updates redux with scenes and refreshed project
	*/
export const deleteDraft = async (projectId, dispatch) => {
	await axios
		.get(
			`${apiUrl}/projects/deleteDraft/${projectId}`,
			authHeaders()
		).then((res) => {
			const { project, scenes, error } = res.data
			// console.log('deleteDraft res.data:', res.data)
			if (error) {
				console.error(error);
				dispatch(setProjectError(error));
			} else {
				dispatch({
					type: UPDATE_PROJECT,
					payload: project,
				});
				dispatch({
					type: GET_SCENES,
					payload: scenes,
				});
			}
		}).catch(err => console.error(err))
}

export const fetchDeleteProject = async (token, projectId) => {
	try {
		const res = await fetch(apiUrl + '/projects/' + projectId, {
			method: 'DELETE',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Delete project
 * @param {string} token auth token
 * @param {string} projectId
 */
export const deleteProject = (token, projectId) => {
	return (dispatch) => {
		fetchDeleteProject(token, projectId).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setProjectError(resJSON.error));
				} else {
					dispatch(setProjectResponse(resJSON.message));
					dispatch({
						type: DELETE_PROJECT,
						payload: projectId,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setProjectError(resJSON));
			}
		});
	};
};

export const fetchProjectsByEmail = async (token, email) => {
	try {
		const res = await fetch(apiUrl + '/projects/' + email, {
			method: 'GET',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Gets all projects by email (Users view)
 * @param {string} token auth token
 * @param {string} email email
 */
export const getProjectsByEmail = (token, email) => {
	return (dispatch) => {
		fetchProjectsByEmail(token, email).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setProjectError(resJSON.error));
				} else {
					dispatch({
						type: GET_PROJECTS,
						payload: resJSON.projects,
					});
					dispatch({
						type: SET_ADMIN_VIEW,
						payload: true,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setProjectError(resJSON));
			}
		});
	};
};

/* Project FE actions */
export const updateProjectProp = (projectId, name, value) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_PROJECT_PROP,
			projectId: projectId,
			name: name,
			value: value,
		});
	};
};

export const fetchCopyProject = async (token, projectId) => {
	try {
		const res = await fetch(apiUrl + '/projects/copy', {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({
				projectId: projectId,
			}),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

export const copyProject = (token, projectId) => {
	return (dispatch) => {
		fetchCopyProject(token, projectId).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setProjectError(resJSON.error));
				} else {
					dispatch(setProjectResponse(resJSON.message));
					dispatch({
						type: COPY_PROJECT,
						payload: resJSON.project,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setProjectError(resJSON));
			}
		});
	};
};

