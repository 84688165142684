import { updateScene } from '../../actions/scenesActions';
import {useTranslation} from "../../utils/useTranslation";

const t = useTranslation;
export const createSceneTemplateOptions = (templateData, selectedScene, projectHasDraftScenes, user, token, dispatch) => {
  // console.log("templateData", templateData)
  // console.log("selectedScene", selectedScene)
  // console.log("selectedScene.answers", selectedScene.answers)
  if (!selectedScene) return [];  // avoid crash after deleting scene
  return templateData.map( template => {
    let answers;
    switch (template.templateName) {
      case 'text':
      case 'text_image':
        answers =
          selectedScene.answers.length === 0
            ? [{
              answer: t().template.choose,
              sceneId: '',
            }]
            : selectedScene.answers.length <= 4
              ? selectedScene.answers.slice(0, selectedScene.answers.length).map((answ) => {
                return {
                  ...answ,
                  answer: answ.answer,
                  imageTitle: '',
                  richText: '',
                  price: '',
                  imageUrl: '',
                };
              })
              : selectedScene.answers.length > 4
                ? selectedScene.answers.slice(0, 4).map((answ) => {
                  return {
                    ...answ,
                    answer: t().template.choose,
                    imageTitle: '',
                    richText: '',
                    price: '',
                    imageUrl: '',
                  };
                })
                : [selectedScene.answers.map((answ) => {
                  return {
                    ...answ,
                    answer: t().template.choose,
                    sceneId: '',
                    imageTitle: '',
                    richText: '',
                    price: '',
                    imageUrl: '',
                  };
                })[0]]
        break;
      case 'images':
        answers =
          selectedScene.answers.length === 0
            ? [{
              answer: t().template.choose,
              sceneId: '',
            }]
            : selectedScene.answers.length <= 4
              ? selectedScene.answers.slice(0, selectedScene.answers.length).map((answ) => {
                return {
                  ...answ,
                  answer: answ.answer,
                  imageTitle: '',
                  richText: '',
                  price: '',
                  imageUrl: '',
                };
              })
              : selectedScene.answers.map((answ) => {
                return {
                  ...answ,
                  answer: t().template.choose,
                  imageTitle: '',
                  richText: '',
                  price: '',
                  imageUrl: '',
                };
              })
        break;
      case 'columns':
      case 'video':
      case 'html':
        answers =
          selectedScene.answers.length === 0
            ? [{
              answer: t().template.choose,
              sceneId: '',
            }]
            : selectedScene.answers.length <= 4
              ? selectedScene.answers.slice(0, selectedScene.answers.length).map((answ) => {
                return {
                  ...answ,
                  answer: answ.answer,
                  imageTitle: '',
                  richText: '',
                  price: '',
                  imageUrl: '',
                };
              })
              : selectedScene.answers.length > 4
                ? selectedScene.answers.slice(0, 4).map((answ) => {
                  return {
                    ...answ,
                    answer: t().template.choose,
                    imageTitle: '',
                    richText: '',
                    price: '',
                    imageUrl: '',
                  };
                })
                : selectedScene.answers.map((answ) => {
                  return {
                    ...answ,
                    answer: t().template.choose,
                    imageTitle: '',
                    richText: '',
                    price: '',
                    imageUrl: '',
                  };
                });
        break;
      case 'contact':
        answers =
          selectedScene.answers.length === 0
            ? [{
              answer: t().template.send,
              sceneId: '',
            }]
            : [selectedScene.answers.map((answ) => {
              return {
                ...answ,
                answer: t().template.send,
                sceneId: '',
                imageTitle: '',
                richText: '',
                price: '',
                imageUrl: '',
              };
            })[0]];
        break;
      case 'json':
        answers = [{ answer: t().popup.yes }];
        break;
      case 'freeText':
        answers = [{ answer: t().jsonTemplate.continue }];
        break;
      default:
        answers = [{ answer: 'Ja' }];
    }

    const updateSceneObject = {
      template: template.templateName,
      answers,
      key: Math.random() * 100000,
    };
    if (template.templateName === "contactForm") updateSceneObject.contact = { emailLink: user.email }
    if (template.templateName === "freeText") updateSceneObject.freeText = {
      description: '',
      inputType: 'text',
      placeholder: t().sceneTab.placeholder,
      required: false,
      defaultAnswer: t().sceneTab.noAnswer
    }

    return {
      value: template.templateName,
      label: t().addTab[template.transKey],
      onClick: () => {
        dispatch(updateScene(token, selectedScene._id, updateSceneObject, projectHasDraftScenes));
      },
    }
  })
}
