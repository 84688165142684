import classNames from 'classnames';

import styles from './Toggle.module.scss';

function Toggle(props) {
	return (
		<label className={classNames(styles.switch, props.single && styles.singleChoice)}>
			<input type="checkbox" checked={props.checked} onChange={() => props.onChange()} />
			<span className={classNames(
				styles.slider,
				styles.round,
				props.disabled && styles.disabled,
				props.checked && styles.checked
			)}></span>
		</label>
	);
}

export default Toggle;
