import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import SidebarBox from '../../components/SidebarBox/SidebarBox';
import Answer from '../../components/Answer/Answer';
import { updateSceneAnswer, updateAnswer, removeAnswer } from '../../actions/scenesActions';
import { useTranslation } from '../../utils/useTranslation';
import { showModal, hideModal } from '../../actions/modalActions';

import styles from './AnswersBox.module.scss';
import { ReactComponent as PlusIco } from '../../assets/icons/plus-circle.svg';

function AnswersBox(props) {
  const t = useTranslation();
  const dispatch = useDispatch();
  const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

  const answers =
    props.selectedScene &&
    props.selectedScene.answers.map((answer, index) => {
      return (
        <Answer
          key={index}
          index={index}
          answer={answer}
          scenes={props.scenes}
          selectedScene={props.selectedScene}
          token={props.token}
          dispatch={props.dispatch}
          updateSceneAnswer={updateSceneAnswer}
          updateAnswer={updateAnswer}
          removeAnswer={removeAnswer}
        />
      );
    });

  function showPopup(title) {
    dispatch(
      showModal('POPUP_MODAL', {
        title: title,
        buttonPrimary: {
          label: t.popup.ok,
          action: () => {
            dispatch(hideModal());
          },
        },
      })
    );
  }

  function addAnswer() {
    if (!props.selectedScene) return;
    const currentNumAnswers = props.selectedScene.answers.length;
    switch (props.selectedScene.template) {
      case 'images':
      case 'html':
        if (currentNumAnswers === 8) {
          showPopup(t.sceneTab.maxEightButtons)
          return;
        };
        break
      default:
        if (currentNumAnswers === 4) {
          showPopup(t.sceneTab.maxFourButtons)
          return;
        };
    }
    props.dispatch(
      updateAnswer(
        props.token,
        props.selectedScene._id,
        currentNumAnswers,
        { answer: t.template.choose },
        projectHasDraftScenes
      )
    );
  }

  return (
    <SidebarBox>
      {answers}
      <div className={styles.inputGroup}>
        <label className={styles.label}>{t.sceneTab.addAnswer}</label>
        <PlusIco className={styles.plusIco} onClick={() => addAnswer()} />
      </div>
    </SidebarBox>
  );
}

export default AnswersBox;
