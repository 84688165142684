import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Dashboard from '../../components/Dashboard/Dashboard';
import Template from '../../components/Template/Template';
import { getScenes } from '../../actions/scenesActions';
import Button from '../../components/Button/Button';
import { useTranslation } from '../../utils/useTranslation';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { showModal, hideModal } from '../../actions/modalActions';
import { fetchCopyProject, fetchProjects } from '../../actions/projectsActions';
import { getUser } from '../../actions/userActions';
import { ifUserRole } from '../../utils/ifUserRole';
import { isAuthor } from '../../utils/isAuthor';
import styles from './Preview.module.scss';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ROLES } from '../../utils/constants/roles';
import { getEmbedCode } from '../../utils/projectHelpers';
import { setWidgetScriptLoaded } from '../../actions/previewActions';

const WIDGET_URL = process.env.REACT_APP_WIDGET_URL

/*
	This component is obsolete now that the preview is rendered solely by the widget!

	Called by ProjectCard.js and Canvas.js like this 'history.push('/preview/' + selectedProject._id)'.
	If (props.public) use this app to show old iframe preview.
	Else a new tab to process.env.REACT_APP_WIDGET_URL should already be open.
*/
/**
	* This component is deprecated, since we use the widget for project preview.
	* The only place this is still used is for the project embedded with iFrame.
	* We will support this until September 2023.
	* TODO: Delete this file after September 2023.
	*/
const Preview = (props) => {
	const t = useTranslation();
	const params = useParams();
	const dispatch = useDispatch();
	const navigationHistory = useHistory();
	const history = useRef([]);
	const token = useSelector((state) => state.auth.token);
	const scenes = useSelector((state) => state.scenes.data);
	const projects = useSelector((state) => state.projects.data);
	const user = useSelector((state) => state.user);
	const widgetScriptLoaded = useSelector((state) => state.preview.widgetScriptLoaded)

	const [selectedProject, setSelectedProject] = useState({});
	const [currentScene, setCurrentScene] = useState();
	// eslint-disable-next-line no-unused-vars
	const [firstSceneId, setFirstSceneId] = useState('');
	const [embedCode, setEmbedCode] = useState('');
	const [isThereFirstScene, setIsThereFirstScene] = useState(false);
	const [breadcrumbs, setBreadcrumbs] = useState([]);

	useEffect(() => {
		/* avoid error caused by reloading widget script when it is already loaded
		   by forcing a page reload if widget script is loaded */
		if (widgetScriptLoaded === true) {
			dispatch(setWidgetScriptLoaded(false));
			window.location.reload();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(getScenes(token, params.id));
		if (token) {
			dispatch(getUser(token));
		}
	}, [dispatch, token, params.id]);

	useEffect(() => {
		if (projects.length > 0) {
			setSelectedProject(projects.filter((project) => project._id.toString() === params.id.toString())[0]);
		}
	}, [params.id, projects]);

	useEffect(() => {
		if (selectedProject._id) {
			if (!props.public && !ifUserRole(ROLES.ADMIN) && selectedProject.userId !== localStorage.getItem('id')) {
				navigationHistory.replace('/previewPublic/' + params.id.toString());
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigationHistory, params.id, props.public, selectedProject, ifUserRole(ROLES.ADMIN)]);

	useEffect(() => {
		let breadcrumbsCopy = breadcrumbs;
		currentScene && breadcrumbsCopy.push(currentScene);
		setBreadcrumbs(breadcrumbsCopy);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentScene]);

	const setFirstScene = useCallback(() => {
		if (scenes.length === 1) setCurrentScene(scenes[0]);
		else if (scenes.length > 1) {
			let sceneExists = false;
			for (let scene of scenes) {
				if (scene.isFirstScene) {
					sceneExists = true;
					setIsThereFirstScene(true);
					setCurrentScene(scene);
					break;
				}
			}
			!sceneExists && showStartScenePopup();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scenes]);

	useEffect(() => {
		setFirstScene();
	}, [scenes, setFirstScene]);

	useEffect(() => {
		if (selectedProject._id)
			// setEmbedCode(`
		  // <div style="position: relative;
			//   					overflow: hidden;
			//   					width: 100%;
			//   					padding-top: 56.25%;
			//   				 "
			//  >
			// 	<iframe style="position: absolute;
			// 	               top: 0;
			// 	               left: 0;
			// 	               bottom: 0;
			// 	               right: 0;
			// 	               width: 100%;
			// 	               height: 100%;
			// 	               border: none;
			// 	              "
			// 			src="${window.location.href.replace('preview', 'previewPublic')}/widget"
			// 			title="${selectedProject._id.toString()}"
			// 	></iframe>
		  // </div>
			// `);
			setEmbedCode(getEmbedCode(selectedProject._id.toString()));
	}, [selectedProject]);

	/**
	 * Set the current scene and pass the answer label to the history list
	 * @param id - Scene ID
	 * @param answerLabel string
	 */
	const setCurrentSceneById = (id, answerLabel = '') => {
		if (id)
			for (let scene of scenes) {
				if (scene._id.toString() === id.toString()) {
					setCurrentScene(scene);
					history.current.push({
						from: currentScene._id,
						to: scene._id,
						fromScene: currentScene,
						fromSceneLabel: answerLabel,
						toScene: scene,
					});
					break;
				}
			}
	};

	const findPreviousOfId = (id) => {
		let breadcrumbsCopy = breadcrumbs;
		breadcrumbsCopy.splice(-1); // removing last scene from where back button is pressed
		let lastScene = breadcrumbsCopy.splice(-1); // getting scene that was before scene where back button is pressed
		return lastScene[0]._id;
	};

	// This part has been refactored to a reusable function in Utils directory
	// const copyToClipboard = (text) => {
	// 	var dummy = document.createElement('textarea');
	// 	document.body.appendChild(dummy);
	// 	dummy.value = text;
	// 	dummy.select();
	// 	let result = document.execCommand('copy');
	// 	document.body.removeChild(dummy);
	// 	if (result) {
	// 		showPopup(t.preview.copiedToClipboard);
	// 	}
	// };

	const copyProject = async (projectId) => {
		fetchProjects(token).then(async (resJson) => {
			if (resJson.error) {
				console.log('error', resJson.error);
			} else {
				if (resJson.projects.length < 3 || (user && ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL))) {
					showPopup(t.projectList.pleaseWaitForCopy, "");
					if (await fetchCopyProject(token, projectId)) {
						navigationHistory.push('/projectList');
					}
				} else {
					showPopup(t.projectList.maxNumberOf3, t.projectList.contactSupport);
				}
			}
		});
	};

	function showPopup(title, subtitle) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				subtitle: subtitle,
				buttonPrimary: {
					label: t.popup.ok,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function showStartScenePopup() {
		if (isThereFirstScene === false) {
			props.public
				? dispatch(
						showModal('POPUP_MODAL', {
							title: t.preview.sceneInMaintenance,
						})
				  )
				: dispatch(
						showModal('POPUP_MODAL', {
							title: t.preview.chooseStartScene,
							buttonPrimary: {
								label: t.popup.ok,
								action: () => {
									dispatch(hideModal());
									navigationHistory.push('/ProjectEditor/' + selectedProject._id);
								},
							},
						})
				  );
		}
	}

	const getHistoryList = () => {
		let tmp = [];
		let scenes = {};
		for (let h of history.current) {
			let from = h.fromScene;
			let answ = from.answers.filter((a) => a.sceneId === h.to).map((x) => x.answer);
			if (Array.isArray(answ) && answ.length === 0) answ = 'Zurück Button';
			else {
				answ = answ[0];
				if (h.fromSceneLabel && h.fromSceneLabel !== '') {
					answ = h.fromSceneLabel;
				}
			}

			let price = h.fromScene.price && h.fromScene.price;

			tmp.push(`(${from.title}): ${from.question} => ${answ}`);

			//Possibly we are gonna use this in the future
			// historyArray.push({
			// 	title: from.title,
			// 	question: from.question,
			// 	answer: from.answer ? from.answer : answ,
			// 	price: price ? price : null,
			// });

			Object.assign(scenes, {
				[from.title + '.title']: from.title,
				[from.title + '.price']: price ? price : null,
				[from.title + '.question']: from.question,
				[from.title + '.answer']: from.answer ? from.answer : answ,
			});

		}
		return {
			history: tmp,
			// historyArray: historyArray,
			scenes: scenes,
			projectName: selectedProject.title,
		};
	};

	if (props.public) {
		const widgetClass = props.widget && styles.widget;
		return (
				<div className={classNames(styles.container, { [styles.containerPublic]: props.public }, widgetClass)}>
					<div className={classNames(styles.scene, styles.publicScene)}>
						{currentScene && (
							<Template
								scene={currentScene}
								goTo={setCurrentSceneById}
								project={selectedProject}
								templateType={currentScene.template}
								goBack={() => {
									let previous = findPreviousOfId(currentScene._id);
									if (previous) setCurrentSceneById(previous);
								}}
								backButton={currentScene.hasBackButton}
								firstSceneId={firstSceneId}
								hideSceneName
								history={getHistoryList()}
								showAudioplayer={true}
								autoplay={true}
								showVideo={true}
								sidebar={false}
							/>
						)}
					</div>
					{token && token !== '' && !props.widget && isThereFirstScene && (
						<div className={styles.buttonRow}>
							<div className={styles.buttonWrapper}>
								<Button label={t.preview.makeCopy} onClick={() => copyProject(selectedProject._id)} primary />
							</div>
						</div>
					)}
				</div>
		);
	} else {
		// new tab with widget preview should already be open
		return (
			<Dashboard>
				<div className={classNames(styles.widgetContainer, { [styles.containerPublic]: props.public })}>
					<h1
						className={styles.projectTitle}
						onClick={() => {
							navigationHistory.goBack();
						}}
					>
						<ChevronLeft className={styles.ico} />
						Back
					</h1>
					<div className={styles.widgetScene}>
						{currentScene && (
							<>
								<Helmet onChangeClientState={() => dispatch(setWidgetScriptLoaded(true))}>
									<script src={WIDGET_URL} defer></script>
								</Helmet>
								<div
									className="five-digital-berater-widget"
									data-berater={params.id}
								>
								</div>
							</>

						)}
					</div>
					{(ifUserRole(ROLES.ADMIN) || isAuthor(selectedProject.userId)) && isThereFirstScene && (
						<div className={styles.widgetButtonRow}>
							<div className={styles.buttonWrapper}>
								<Button
									label={t.preview.copyLink}
									onClick={() =>
										copyToClipboard(`${process.env.REACT_APP_WIDGET_URL}/berater/${params.id}`, showPopup, t.popup.linkCopiedToClipboard)
									}
									primary
								/>
							</div>
							<div className={styles.buttonWrapper}>
								<Button
									label={t.preview.copyEmbedCode}
									onClick={() => {
										getHistoryList();
										return copyToClipboard(embedCode, showPopup, t.popup.codeCopiedToClipboard);
									}}
									primary
								/>
							</div>
							<div className={styles.buttonWrapper}>
								<Button
									label={t.preview.edit}
									onClick={() => {
										if (window.innerWidth < 992) {
											showPopup(t.projectList.onlyLargeScreen);
										} else {
											navigationHistory.push('/ProjectEditor/' + params.id);
										}
									}}
									primary
								/>
							</div>
							<div className={styles.buttonWrapper}>
								<Button label={t.preview.makeCopy} onClick={() => copyProject(selectedProject._id)} primary />
							</div>
						</div>
					)}
				</div>
			</Dashboard>
		);

	}
};

export default Preview;
