export const baseBlueColor = '#0085ff'; //#blue
export const orange = '#fa991c'; //#orange

const error = '#ff0000'; //#red same as in styles/_colors.scss
export const colors = Object.freeze({
	blue: baseBlueColor,
	accent: orange,
	error: error,
	orange: orange
})
// export const baseBlueColor = '#fa991c';
