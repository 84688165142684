import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../actions/userActions';
import { getAnalytics } from '../../actions/analyticActions';
import Dashboard from '../../components/Dashboard/Dashboard';
import { Link } from 'react-router-dom';
import styles from './Analytics.module.scss';

function Analytics() {
	const user = useSelector((state) => state.user);
	const token = useSelector((state) => state.auth.token);
	const analytics = useSelector((state) => state.analytics);
	const [search, setSearch] = useState('');
	const [allAnalytics, setAllAnalytics] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		let foundedProjects = [];
		if (search.length > 0) {
			for (let analytic of analytics) {
				if (analytic.projectID.includes(search) || analytic.title.includes(search)) {
					foundedProjects.push(analytic);
				}
			}
			setAllAnalytics(foundedProjects);
		} else {
			setAllAnalytics(analytics);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		dispatch(getUser(token));
	}, [dispatch, token]);

	useEffect(() => {
		dispatch(getAnalytics(user._id));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		setAllAnalytics(analytics);
	}, [analytics]);

	const tableRows = allAnalytics.map((analytic, index) => {
		return (
			<tr className={styles.tr} key={index}>
				<td className={styles.td}>{analytic.title}</td>
				<td className={styles.td}>{analytic.projectID}</td>
				<td className={styles.td}>{analytic.views}</td>
				<td className={styles.td}>{analytic.startedSessions}</td>
				<td className={styles.td}>{analytic.completedSessions}</td>
				<td className={styles.td}>
					<Link to={'/sessions/' + analytic.projectID} className={styles.link}>
						Details
					</Link>
				</td>
			</tr>
		);
	});

	return (
		<div>
			<Dashboard>
				<div className={styles.container}>
					<div className={styles.searchBar}>
						<label>Search by Title or ID</label>
						<input
							id="myTitle"
							type="text"
							value={search}
							name="search"
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							className={styles.titleInput}
						/>
						<button className={styles.xButton} onClick={() => setSearch('')}>
							X
						</button>
					</div>
					<table className={styles.table}>
						<thead>
							<tr className={styles.tr}>
								<th className={styles.th}>Project Name</th>
								<th className={styles.th}>Project ID</th>
								<th className={styles.th}>Views</th>
								<th className={styles.th}>Started</th>
								<th className={styles.th}>Submited</th>
								<th className={styles.th}>Details</th>
							</tr>
						</thead>
						<tbody>{tableRows}</tbody>
					</table>
				</div>
			</Dashboard>
		</div>
	);
}

export default Analytics;
