import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toast from "react-hot-toast";
import TwoBtnToast from "../ToastJsx/TwoBtnToast";
import { ReactComponent as EditIcon } from '../../assets/icons/edit-proj-card.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-proj-card.svg';
import { ReactComponent as EmbedIcon } from '../../assets/icons/embed.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user-proj-card.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/analytics.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-proj-card.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import { showModal, hideModal } from '../../actions/modalActions';
import { useTranslation } from '../../utils/useTranslation';
import { ifUserRole } from '../../utils/ifUserRole';
import { isAuthor } from '../../utils/isAuthor';
import { fetchProjects } from '../../actions/projectsActions';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { ROLES } from '../../utils/constants/roles';
import { getEmbedCode } from '../../utils/projectHelpers';
import styles from './ProjectCard.module.scss';

const WIDGET_PREVIEW_URL = process.env.REACT_APP_WIDGET_URL

// called by ProjectList.js
function ProjectCard(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useTranslation();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.user);

  const embedCode = getEmbedCode(props._id);

  const showEditIcon = ifUserRole(ROLES.ADMIN) || isAuthor(props.projectCreatorId);
  const showCopyIcon = ifUserRole(ROLES.ADMIN) || isAuthor(props.projectCreatorId);
  const showEmbedIcon = ifUserRole(ROLES.ADMIN) || isAuthor(props.projectCreatorId);
  const showShareIcon = ifUserRole(ROLES.ADMIN) || isAuthor(props.projectCreatorId);
  const showSubmissionIcon = (ifUserRole(ROLES.ADMIN) || isAuthor(props.projectCreatorId)) && props.saveSubmissionsToDB;
  const showAnalyticsIcon =  ifUserRole(ROLES.ADMIN) ||  (ifUserRole(ROLES.PROFESSIONAL) && isAuthor(props.projectCreatorId));
  const showDeleteIcon = ifUserRole(ROLES.ADMIN) || isAuthor(props.projectCreatorId);

  const editProject = (id) => {
    if (window.innerWidth < 992) {
      props.showPopup();
    } else {
      history.push('/ProjectEditor/' + id);
    }
  };

  const copyProject = () => {
    if (props.hasUnpublishedChanges) {
      dispatch(
        showModal('POPUP_MODAL', {
          title: t.projectList.publishBeforeCopy,
          buttonPrimary: {
            label: t.popup.ok,
            action: () => dispatch(hideModal())
          },
        })
      );
    } else {
      fetchProjects(token).then((resJson) => {
        if (resJson.error) {
          console.log('error', resJson.error);
        } else {
          if (resJson.projects.length < 3 || (user && ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL))) {
            props.copyProject();
            showPopup(t.projectList.pleaseWaitForCopy, "");
          } else {
            showPopup(t.projectList.maxNumberOf3, t.projectList.contactSupport);
          }
        }
      });
    }
  };
  function showPopup(title, subtitle) {
    dispatch(
      showModal('POPUP_MODAL', {
        title: title,
        subtitle: subtitle,
        buttonPrimary: {
          label: t.popup.ok,
          action: () => {
            dispatch(hideModal());
          },
        },
      })
    );
  }

  function deleteProject() {
    if (props.hasUnpublishedChanges) {
      dispatch(
        showModal('POPUP_MODAL', {
          title: t.projectList.publishBeforeDelete,
          buttonPrimary: {
            label: t.popup.ok,
            action: () => dispatch(hideModal())
          },
        })
      );
    } else {
      toast(toastMsg => <TwoBtnToast
          title={t.projectList.confirmDelete}
          btn1Label={t.popup.cancel}
          btn1Fn={()=> setTimeout( () => toast.dismiss(toastMsg.id), 200)}
          btn2Label={t.popup.confirm}
          btn2Fn={() => {
                    props.deleteProject();
                    toast.success(t.projectList.pleaseWaitForDeletion);
                    setTimeout( () => toast.dismiss(toastMsg.id), 200)
                  }}
        />, {
          duration: 6000
        }
      );
    }
  }

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <h1 className={styles.title}>{props.title}</h1>
      </div>
      <p className={styles.description}>
        {props.description.length > 170 ? props.description.slice(0, 167) + '...' : props.description}
      </p>
      <div className={styles.iconsRow}>
        {showEditIcon && <EditIcon className={styles.editIcon} onClick={() => editProject(props._id)} title={t.projectList.editProject} />}
        {showCopyIcon && <CopyIcon className={styles.copyIcon} onClick={() => copyProject(props._id)} title={t.projectList.copyProject} />}
        {showEmbedIcon && (
          <EmbedIcon className={styles.embedIcon}
                     onClick={() => copyToClipboard(embedCode, t.popup.codeCopiedToClipboard)}
                     title={t.preview.copyEmbedCode}
          />
        )}
        {showShareIcon && (
          <ShareIcon
            className={styles.shareIcon}
            onClick={() => copyToClipboard(`${WIDGET_PREVIEW_URL}/project/${props._id}`, t.popup.linkCopiedToClipboard)}
            title={t.projectList.shareProjectURL}
          />
        )}
        {showSubmissionIcon && (
          <UserIcon className={styles.userIcon} onClick={() => history.push(`/submissions/${props._id}`)} title={t.projectList.viewSubmissions} />
        )}
        {showAnalyticsIcon && (
          <AnalyticsIcon className={styles.analyticsIcon} onClick={() => history.push(`/sessions/${props._id}`)} title={t.projectList.viewSessions} />
        )}
        {showDeleteIcon && <DeleteIcon className={styles.deleteIcon} onClick={deleteProject} title={t.projectList.deleteProject} />}
      </div>
      <div className={styles.imageContainer}>
        {props.projectImage && <img className={styles.image} src={props.projectImage} alt="Project background" />}
        <PlayIcon
          className={styles.playIcon}
          alt="Play placeholder"
          onClick={() => window.open(`${WIDGET_PREVIEW_URL}/project/${props._id}`, '_blank')}
        />
      </div>
    </div>
  );
}

export default ProjectCard;
