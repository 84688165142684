import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import { ReactComponent as MinusIco } from '../../assets/icons/minus-circle.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-bgcolor.svg';
import Input from '../../components/Input/Input';
import SelectInput from '../../components/SelectInput/SelectInput';
import Toggle from '../../components/Toggle/Toggle';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import RichText from '../RichText/RichText';
import { showModal, hideModal } from '../../actions/modalActions';
import { useTranslation } from '../../utils/useTranslation';
import { validateUrl } from '../../utils/validateUrl';
import { deleteImage } from "../../utils/deleteImage";
import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';
import styles from './Answer.module.scss';


const MAX_ANSWER_BUTTON_CHARACTER_LENGTH = 24

/* Called from AnswersBox.js for images and columns templates,
		and from ContactBoxOld-Old.js, ContactBox.js and FreeTextBox.js. */
function Answer({ scenes, selectedScene, token, answer, index, updateAnswer, updateSceneAnswer, removeAnswer }) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

	let maxAnswerBtnCharLength = MAX_ANSWER_BUTTON_CHARACTER_LENGTH
	if (ifUserRole(ROLES.ADMIN)) maxAnswerBtnCharLength = 1000

	function showPopup() {
		dispatch(
			showModal('POPUP_MODAL', {
				title: t.sceneTab.minOneAnswer,
				buttonPrimary: {
					label: t.popup.ok,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function renderAnswerRow(answer, index) {
		return (
			<div className={styles.answerGroup} key={index}>
				<div className={styles.answerInputContainer}>
					<label className={styles.answerLabel}>{t.sceneTab.answer}</label>
					<Input
						type="text"
						name="answer"
						value={answer.answer}
						onChange={(e) => {
							if (e.target.value.length <= maxAnswerBtnCharLength) {
								dispatch(updateSceneAnswer(selectedScene._id, index, e.target.name, e.target.value));
							}
						}}
						onBlur={() => {
							dispatch(updateAnswer(token, selectedScene._id, index, answer, projectHasDraftScenes));
						}}
						secondary
						error={answer.answer?.length >= maxAnswerBtnCharLength && t.sceneTab.errors.answer}
						errorstyle={{ fontSize: 12, marginTop: -6, left: 0}}
					/>
				</div>
				<MinusIco
					className={styles.minusIco}
					onClick={() => {
						if (
							selectedScene.answers.length > 1 ||
							selectedScene.template === 'text' ||
							selectedScene.template === 'text_image' ||
							selectedScene.template === 'video'
						) {
							dispatch(removeAnswer(token, selectedScene._id, index, projectHasDraftScenes));
						} else {
							showPopup();
						}
					}}
				/>
			</div>
		);
	}

	const isColumnsOrImages = template => {
		return (template === 'columns' || template === 'images')
	}

	const renderLinkRow = (answer, index) => {
		return (
			<div className={styles.inputGroup}>
				{renderToggle(answer, index)}
				{renderLink(answer, index)}
				{renderOpenInNewTabToggle(answer, index)}
			</div>
		);
	}

	function renderOpenInNewTabToggle(answer, index) {
			return answer.external && (
				<div className={classNames(styles.inputToggle, styles.newTabToggle)}>
					<Toggle
						checked={answer.newTab}
						single={true}
						onChange={() => {
							dispatch(
								updateAnswer(
									token,
									selectedScene._id,
									index,
									{ newTab: !answer.newTab },
									projectHasDraftScenes
								)
							);
						}}
					/>
					<label className={styles.label}>{t.sceneTab.openInNewTab}</label>
				</div>
			);
	}

	function renderToggle(answer, index) {
		return (
			<div className={styles.inputToggle}>
				<label className={styles.label}>{t.sceneTab.scene}</label>
				<Toggle
					checked={answer.external}
					onChange={() => {
						dispatch(
							updateAnswer(
								token,
								selectedScene._id,
								index,
								{ external: !answer.external, sceneId: '' },
								projectHasDraftScenes
							)
						);
					}}
				/>
				<label className={styles.label}>{t.sceneTab.url}</label>
			</div>
		);
	}

	function renderScenesOptions(actionIndex) {
		const sceneOptions = scenes.map((scene) => {
			return {
				value: scene._id,
				label: scene.title,
				onClick: () => {
					dispatch(
						updateAnswer(
							token,
							selectedScene._id,
							actionIndex,
							{ sceneId: scene._id },
							projectHasDraftScenes
						)
					);
				},
			};
		});
		sceneOptions.push({
			value: '',
			label: t.sceneTab.removeLink,
			onClick: () => {
				dispatch(
					updateAnswer(
						token,
						selectedScene._id,
						actionIndex,
						{ sceneId: '' },
						projectHasDraftScenes
					)
				);
			},
		});
		return sceneOptions;
	}

	function renderLink(answer, index) {
		if (answer.external) {
			return (
				<div className={classNames(styles.zIndex)}>
					<Input
						type="text"
						name="url"
						value={answer.url}
						onChange={(e) => {
							dispatch(updateSceneAnswer(selectedScene._id, index, e.target.name, e.target.value));
						}}
						onBlur={() => {
							if (validateUrl(answer.url)) {
								dispatch(
									updateAnswer(
										token,
										selectedScene._id,
										index,
										{ url: answer.url },
										projectHasDraftScenes
									)
								);
							}
						}}
						placeholder="https://"
						ternary
						error={answer.url?.length > 0 && !validateUrl(answer.url) && t.sceneTab.errors.link}
						errorstyle={{ fontSize: '12px' }}
					/>
				</div>
			);
		} else {
			return (
				<div className={classNames(styles.zIndex)}>
					<SelectInput options={renderScenesOptions(index)} selected={answer.sceneId} ternary />
				</div>
			);
		}
	}

	function renderImageRow(answer, index) {  // only for 'images' and 'columns' templates
		return (
			<React.Fragment>
				<div className={styles.inputGroup}>
					<label className={styles.label}>{t.sceneTab.image}</label>
					<div className={styles.imageRow}>
						<ImageUpload
							onUpload={(imageUrl, imagePublicId) => {
								dispatch(
									updateAnswer(
										token,
										selectedScene._id,
										index,
										{ ...answer, imageUrl, imagePublicId },
										projectHasDraftScenes
									)
								);
							}}
							imagePublicId={answer.imagePublicId}
							imageUrl={answer.imageUrl}
							index={index}
							token={token}
						/>
						<span className={styles.condition}>{t.imageUpload.minMaxImageSize}</span>
						{answer.imagePublicId &&
							<span className={styles.delete}>
								<DeleteIcon
									className={styles.deleteIcon}
									onClick={() => deleteImage(token, 'images_or_columns', selectedScene, updateAnswer, dispatch, index)}
									title={t.generalTab.deleteImage}
								/>
							</span>
						}
					</div>
					<div className={styles.inputGroup}>
						<label className={styles.label}>{t.sceneTab.imageDescription}</label>
						<Input
						type="text"
						name="imageDescription"
						value={answer.imageDescription}
						onChange={(e) => dispatch(
							updateSceneAnswer(selectedScene._id, index, e.target.name, e.target.value)
						)}
						onBlur={() =>
							dispatch(
								updateAnswer(
									token,
									selectedScene._id,
									index,
									{ imageDescription: answer.imageDescription },
									projectHasDraftScenes
								)
							)
						}
						secondary
						/>
					</div>
					{ifUserRole(ROLES.ADMIN) && <div className={styles.inputToggle}>
						<Toggle
							single={true}
							checked={answer.retrieveOrigSize}
							onChange={() => {
								dispatch(
									updateAnswer(
										token,
										selectedScene._id,
										index,
										{ ...answer, retrieveOrigSize: !answer.retrieveOrigSize },
										projectHasDraftScenes
									)
								);
							}}
						/>
						<label className={styles.label}>{t.imageUpload.retrieveOrigSize}</label>
					</div>}
				</div>
				{selectedScene.template === 'columns' && (
					<>
						<div className={styles.inputGroup}>
							<label className={styles.label}>{t.sceneTab.imageTitle}</label>
							<Input
								type="text"
								name="imageTitle"
								value={answer.imageTitle}
								onChange={(e) => {
									if (e.target.value.length <= 15) {
										dispatch(
											updateSceneAnswer(selectedScene._id, index, e.target.name, e.target.value)
										);
									}
								}}
								onBlur={() => {
									dispatch(
										updateAnswer(
											token,
											selectedScene._id,
											index,
											{ imageTitle: answer.imageTitle },
											projectHasDraftScenes
										)
									);
								}}
								secondary
								error={answer.imageTitle && answer.imageTitle.length >= 15 && t.sceneTab.errors.answer}
								errorstyle={{ fontSize: '12px' }}
							/>
						</div>
						<div className={styles.inputGroup}>
							<label className={styles.label}>{t.sceneTab.answerRichTextLabel}</label>
							<RichText
								selectedScene={selectedScene}
								onBlur={() => {
									dispatch(
										updateAnswer(
											token,
											selectedScene._id,
											index,
											{ richText: answer.richText },
											projectHasDraftScenes
										)
									);
								}}
								onChange={(e) => {
									dispatch(updateSceneAnswer(selectedScene._id, index, 'richText', e));
								}}
								defaultValue={answer.richText}
							/>
						</div>
						<div className={styles.inputGroup}>
							<label className={styles.label}>{t.sceneTab.price}</label>
							<Input
								type="text"
								name="price"
								value={answer.price}
								onChange={(e) => {
									if (e.target.value.length <= 9) {
										dispatch(
											updateSceneAnswer(selectedScene._id, index, e.target.name, e.target.value)
										);
									}
								}}
								onBlur={() => {
									dispatch(
										updateAnswer(
											token,
											selectedScene._id,
											index,
											{ price: answer.price },
											projectHasDraftScenes
										)
									);
								}}
								secondary
								error={answer.price && answer.price.length >= 9 && t.sceneTab.errors.answer}
								errorstyle={{ fontSize: '12px' }}
							/>
						</div>
					</>
				)}
			</React.Fragment>
		);
	}

	if (!answer) {
		return null;
	}

	return (
		<>
			<div className={styles.answer}>
				{renderAnswerRow(answer, index)}
				{renderLinkRow(answer, index)}
				{isColumnsOrImages(selectedScene.template) && renderImageRow(answer, index)}
			</div>
			<hr className={styles.hr} />
		</>
	);
}

export default Answer;
