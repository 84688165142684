/* TODO: remove the unused action creators and corresponding reducers.
	 Then consider whether it makes sense to keep fetchGetSessions and
	 fetchDownloadSessions here, or if it would be better to move them
	 either into Sessions.js, into the Sessions folder or into a new
	 folder called 'API calls', for example.
*/
export const GET_SESSIONS = 'GET_SESSIONS';
export const CAN_ACCESS = 'CAN_ACCESS';
export const SESSIONS_ERROR = 'SESSIONS_ERROR';

const apiUrl = process.env.REACT_APP_BE_URL;
const token = localStorage?.token;

export function getSessionsAction(data) {
	return {
		type: GET_SESSIONS,
		payload: data,
	};
}

export const setSessionsError = (data) => {
	return {
		type: SESSIONS_ERROR,
		payload: data,
	};
};

export const fetchGetSessions = async (projectID, startDate, endDate, offset, maxPerPage) => {
	try {
		const sessions = await fetch(apiUrl + `/sessions/id/${projectID}`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({
				startDate,
				endDate,
				offset,
				maxPerPage
			}),
		});

		return sessions && (await sessions.json());
	} catch (error) {
		setSessionsError(error);
	}
};

/* Is this function used for anything anymore?
	 Sessions.js now calls fetchGetSessions directly without sending
	 the sessions to Redux.
*/
export const getSessions = (projectId, startDate, endDate, offset, maxPerPage) => {
	return (dispatch) => {
		fetchGetSessions(projectId, startDate, endDate, offset, maxPerPage)
		.then((resJSON) => {
			if (resJSON.sessionData === null) {
				dispatch({
					type: CAN_ACCESS,
					payload: resJSON.sessionData,
				});
			}
			if (resJSON && !resJSON?.error) {
				dispatch(getSessionsAction(resJSON));
			} else {
				console.error(resJSON.error);
				dispatch(setSessionsError(resJSON));
			}
		});
	};
};

export const fetchDownloadSessions = async (projectId, startDate, endDate) => {
	try {
		const sessions = await fetch(apiUrl + `/sessions/download/id/${projectId}`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({
				startDate: startDate,
				endDate: endDate,
			}),
		});

		return sessions && (await sessions.json());
	} catch (error) {
		console.log('Something went wrong getting sessions for download. Error: ', error);
	}
};
