import axios from "axios";
import {authHeaders} from "../utils/authHeaders";

export const GET_USER = 'GET_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_ERROR = 'USER_ERROR';
export const USER_RESPONSE = 'USER_RESPONSE';

const apiUrl = process.env.REACT_APP_BE_URL;

export function getUserAction(user) {
  return {
    type: GET_USER,
    payload: user,
  };
}
/**
 *
 * @param {string} data containing error
 * @returns {void}
 */
export const setUserError = (data) => {
  return {
    type: USER_ERROR,
    payload: data,
  };
};

export const setUserResponse = (response) => {
  return {
    type: USER_RESPONSE,
    payload: response,
  };
};

export const fetchUser = async (token) => {
  try {
    const res = await fetch(apiUrl + '/users', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const getUser = (token) => {
  return (dispatch) => {
    fetchUser(token).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setUserError(resJSON.error));
        } else {
          localStorage.setItem('username', resJSON.user.username);
          dispatch(getUserAction(resJSON.user));
        }
      } else {
        console.error(resJSON);
        dispatch(setUserError(resJSON));
      }
    });
  };
};

export const fetchUpdateUser = async (token, user) => {
  try {
    const res = await fetch(apiUrl + '/users/', {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(user),
    });
    if (res) {
      let resJSON = await res.json();
      return resJSON;
    } else {
      console.error(res);
      return undefined;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Update user
 * @param {string} token
 * @param {object} user
 */
export const updateUser = (token, user) => {
  return (dispatch) => {
    fetchUpdateUser(token, user).then((resJSON) => {
      if (resJSON) {
        if (resJSON.error) {
          console.error(resJSON.error);
          dispatch(setUserError(resJSON.error));
        } else {
          dispatch(setUserResponse(resJSON.message));
          dispatch({
            type: UPDATE_USER,
            payload: resJSON.user,
          });
        }
      } else {
        console.error(resJSON);
        dispatch(setUserError(resJSON));
      }
    });
  };
};

/**
 * Sends a request for email address change to BE and Mailjet for the
 * user to confirm via and email arriving in their inbox
 * @param newEmail string
 * @returns {Object} if all OK { message }, else { error }
 */
export const changeEmail = async (newEmail) => {
  console.log('newEmail:', newEmail)
  let result;
  await axios
    .post(
      `${apiUrl}/users/change-email`,
      { newEmail },
      authHeaders()
    ).then(res => {
      console.log(res.data)
      result = res.data;
    }).catch(err => {
      console.error(err);
      result = { error: err.toString() };
    })
  return result;
};

/**
 * User arrives here from /email-changed after clicking the confirm button on the email
 * received in their inbox
 * @param urlToken
 * @returns {Object} if all OK { message }, else { error }
 */
export const confirmEmailChanged = async (urlToken) => {
  console.log('confirmEmailChanged() route invoked!')
  let result;
  await axios
    .patch(
      `${apiUrl}/users/confirm-email-change`,
      { urlToken },
    ).then(res => {
      console.log(res.data)
      result = res.data;
    }).catch(err => {
      console.error(err);
      result = { error: err.toString() };
    })
  return result;
};

/**
 * Checks user's subscription current status in Stripe
 * @param userId string
 * @param userSubscription string
 * @returns subscriptionStatus string ('active' or 'trialing' is good, anything else is no good)
 */
export const checkSubscription = async (userId, userSubscription) => {
  let status;
  await axios
    .post(
      `${apiUrl}/subscription/check`,
      { userId, subscription: userSubscription },
      authHeaders()
    ).then(res => {
      // console.log(res)
      if (res.data.subscriptionStatus) {
        status = res.data.subscriptionStatus
      } else if (res.data.error) {
        console.error(res.data.error);
      }
    }).catch(err => console.error(err))
  return status
  // try {
  //   const res = await fetch(apiUrl + '/checkSubscription', {
  //     method: 'POST',
  //     mode: 'cors',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-type': 'application/json',
  //       Authorization: 'Bearer ' + token,
  //     },
  //     body: JSON.stringify({ userId, subscription: userSubscription }),
  //   });
  //   if (res) {
  //     let resJSON = await res.json();
  //     if (resJSON.subscriptionStatus) {
  //       return resJSON.subscriptionStatus;
  //     };
  //     if (resJSON.error) {
  //       console.error(resJSON.error);
  //     };
  //   } else {
  //     console.error(res);
  //     return undefined;
  //   }
  // } catch (error) {
  //   console.error(error);
  //   return undefined;
  // }
};
