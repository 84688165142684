import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { sendForgotPasswordEmail, setAuthError, setAuthResponse } from '../../actions/authActions';
import { useTranslation } from '../../utils/useTranslation';
import { validateEmail } from '../../utils/validateEmail';

import styles from '../Login/Login.module.scss';
import { ReactComponent as Logo } from '../../assets/images/logo-clickmaster-portrait.svg';
import mailIcon from '../../assets/icons/mail.svg';

function ForgotPassword() {
	const auth = useSelector((state) => state.auth);
	const t = useTranslation();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	useEffect(() => {
		setEmailError('');
		dispatch(setAuthError(''));
	}, [dispatch, email]);

	useEffect(() => {
		dispatch(setAuthResponse(''));
		dispatch(setAuthError(''));
	}, [dispatch]);

	function handleSubmit(e) {
		e.preventDefault();
		let error = false;
		if (email.length === 0) {
			error = true;
			setEmailError(t.login.fieldRequired);
		} else if (!validateEmail(email)) {
			error = true;
			setEmailError(t.login.emailInvalid);
		}
		if (!error) {
			dispatch(sendForgotPasswordEmail(email));
		}
	}

	function renderWarning() {
		if (auth.error !== '') {
			return auth.error;
		}
		if (auth.response !== '') {
			return auth.response;
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<div className={styles.textWrapper}>
					<Logo className={styles.logo}/>
					<h1 className={styles.title}>{t.login.welcomeTitle}</h1>
				</div>
			</div>
			<div className={styles.formContainer}>
				<form onSubmit={handleSubmit}>
					<h2 className={styles.title} style={{marginLeft: 10, marginRight: 10}}>{t.forgotPassword.forgotPassword}</h2><br/>
					<h2 className={styles.subtitle}>
						{t.forgotPassword.enterEmail}
						{t.forgotPassword.toResetPassword}
					</h2>

					<div className={styles.inputWrapper}>
						<Input
							type="text"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							error={emailError}
							placeholder={t.login.emailPlaceholder}
							icon={mailIcon}
							primary
						/>
					</div>
					<div className={classNames(styles.warning, { [styles.success]: auth.response !== '' })}>
						{renderWarning()}
					</div>
					<div className={styles.submitWrapper}>
						<Button label={t.forgotPassword.resetPassword} type="submit" primary />
					</div>
					<Link to="/login" className={styles.link}>
						{t.changePassword.backToLogin}
					</Link>
				</form>
		</div>
		</div>
	);
}

export default ForgotPassword;
