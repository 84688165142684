import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { registerUser, setAuthError, setAuthResponse } from '../../actions/authActions';
import { useTranslation } from '../../utils/useTranslation';
import { validateEmail } from '../../utils/validateEmail';

import styles from '../Login/Login.module.scss';
import { ReactComponent as Logo } from '../../assets/images/logo-clickmaster-portrait.svg';
import mailIcon from '../../assets/icons/mail.svg';
import userIcon from '../../assets/icons/user.svg';
import passwordIcon from '../../assets/icons/password.svg';
import { MIN_PASSWORD_LENGTH } from '../../utils/constants/minPwLength';

function Register() {
	const auth = useSelector((state) => state.auth);
	const t = useTranslation();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [passwordRepeat, setPasswordRepeat] = useState('');
	const [emailError, setEmailError] = useState('');
	const [usernameError, setUsernameError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [passwordRepeatError, setPasswordRepeatError] = useState('');
	const [acceptedTOS, setAcceptedTOS] = useState(false);
	const [acceptedTOSError, setAcceptedTOSError] = useState('');
	const { brandname } = useParams();

	useEffect(() => {
		setEmailError('');
		dispatch(setAuthError(''));
	}, [dispatch, email]);

	useEffect(() => {
		setUsernameError('');
		dispatch(setAuthError(''));
	}, [dispatch, username]);

	useEffect(() => {
		setPasswordError('');
		setPasswordRepeatError('');
		dispatch(setAuthError(''));
	}, [dispatch, password, passwordRepeat]);

	useEffect(() => {
		setAcceptedTOSError('');
		dispatch(setAuthError(''));
	}, [dispatch, acceptedTOS]);

	useEffect(() => {
		dispatch(setAuthResponse(''));
		dispatch(setAuthError(''));
	}, [dispatch]);

	function handleSubmit(e) {
		e.preventDefault();
		let error = false;
		if (email.length === 0) {
			error = true;
			setEmailError(t.login.fieldRequired);
		} else if (!validateEmail(email)) {
			error = true;
			setEmailError(t.login.emailInvalid);
		}
		if (username.length === 0) {
			error = true;
			setUsernameError(t.login.fieldRequired);
		}
		if (password.length === 0) {
			error = true;
			setPasswordError(t.login.fieldRequired);
		} else if (password.length < MIN_PASSWORD_LENGTH) {
			error = true;
			setPasswordError(t.register.passwordTooShort);
		} else if (password !== passwordRepeat) {
			error = true;
			setPasswordError(t.register.passwordMismatch);
		}
		if (passwordRepeat.length === 0) {
			error = true;
			setPasswordRepeatError(t.login.fieldRequired);
		} else if (password !== passwordRepeat) {
			error = true;
			setPasswordRepeatError(t.register.passwordMismatch);
		}
		if (!acceptedTOS) {
			error = true;
			setAcceptedTOSError(t.register.agreeToTOS);
		}
		if (!error) {
			dispatch(registerUser(username, email, password, true, brandname));
		}
	}

	function renderWarning() {
		if (auth.error !== '') {
			return auth.error;
		}
		if (auth.response !== '') {
			return auth.response;
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.left}>
				<div className={styles.textWrapper}>
					<Logo className={styles.logo}/>
					<h1 className={styles.title}>{t.login.welcomeTitle}</h1>
				</div>
			</div>
			<div className={styles.formContainer}>
			<form className={styles.form} onSubmit={handleSubmit}>
				<h2 className={styles.title}>{t.login.registerTitle}</h2><br/>
				<div className={styles.inputWrapper}>
					<Input
						type="text"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						error={emailError}
						placeholder={t.login.emailPlaceholder}
						icon={mailIcon}
						primary
					/>
				</div>
				<div className={styles.inputWrapper}>
					<Input
						type="text"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
						error={usernameError}
						placeholder={t.register.usernamePlaceholder}
						icon={userIcon}
						primary
					/>
				</div>
				<div className={styles.inputWrapper}>
					<Input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						error={passwordError}
						placeholder={t.login.passwordPlaceholder}
						icon={passwordIcon}
						primary
					/>
				</div>
				<div className={styles.inputWrapper}>
					<Input
						type="password"
						value={passwordRepeat}
						onChange={(e) => setPasswordRepeat(e.target.value)}
						error={passwordRepeatError}
						placeholder={t.register.repeatPasswordPlaceholder}
						icon={passwordIcon}
						primary
					/>
				</div>
				<div className={styles.checkboxWrapper}>
					<input type="checkbox" value={acceptedTOS} onChange={() => setAcceptedTOS(!acceptedTOS)} />
					<label className={styles.checkboxLabel}>
						{t.register.privacyLabel}
						<a
							href="https://croative.co/privacy/"
							target="_blank"
							rel="noreferrer"
							className={styles.checkboxLink}
						>
							{t.register.privacyLink}
						</a>
						{t.register.tosLabel}
						<a
							href="https://croative.co/tos/"
							target="_blank"
							rel="noreferrer"
							className={styles.checkboxLink}
						>
							{t.register.tosLink}
						</a>
					</label>
					<div className={classNames(styles.error, { [styles.show]: acceptedTOSError })}>
						{acceptedTOSError}
					</div>
				</div>
				<div className={classNames(styles.warning, { [styles.success]: auth.response !== '' })}>
					{renderWarning()}
				</div>
				<div className={styles.submitWrapper}>
					<Button label={t.register.registerButtonLabel} type="submit" primary />
				</div>


			</form>
				<div className={styles.noAccountContainer}>
					<h3 className={styles.noAccountLabel}>
						{t.register.alreadyHaveAccount}
					</h3>
					<Link to="/login" className={styles.link}>
						{t.register.loginButtonLabel}
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Register;
