import React from 'react';
import SidebarBox from '../SidebarBox/SidebarBox';
import styles from './SceneCodeSnippet.module.scss';
import { useTranslation } from '../../utils/useTranslation';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import {useSelector} from "react-redux";
import Toggle from '../Toggle/Toggle'


function CodeSnippetBox(props) {
	const t = useTranslation();
	const projectHasDraftScenes = useSelector((state) => state.projects.data[0].hasDraftScenes);

	return (
		<SidebarBox>
			<div className={styles.wrapper}>
				<div className={styles.checkboxWrapper}>
					<Toggle
						name="hasCodeSnippet"
						checked={props.selectedScene?.hasCodeSnippet}
						single
						onChange={(e) => {
							props.dispatch(
								props.updateScene(
									props.token,
									props.selectedScene._id,
									{ hasCodeSnippet: !props.selectedScene?.hasCodeSnippet },
									projectHasDraftScenes
								)
							);
						}}
					/>
					<label className={styles.checkboxLabel}>{t.sceneTab.codeSnippet}</label>
				</div>
				<>
					{props.selectedScene.hasCodeSnippet && (
						<CodeMirror
							value={props.selectedScene?.codeSnippet}
							className={styles.codeEditor}
							extensions={[javascript()]}
							basicSetup={{lineNumbers: false, foldGutter: false }}
							placeholder={"Write your code here"}
							minHeight={"100px"}
							onChange={ userInput => {
								props.dispatch(props.updateSceneProp(props.selectedScene._id, 'codeSnippet', userInput));
							}}
							onBlur={() =>
								props.dispatch(
									props.updateScene(
										props.token,
										props.selectedScene._id,
										{ codeSnippet: props.selectedScene.codeSnippet },
										projectHasDraftScenes
									)
								)
							}
						/>
					)}
				</>
			</div>
		</SidebarBox>
	);
}

export default CodeSnippetBox;
