import { useState } from "react";
import { Container, Form, FormControl } from "react-bootstrap";
import Button from "../Button/Button";
import RichText from "../RichText/RichText";
import { useTranslation } from '../../utils/useTranslation';
import styles from './EditContactField.module.scss';

// characters not allowed in object keys: first two not allowed by MongoDB, second two not allowed in JSON;
const NOT_ACCEPTED_CHARACTERS = ['.', '$', '"', '\\', ];

// edit fields of contact form; called from ContactBox.js
const EditContactField = ({ selectedScene, submitField, currentField = {}, fieldIndex, showSaveBtn }) => {
  const t = useTranslation();
  const [formSelect, setFormSelect] = useState(currentField.type || 'shortText');
  const [fieldName, setFieldName] = useState(currentField.name || t.sceneTab.fieldName);
  const [fieldNameErrMsg, setFieldNameErrMsg] = useState('');
  const [placeholder, setPlaceholder] = useState(currentField.placeholder || t.sceneTab.placeholder);
  const [requiredField, setRequiredField] = useState(currentField.requiredField || false);
  const [options, setOptions] = useState(currentField.options || t.sceneTab.options);
  const [checkboxText, setCheckboxText] = useState(currentField.checkboxText || t.sceneTab.checkboxText);
  const [fieldWidth, setFieldWidth] = useState(currentField.fieldWidth || '33%');

  const onFormSubmit = e => {
    e.preventDefault();
    // if new focus is still inside this form, ignore the onBlur()
    if (e.currentTarget.contains(e.relatedTarget)) return;
    // if (e.currentTarget.parentNode.contains(e.relatedTarget)) return;  // It seems the above line works just fine...
    const updatedField = {}
    updatedField.type = formSelect;
    updatedField.name = fieldName;
    updatedField.placeholder = placeholder;
    updatedField.requiredField = requiredField;
    updatedField.options = options;
    updatedField.checkboxText = checkboxText;
    updatedField.fieldWidth = fieldWidth;
    submitField(updatedField, fieldIndex);
  }

  const onFieldTypeSelect = e => {
    const type = e.target.value;
    setFormSelect(type);
    if (!currentField.options && type === "multipleChoice") setOptions(t.sceneTab.multiChoiceOptions);
  }

  return <Container className={styles.fieldEditorContainer} onBlur={onFormSubmit}>
    <Form
      // onSubmit={onFormSubmit}
      className={styles.form}
    >
      <Form.Group className={styles.fieldRow}>
        <Form.Label className={styles.fieldLabel}>
          {t.sceneTab.type}
        </Form.Label>

        <Form.Select aria-label="select form type"
                     className={styles.fieldInput}
                     onChange={e => onFieldTypeSelect(e)}
                     defaultValue={formSelect}
                     size='sm'
        >
          <option value="shortText">{t.sceneTab.shortText}</option>
          <option value="textarea">{t.sceneTab.textarea}</option>
          <option value="dropdown">{t.sceneTab.dropdown}</option>
          <option value="checkbox">{t.sceneTab.checkbox}</option>
          <option value="multipleChoice">{t.sceneTab.multipleChoice}</option>
          <option value="date">{t.sceneTab.date}</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className={styles.fieldRow}>
        <label className={styles.fieldLabel}>
          {t.sceneTab.fieldName}
        </label>
        <FormControl
          className={styles.fieldInput}
          value={fieldName}
          size={'sm'}
          onChange={(e) => {
           setFieldNameErrMsg('')
           let newStr = e.target.value;
           /** It shows user the info about unallowed characters and removes the message after 3 seconds */
           const validateCharacters = (character) => {
             if (newStr.includes(character)) {
               setFieldNameErrMsg(t.sceneTab.forbiddenCharacters);
               newStr = newStr.replaceAll(character, '');
               setTimeout(() => setFieldNameErrMsg(''), 3000);
             }
           };
           NOT_ACCEPTED_CHARACTERS.some(validateCharacters);
           setFieldName(newStr);
          }}
        />
      </Form.Group>
      {fieldNameErrMsg && <div className={styles.fieldNameErrMsg}>{fieldNameErrMsg}</div>}
      {(formSelect === 'shortText' || formSelect === 'textarea' || formSelect === 'dropdown' || formSelect === 'multipleChoice' || formSelect === 'date') &&
        <Form.Group className={styles.fieldRow}>
          <label className={styles.fieldLabel}>
            {t.sceneTab.placeholder}
          </label>
          <FormControl className={styles.fieldInput} value={placeholder} onChange={(e) => setPlaceholder(e.target.value)} size='sm' />

        </Form.Group>
      }
      {(formSelect === 'dropdown' || formSelect === 'multipleChoice') &&
        <Form.Group className={styles.fieldRow}>
          <label className={styles.fieldLabel}>
            {t.sceneTab.options}
          </label>

          <FormControl as="textarea" rows={5} value={options}
                       onChange={(e) => setOptions(e.target.value)} size='sm' />

        </Form.Group>
      }
      {(formSelect === 'checkbox') &&
        <Form.Group>
          <Form.Label>
            {t.sceneTab.checkboxText}
          </Form.Label>
          <RichText
            selectedScene={selectedScene}
            onChange={(e) => setCheckboxText(e)}
            onBlur={(link) => { if (link) setCheckboxText(link) }}
            // onBlur={() => void(0)}  // we don't need this here...
            defaultValue={checkboxText}
            light
          />
        </Form.Group>
      }
      <Form.Group className={styles.fieldRow}>
        <label className={styles.fieldLabel}>
          {t.sceneTab.fieldWidth}
        </label>

        <Form.Select aria-label="select field width"
                     className={styles.fieldInput}
                     onChange={(e)=> setFieldWidth(e.target.value)}
                     defaultValue={fieldWidth}
                     size='sm'
        >
          <option value="33%">33%</option>
          <option value="50%">50%</option>
          <option value="66%">66%</option>
          <option value="100%">100%</option>
        </Form.Select>

      </Form.Group>

      <Form.Group  className={styles.fieldRow}>
        <Form.Label className={styles.fieldLabel}>
          {t.sceneTab.requiredField}
        </Form.Label>
        <Form.Switch checked={requiredField} onChange={() => setRequiredField(!requiredField)}
                     id='form-switch'/>

      </Form.Group>

      {showSaveBtn &&
        <Button secondary small label={t.sceneTab.save}
                onClick={e => onFormSubmit(e)}
                style={{marginTop: '5px', marginLeft: '142px', fontSize: '12px', padding: '2px'}}
      />}

    </Form>
  </Container>
}

export default EditContactField;
