import React, { useState } from 'react';
import classNames from 'classnames';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import Button from '../Button/Button';
import ImageRender from "../ImageRender/ImageRender";
import imageTemplateCol from '../../assets/icons/image-template-col.svg';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { useTranslation } from '../../utils/useTranslation';
import { lightenDarkenColor } from '../../utils/lightenDarkenColor';
import getOptimizedImage from '../../utils/getOptimizedImage';
import { templateBackground } from '../../utils/templateBackground';
import styles from './ColumnsTemplate.module.scss';

/**
	* Renders 'columns' and 'images' templates on canvas
	* Most of this code is obsolete with the use of the widget
	*/
function ColumnsTemplate(props) {
	const t = useTranslation();
	const [hoverIndex, setHoverIndex] = useState(false);
	const [hoverBack, setHoverBack] = useState(false);

	function renderAnswers(answers, key) {
		return (
			<div className={classNames(styles.buttonsContainer)}>
				{answers.map((answer, index) => {
					const answerTitle = answer.imageTitle ? ` [${answer.imageTitle}]` : '';
					const answerPrice = answer.price ? ` (${answer.price})` : '';
					let historyAnswer = answer.answer + answerTitle + answerPrice;

					return (
						<div
							className={classNames(styles.buttonWrapper, {
								[styles.buttonWrapperSmall]: answers.length > 3 || props.scene.template === 'images',
							})}
							// onClick={(e) => { // obsolete with widget
							// 	e.stopPropagation();
							// 	if (answer.sceneId) {
							// 		props.goTo(answer.sceneId, historyAnswer);
							// 	} else if (answer.url) {
							// 		window.open(getValidUrl(answer.url), '_blank');
							// 	}
							// }}
							key={index}
						>
							<div className={styles.answerColumn}>
								<ImageRender
									imageUrl={
										answer.imageUrl
											? getOptimizedImage(answer.imageUrl, 200)
											: imageTemplateCol
									}
									className={classNames(styles.image, {
										[styles.imageSmall]: answers.length > 3 || props.scene.template === 'images',
									})}
								 alt={answer.imageDescription ? answer.imageDescription : 'column'}
								/>
								{answer.price && (
									<span
										className={classNames(styles.priceBubble, {
											[styles.priceBubbleSmall]: answers.length > 3 || props.scene.template === 'images',
										})}
										style={{ backgroundColor:	props.project?.general?.buttonBackgroundColor?.hex }}
									>
										<span
											className={classNames(styles.priceLabel, {
												[styles.priceLabelSmall]: answers.length > 3 || props.scene.template === 'images',
											})}
											style={{ color: props.project?.general?.buttonColor?.hex }}
										>
											{t.template.priceFrom}
										</span>
										<span
											className={classNames(styles.price, {
												[styles.priceSmall]: answers.length > 3 || props.scene.template === 'images',
											})}
											style={{ color: props.project?.general?.buttonColor?.hex }}
										>
											{answer.price}
										</span>
									</span>
								)}
								{answer.imageTitle && (
									<h3
										className={styles.imageTitle}
										style={{
											color:
												props.project &&
												props.project.general &&
												props.project.general.color &&
												props.project.general.color.hex,
										}}
									>
										{answer.imageTitle}
									</h3>
								)}
								{answer.richText && (
									<div className={classNames(styles.labels)}>
										<div
											className={styles.richText}
											dangerouslySetInnerHTML={{ __html: answer.richText }}
											style={{ color: props?.project?.general?.color?.hex }}
										/>
									</div>
								)}
								<Button
									key={answer.answer + props.scene._id}
									label={answer.answer}
									secondary
									// onClick={(e) => { // obsolete with widget
									// 	e.stopPropagation();
									// 	if (answer.sceneId) {
									// 		props.scene.price = answer.price && answer.price;
									// 		let sceneAnswer = answer.answer && answer.answer;
									// 		let sceneImageTitle = answer.imageTitle ? `[${answer.imageTitle}]` : '';
									// 		props.scene.answer = sceneAnswer + ' ' + sceneImageTitle;
									// 		props.goTo(answer.sceneId, historyAnswer);
									// 	} else if (answer.url) {
									// 		window.open(getValidUrl(answer.url), '_blank');
									// 	}
									// }}
									onMouseEnter={() => {
										if (props.setChildIndex) {
											if (key === '2') props.setChildIndex(index + 4);
											else props.setChildIndex(index);
										}
										setHoverIndex(Number(index + key));
									}}
									onMouseLeave={() => {
										if (props.setChildIndex) props.setChildIndex(null);
										setHoverIndex(false);
									}}
									style={{
										minWidth: (answers.length > 3 || props.scene.template === 'images') && '78px',
										color: props?.project?.general?.buttonColor?.hex,
										backgroundColor: props?.project?.general?.buttonBackgroundColor?.hex &&
											(hoverIndex === Number(index + key)
												? lightenDarkenColor(props.project.general.buttonBackgroundColor.hex, -40)
												: props.project.general.buttonBackgroundColor.hex),
									}}
								/>
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	function groupAnswers(answers) {
		if (answers.length === 5) {
			return (
				<>
					{renderAnswers(answers.slice(0, 3), '1')}
					{renderAnswers(answers.slice(3, 5), '2')}
				</>
			);
		} else if (answers.length === 6) {
			return (
				<>
					{renderAnswers(answers.slice(0, 3), '1')}
					{renderAnswers(answers.slice(3, 6), '2')}
				</>
			);
		} else if (answers.length === 7) {
			return (
				<>
					{renderAnswers(answers.slice(0, 4), '1')}
					{renderAnswers(answers.slice(4, 7), '2')}
				</>
			);
		} else {
			return (
				<>
					{renderAnswers(answers.slice(0, 4), '1')}
					{renderAnswers(answers.slice(4, 9), '2')}
				</>
			);
		}
	}

	return (
		<React.Fragment>
			{props.backButton && props.firstSceneId !== props.scene._id.toString() && (
				<ChevronLeft
					className={styles.backButton}
					onClick={() => props.goBack()}
					style={{
						fill:
							props.project?.general?.buttonBackgroundColor?.hex &&
							(hoverBack
								? lightenDarkenColor(props.project.general.buttonBackgroundColor.hex, -40)
								: props.project.general.buttonBackgroundColor.hex),
					}}
					onMouseEnter={() => setHoverBack(true)}
					onMouseLeave={() => setHoverBack(false)}
				/>
			)}

			{props.showAudioplayer && props.scene.audioFileUrl && (
				<AudioPlayer
					audioUrl={props.scene.audioFileUrl}
					autoplay={props.autoplay}
					isFirstScene={props.isFirstScene}
					sidebar={props.sidebar}
				/>
			)}

			<div
				className={classNames(styles.template)}
				style={templateBackground(props.project?.general?.backgroundImage, props.project?.general?.backgroundColor)}
			>
				<span className={classNames(styles.templateRow)}>
					{!props.hideSceneName && (
						<div className={styles.titleWrapper}>
							<span className={styles.title}>{props.scene && props.scene.title}</span>
						</div>
					)}
					<h1
						className={classNames(styles.question)}
						style={{ color:	props.project?.general?.color?.hex }}
					>
						{props.scene && props.scene.question}{' '}
					</h1>
					<div>
						{props.scene?.richText && (
							<p
								className={styles.richText}
								dangerouslySetInnerHTML={{ __html: props.scene.richText }}
								style={{ color: props.project?.general?.color?.hex }}
							></p>
						)}
					</div>
					{props.scene.template === 'images'
						? groupAnswers(props.scene && props.scene.answers)
						: renderAnswers(props.scene && props.scene.answers, '1')}
				</span>

				{props.children}   {/* obsolete with React-Flow */}
			</div>
		</React.Fragment>
	);
}

export default ColumnsTemplate;
