
// import classNames from 'classnames';
import moment from "moment";
import { useEffect, useState } from 'react';
import { Button, ButtonGroup, Container, Col, Dropdown, Row } from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import { Chart as ChartJS } from 'chart.js/auto'; // for some reason we need /auto here
import { Line } from 'react-chartjs-2';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { getProjects } from '../../actions/projectsActions';
import { fetchDownloadSessions, fetchGetSessions } from '../../actions/sessionsActions';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as SortIcon } from '../../assets/icons/sort-down.svg';
import { formatDate } from '../../utils/formatDate';
import { useTranslation } from '../../utils/useTranslation';
import Dashboard from '../Dashboard/Dashboard';

import styles from './Sessions.module.scss';
import { DATE_FORMAT } from '../../utils/constants/dateFormat';

const MAX_SESSIONS_PER_PAGE = 50;
const primaryDownloadExtension = 'CSV';
const secondaryDownloadExtension = 'JSON';
const CHART_OPTIONS = {
  responsive: true,
	scales: {
		x: {
			grid: {
				display: false
			},
			ticks: {
				display: false
			}
		},
	},
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			displayColors: false,
			titleMarginBottom: 0
		}
	}
};

// called from ProjectCard.js
const Sessions = (props) => {
	const t = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const token = useSelector((state) => state.auth.token);
	const projects = useSelector(state => state.projects.data);

	const [project, setProject] = useState({});
	const [sessions, setSessions] = useState({
		data: [],
		pagination: {
			totalSessions: 0,
			currentPage: 0,
			pages: 0,
		},
		stepsStarted: 0,
		sessionCompleted: 0,
		impressionsData: { xLabels: [], data: [] },
		conversionData: { xLabels: [], data: [] }
	});
	// Initialize with sessions from last 7 days
	const [filterStartDate, setFilterStartDate] = useState(moment().subtract(6, 'days'));
	const [filterEndDate, setFilterEndDate] = useState(moment());
	const [focusedInput, setFocusedInput] = useState(null);
	const [offset, setOffset] = useState(0);
	const [dateSort, setDateSort] = useState('descending');
	const [impressionsChartData, setImpressionsChartData] = useState({});
	const [conversionChartData, setConversionChartData] = useState({});

	const projectId = props.match.params.projectID;

	useEffect(()=>{
		if (projects.length < 1) {
			dispatch(getProjects(token));
		}
		const sessionProject = projects.find(item => item._id === projectId);
		if (sessionProject) setProject(sessionProject);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[projects])

	useEffect(() => {
		(async () => {
			const sessionInfo = await fetchGetSessions(
				projectId, filterStartDate, filterEndDate, offset, MAX_SESSIONS_PER_PAGE
			);
			setSessions(sessionInfo);
			setImpressionsChartData({
				labels: sessionInfo.impressionsData.xLabels,
				datasets: [
					{
						label: 'Impressions/Day',
						data: sessionInfo.impressionsData.data,
						backgroundColor: 'rgba(171,171,253, 0.1)',
						borderColor: 'rgba(171,171,253, 1)',
						borderWidth: 1,
						pointRadius: 0.5,
						// pointHoverRadius: 10,
						fill: true,
					},
				],
			});
			setConversionChartData({
				labels: sessionInfo.conversionData.xLabels,
				datasets: [
					{
						label: 'Conversions/Day',
						data: sessionInfo.conversionData.data,
						backgroundColor: 'rgba(132,224,190, 0.1)',
						borderColor: 'rgba(132,224,190, 1)',
						borderWidth: 1,
						pointRadius: 0.5,
						fill: true,
					},
				],
			});
		})();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterStartDate, filterEndDate, offset]);

	const selectDates = (selection) => {
		switch (selection) {
			case 'last 7':
				setFilterStartDate(moment().subtract(6, 'days'));
				break;
			case 'last 30':
				setFilterStartDate(moment().subtract(29, 'days'));
				break;
			case 'all':
				setFilterStartDate(moment(project.created));
				break;
			default:
				break;
		}
		setFilterEndDate(moment());
		setOffset(0);
	}

	const exportFile = async (fileType) => {
		const sessionsForDownload = await fetchDownloadSessions(
				projectId, filterStartDate, filterEndDate
		);
		return downloadFile({
			data: JSON.stringify(sessionsForDownload),
			fileName: `sessions.${fileType}`,
			fileType: `text/${fileType}`,
		});
	}

	const downloadFile = ({ data, fileName, fileType }) => {
		// Create a blob with the data we want to download as a file
		const blob = new Blob([data], { type: fileType });
		// Create an anchor element and dispatch a click event on it
		// to trigger a download
		const a = document.createElement('a');
		a.download = fileName;
		a.href = window.URL.createObjectURL(blob);
		const clickEvt = new MouseEvent('click', {
			view: window,
			bubbles: true,
			cancelable: true,
		});
		a.dispatchEvent(clickEvt);
		a.remove();
	};

	const organizeSteps = (steps) => {
		return steps.length ? (
			<div className={styles.popup}>
				<h1 className={styles.popupText}>{t.sessions.steps}</h1>
				{steps.length > 0 && (
					<ol className={styles.stepsList}>
						{steps.map((step, index) => {
							return (
								<li className={styles.popupText} key={index}>
									{step}
								</li>
							);
						})}
					</ol>
				)}
			</div>
		) : (
			<div className={styles.popup}>
				<h1 className={styles.popupText}>There aren't any Steps in this session</h1>
			</div>
		);
	}

	const tableRows = (sessions.data?.length) &&
		sessions.data.map((session, index) => {
			let disabled;
			let bgColor;
			if (!session.steps.length) {
				disabled = true;
				bgColor = '#eee'; // light gray
			} else {
				disabled = false;
				bgColor = '#FFBC6E'; // darker orange
			}
			if (session.endTime) bgColor = '#84e0be'; // darker green
			return (
				<tr className={styles.tr} key={index}>
					<td className={styles.td}>{formatDate(session.date)}</td>
					<td className={styles.td}>{session.startTime}</td>
					<td className={styles.td}><div className={styles.urlCell}>{session.sourceUrl ? session.sourceUrl : '/'}</div></td>
					<td className={styles.td}>
						<Popup
							trigger={
								<Button
									disabled={disabled}
									size={'sm'}
									style={{backgroundColor: bgColor, borderColor: bgColor, color: '#fff', borderRadius: '20px', padding: '4px 20px' }}
								>
									{t.sessions.open}
								</Button>
							}
							modal
						>
							{organizeSteps(session.steps)}
						</Popup>
					</td>
				</tr>
			);
		});

	const sortSesssions = () => {
		let sortedSsessions;
		if (dateSort === 'descending') {
			setDateSort('ascending');
			sortedSsessions = sessions.data.sort((a,b) => new Date(a.date) - new Date(b.date));
		};
		if (dateSort === 'ascending') {
			setDateSort('descending')
			sortedSsessions = sessions.data.sort((a,b) => new Date(b.date) - new Date(a.date));
		};
		setSessions({...sessions, data: sortedSsessions})
	}

	const goToNextPage = () => {
		if (sessions.pagination.currentPage < sessions.pagination.pages) {
			let newOffset = offset + MAX_SESSIONS_PER_PAGE;
			setOffset(newOffset);
		}
	}

	const goToPreviousPage = () => {
		const FIRST_PAGE = 1;
		if (sessions.pagination.currentPage > FIRST_PAGE) {
			let newOffset = offset - MAX_SESSIONS_PER_PAGE;
			setOffset(newOffset);
		}
	}

	return (
		<Dashboard>
			<Container className={styles.container}>
				<Row className='g-5'>
					<Col xs={9}>
						<Row className="my-4 d-flex justify-content-between align-items-center">
							<Col xs={3}>
								<div
									className={styles.goBack}
									onClick={() => history.push('/projectList')}
								>
									<ChevronLeft className={styles.ico} />
									{t.sessions.back}
								</div>
							</Col>
							<Col xs={9} className={styles.dateDropDown}>
								<button onClick={() => selectDates('last 7')} className={styles.presetBtn}>
									{t.sessions.past7Days}
								</button>
								<button onClick={() => selectDates('last 30')} className={styles.presetBtn}>
									{t.sessions.past30Days}
								</button>
								<button onClick={() => selectDates('all')} className={styles.presetBtn}>
									{t.sessions.allTime}
								</button>
								<DateRangePicker
									startDate={filterStartDate}
									startDateId="StartDateFilterSessions"
									endDate={filterEndDate}
									endDateId="EndDateFilterSessions"
									onDatesChange={({ startDate, endDate }) => {
										setFilterStartDate(startDate);
										setFilterEndDate(endDate);
										setOffset(0);
									}}
									focusedInput={focusedInput}
									onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
									isOutsideRange={() => false}
									displayFormat={DATE_FORMAT}
									firstDayOfWeek={1}
									small
								/>
							</Col>
						</Row>
						<Row>
							<Container className={styles.header} style={{ padding: '20px'}}>
								<Row className="my-2 d-flex justify-content-between align-items-center">
									<Col xs={9} className={styles.projectTitle}>
										{project.title || ''}
									</Col>
									<Col xs={3} style={{ textAlign: 'end'}}>
										<Dropdown as={ButtonGroup}>
											<Button variant="success"
												onClick={() => exportFile(primaryDownloadExtension)}
												style={{ backgroundColor: '#84e0be', borderColor: '#84e0be', borderRadius: '20px 0 0 20px' }}
												size='sm'
											>
												Download Reporting as {primaryDownloadExtension}
											</Button>

											<Dropdown.Toggle
												split variant="success"
												id="dropdown-split-basic"
												style={{ backgroundColor: '#84e0be', borderColor: '#84e0be', borderRadius: '0 20px 20px 0'  }}
												size='sm'
											/>

											<Dropdown.Menu>
												<Dropdown.Item onClick={() => exportFile(secondaryDownloadExtension)}>
													Download as {secondaryDownloadExtension}
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</Col>
								</Row>
								<p className={styles.projectDescription}>{project.description || ''}</p>
								<Row className="my-2 d-flex justify-content-between align-items-center">
									<Col className={styles.projectDate}>{formatDate(project.created) || ''}</Col>
									<Col className={styles.projectNumber}>Project-ID: {projectId}</Col>
								</Row>
							</Container>
						</Row>
						<Row className='my-4'>
							<Col xs={4} style={{ paddingLeft: '0'}}>
								<div className={styles.numSessions}>
									<div className={styles.cardTitle}>{t.sessions.numImpressions}</div>
									<div className={styles.mainMetric}>{sessions.pagination.totalSessions}</div>
								</div>
							</Col>
							<Col xs={4}>
								<div className={styles.started}>
									<div className={styles.cardTitle}>{t.sessions.numStarted}</div>
									<div className={styles.mainMetric}>{sessions.stepsStarted}</div>
									<div className={styles.secondMetric}>
										{sessions.pagination.totalSessions ?
											Math.round((sessions.stepsStarted/sessions.pagination.totalSessions)*10000) / 100
											: <>-</>
										}%
									</div>
								</div>
							</Col>
							<Col xs={4} style={{ paddingRight: '0'}}>
								<div
									className={styles.completed}
									onClick={() => history.push(`/submissions/${projectId}`)}
								>
									<div className={styles.cardTitle}>{t.sessions.numCompleted}</div>
									<div className={styles.mainMetric}>{sessions.sessionCompleted}</div>
									<div className={styles.secondMetric}>
										{ sessions.stepsStarted ?
											Math.round((sessions.sessionCompleted/sessions.stepsStarted)*10000) / 100
											: <>-</>
										}%
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Container className={styles.sessions} style={{ padding: '20px'}}>
								<div className={styles.sessionsTitle}>Sessions</div>
								<table className={styles.table}>
									<thead>
										<tr className={styles.tr}>
											<th className={styles.th}>
												{t.sceneTab.date}
												<span onClick={() => sortSesssions()} className={styles.sort}>
													{dateSort === 'ascending' && <SortIcon className={styles.sortUp} />}
													{dateSort === 'descending' && <SortIcon className={styles.sortDown} />}
												</span>
											</th>
											<th className={styles.th}>Start Time</th>
											<th className={styles.th}>URL</th>
											<th className={styles.th}>{t.sessions.steps}</th>
										</tr>
									</thead>
									<tbody>{tableRows || null}</tbody>
								</table>
								<ul className={styles.navigation}>
									<li>
										<Button variant={"link-outline"} className={styles.navigationButton} onClick={() => goToPreviousPage()}>
											{t.sessions.back}
										</Button>
									</li>
									<li className={styles.numberOfPages}>
										{t.submissions.page} {sessions.pagination.currentPage} {t.submissions.of} {Math.max(sessions.pagination.pages,1)}
									</li>
									<li>
										<Button variant={"link-outline"} className={styles.navigationButton} onClick={() => goToNextPage()}>
											{t.submissions.next}
										</Button>
									</li>
								</ul>
							</Container>
						</Row>
					</Col>
					<Col xs={3}>
						<Row className='my-4'>
							<Container className={styles.trends} style={{ padding: '25px'}}>
								<div className={styles.trendsTitle}>Top Trends</div>
								<div className={styles.trendsSubtitle}>{t.sessions.numSessions} Trend</div>
								<div className={styles.trendsGraph}>
									{impressionsChartData.datasets &&
										<Line
											data={impressionsChartData} options={CHART_OPTIONS}
											//className={styles.graph}
										/>
									}
								</div>
								<div className={styles.trendsSubtitle}>Conversion Trend</div>
								<div className={styles.trendsGraph}>
									{conversionChartData.datasets &&
										<Line
											data={conversionChartData} options={CHART_OPTIONS}
											//className={styles.graph}
										/>
									}
								</div>
							</Container>
						</Row>
						<Row>
							<Container className={styles.callToAction} style={{ padding: '25px'}}>
								<div className={styles.ctaTitle}>{t.sessions.howToIncreaseImpressions}</div>
								<div className={styles.ctaSubtitle}>{t.sessions.opportunitiesToIncreaseVisibility}</div>
								<Button
									onClick={() => window.open('https://www.five-digital.de/berater-optimieren', '_blank')}
									style={{
										marginTop: '15px',
										backgroundColor: '#ababfd',
										border: '1px solid #ffffff',
										borderRadius: '6px',
										padding: '5px 20px',
										textTransform: 'uppercase',
										fontSize: '12px',
										boxShadow: 'none'
									}}
								>
									{t.sessions.learnMore}
								</Button>
							</Container>
						</Row>
					</Col>
				</Row>
			</Container>
		</Dashboard>
	);
}

export default withRouter(Sessions);  // adds history, location and match to props
