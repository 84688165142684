import React, { useState } from 'react';
import classNames from 'classnames';
import { lightenDarkenColor } from '../../utils/lightenDarkenColor';
import styles from './VideoTemplate.module.scss';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import Button from '../Button/Button';
import { ReactComponent as PlayIcon } from '../../assets/icons/play-circle.svg';
import { templateBackground } from '../../utils/templateBackground';


function VideoTemplate(props) {

  // eslint-disable-next-line no-unused-vars
  const [showQuestions, setShowQuestions] = useState(true); //explicitly called to force re-render (ignore const names)
  const projectColor = props.project?.general?.color?.hex;
  const projectBgColor = props.project?.general?.backgroundColor?.hex;
  const projectButtonBgColor = props.project?.general?.buttonBackgroundColor?.hex;
  const backgroundImage = props.scene.videoFileUrl
		? props.scene.videoFileUrl.slice(0, -4) + '.jpg'
    : templateBackground(props.project?.general?.backgroundImage, projectBgColor);

  // function showQuestionAndAnswers() {
  // 	if (!props.showSceneQuestions || !props.scene.showQuestionsOnStart) {
  // 		return styles.hideQuestions;
  // 	}
  // }

  function getSeconds(seconds) {
    if (
      props.scene.showQuestionsOnStart === false &&
      seconds.playedSeconds >= seconds.loadedSeconds &&
      seconds.played === 1
    ) {
      props.scene.showQuestionsOnStart = true;

      //We are calling this change state just for a re-render
      setShowQuestions(false);
    } else {
      //We are calling this change state just for a re-render
      setShowQuestions(true);
    }
  }

  function isFirstScene() {
    return props.firstSceneId === props.scene._id;
  }

  function getVideoURL() {
    let VIDEO_QUALITY = 70;
    let videoURL = props.scene.videoFileUrl;
    let videoURLSplited = videoURL.split('/');
    videoURLSplited[5] += '/q_' + VIDEO_QUALITY;
    return videoURLSplited.join('/');
  }

  return (
    <React.Fragment>
      {props.backButton && props.firstSceneId !== props.scene._id.toString() && (
        <ChevronLeft
          className={styles.backButton}
          onClick={() => props.goBack()}
          style={{
            fill: projectButtonBgColor ? lightenDarkenColor(projectButtonBgColor, -40) : projectButtonBgColor,
          }}
        />
      )}
      {props.showVideo && props.scene.videoFileUrl ? (
        <VideoPlayer
          videoUrl={getVideoURL()}
          backgroundImage={backgroundImage}
          autoplay={props.autoplay}
          getPlaySeconds={getSeconds}
          className={styles.videoPlayer}
          isFirstScene={isFirstScene()}
          sidebar={props.sidebar}
        />
      ) : (
        <div
          className={styles.template}
          style={{
          	backgroundImage: backgroundImage && 'url(' + backgroundImage + ')',
          }}
        >
					<div className={styles.templateRow}>
						{!props.hideSceneName && (
							<div className={styles.titleWrapper}>
								<span className={styles.title}>{props.scene && props.scene.title}</span>
							</div>
						)}
						<PlayIcon className={styles.playIcon} width={60} height={60} style={{ marginBottom: '15px' }} alt="Play Icon" />
						<h1
							className={styles.question}
							style={{ color: projectColor }}
						>
							{props.scene?.question}
						</h1>
						{props.scene?.richText && (
							<p
								className={styles.richText}
								dangerouslySetInnerHTML={{ __html: props.scene.richText }}
								style={{ color: projectColor }}
							></p>
						)}
						<div className={styles.buttonsContainer}>
							{props.scene?.answers?.map((answer, index) => (
								<div
									className={classNames(styles.buttonWrapper, {
										[styles.buttonWrapperSmall]: props.scene?.answers.length > 3,
									})}
									key={index}
								>
									<Button
										key={answer.answer + props.scene._id}
										label={answer.answer}
										secondary
										onClick={() => {}
										}
										style={{minWidth: props.scene.answers.length === 4 ? '80px' : ''}}
									/>
								</div>
							))}
						</div>
					</div>
					{/*{props.children}   /!* obsolete with React-Flow *!/*/}
				</div>
			)}


    </React.Fragment>
  );
}

export default VideoTemplate;
