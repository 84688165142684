import SidebarBox from '../../components/SidebarBox/SidebarBox';
import Button from '../../components/Button/Button';

import styles from './SceneEditFooterBox.module.scss';

function SceneEditFooterBox(props) {
	return (
		<div className={styles.footerBox}>
			<SidebarBox>
				<div className={styles.buttonBox}>
					<div className={styles.buttonRow}>
						<span className={styles.link} onClick={() => props.onDeleteLinkClick()}>
							{props.deleteSceneLinkLabel}
						</span>
						<Button primary label={props.buttonLabel} small onClick={() => props.onButtonClick()} />
					</div>
					{props.warning && (
						<div className={styles.warning}>
							<span>{props.warning}</span>
						</div>
					)}
				</div>
			</SidebarBox>
		</div>
	);
}

export default SceneEditFooterBox;
