import Button from "../Button/Button";
import styles from './ToastJsx.module.scss';

/**
 * Returns JSX for a toast with title, subtitle and one button, such as the 'No first scene set'
 * @param {string} title
 * @param {string} subtitle
 * @param {string} btn1Label
 * @param {function} btn1Fn
 * @returns {JSX.Element}
 * @constructor
 */
const SubtitleOneBtnToast = ({ title, subtitle, btn1Label, btn1Fn }) => {
  return <div>
    <div className={styles.title}>{title}</div>
    <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.singleBtn}>
        <Button small primary label={btn1Label} onClick={btn1Fn} />
      </div>
  </div>
}
export default SubtitleOneBtnToast;
