import React from 'react';
import SidebarBox from '../SidebarBox/SidebarBox';
import styles from './CustomCSSBox.module.scss';
import { useTranslation } from '../../utils/useTranslation';
import customClassesGenerator from '../../utils/customClassesGenerator';
import CodeMirror from '@uiw/react-codemirror';
import { css } from '@codemirror/lang-css';
import Toggle from '../Toggle/Toggle'

/* Called from SceneTab.js and GeneralTab.js. */
function CustomCSSBox(props) {
	const t = useTranslation();
	const dispatch = props.dispatch;
	const scope = props.scope;  // either 'project' (from GeneralTab.js) or 'scene' (from 'SceneTab.js')
	const updateSceneOrProject = props.updateSceneOrProject;
	const token = props.token;
	const selectedSceneOrProject = props.selectedSceneOrProject;
	const updateSceneOrProjectProp = props.updateSceneOrProjectProp;

	return (
		<SidebarBox>
			<div className={styles.wrapper}>
				<div className={styles.checkboxWrapper}>
					<Toggle
						name="hasCustomCss"
						checked={selectedSceneOrProject?.hasCustomCss}
						single
						onChange={() => {
							if (scope === 'scene') { // from SceneTab.js
								dispatch(
									updateSceneOrProject(token, selectedSceneOrProject._id, { hasCustomCss: !selectedSceneOrProject?.hasCustomCss })
								);
							} else {  // 'project' from GeneralTab.js
								dispatch(
									updateSceneOrProject(token, { ...selectedSceneOrProject, hasCustomCss: !selectedSceneOrProject?.hasCustomCss })
								);
							}
						}}
					/>
					<label className={styles.checkboxLabel}>{t.sceneTab.customCss}</label>
				</div>
				<>
					{selectedSceneOrProject?.hasCustomCss && (
						<CodeMirror
							className={styles.codeEditor}
							value={selectedSceneOrProject?.customCss ||
								 customClassesGenerator(selectedSceneOrProject, scope)
							}
							extensions={[css()]}
							basicSetup={{lineNumbers: false, foldGutter: false }}
							height="180px"
							onChange={ userInput => {
								dispatch(updateSceneOrProjectProp(selectedSceneOrProject._id, 'customCss', userInput));
							}}
							onBlur={() => {
								if (scope === 'scene') { // from SceneTab.js
									dispatch(
										updateSceneOrProject(token, selectedSceneOrProject._id, {
											customCss: selectedSceneOrProject.customCss,
										})
									);
								} else {  // 'project' from GeneralTab.js
									dispatch(
										updateSceneOrProject(token,
											{ ...selectedSceneOrProject, customCss: selectedSceneOrProject.customCss }
										)
									);
								}
							}}
						/>
					)}
				</>
				{(selectedSceneOrProject?.hasCustomCss && scope === 'project') &&
					<p className={styles.projectNumber} style={{ marginTop: '5px' }}>
						{`#${scope}-${selectedSceneOrProject._id}`}
					</p>
				}
			</div>
		</SidebarBox>
	);
}

export default CustomCSSBox;
