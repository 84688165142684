import {useDispatch} from "react-redux";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import toast from "react-hot-toast";
import Button from "../../components/Button/Button"
import TwoBtnToast from "../ToastJsx/TwoBtnToast";
import {deleteDraft} from "../../actions/projectsActions";
import {setActiveTab} from "../../actions/sidebarActions";
import {useTranslation} from "../../utils/useTranslation";
import {colors} from "../../utils/constants/colors";
import styles from "./Button.module.scss";

const WIDGET_PREVIEW_URL = process.env.REACT_APP_WIDGET_URL;

const PreviewSplitButton = ({ projectID, hasDraft = false, selectedSceneId }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const tooltipMsg = selectedSceneId ? t.canvas.startFromSelectedScene : t.canvas.startFromFirstScene;
  let previewLink = `${WIDGET_PREVIEW_URL}/project/${projectID}`;
  const draftEnding = "?draft=true";
  let previewLinkDraft = `${previewLink}${draftEnding}`;
  if (selectedSceneId) previewLink += `?start_scene=${selectedSceneId}`;
  if (selectedSceneId) previewLinkDraft += `&start_scene=${selectedSceneId}`;

  const onDeleteDraft = () => {
    // console.log("onDeleteDraft() called!")
    toast(toastMsg => <TwoBtnToast
        title={t.canvas.deleteDraftConfirm}
        btn1Label={t.popup.cancel}
        btn1Fn={()=> setTimeout( () => toast.dismiss(toastMsg.id), 200)}
        btn2Label={t.popup.confirm}
        btn2Fn={() => {
          dispatch(setActiveTab('add'));
          deleteDraft(projectID, dispatch).then();
          setTimeout( () => toast.dismiss(toastMsg.id), 200)
        }}
      />, {
        duration: 6000
      }
    );
  }

  return (
    <div className={styles.buttonContainer} title={tooltipMsg}>
      {hasDraft ? (
        <Dropdown as={ButtonGroup} style={{ height: "inherit" }}>
          <Button
            primary
            label={t.canvas.previewDraft}
            onClick={() => window.open(previewLinkDraft, "_draft")}
            style={{paddingLeft: 20, paddingRight: 20, borderRadius: "30px 0 0 30px"}}
            className={styles.button}
          />

          <Dropdown.Toggle style={{borderRadius: "0 30px 30px 0", backgroundColor: colors.accent, borderColor: colors.accent }} />

          <Dropdown.Menu align={"end"}>
            <Dropdown.Item href={previewLink} target={"_live"}>
              {t.canvas.previewLive}
            </Dropdown.Item>
            <Dropdown.Item onClick={onDeleteDraft} style={{ color: colors.error}}>
              {t.canvas.deleteDraft}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        )
        :
        (<Button
            primary
            onClick={() => window.open(previewLink, "_live")}
            style={{paddingLeft: 20, paddingRight: 20, borderRadius: 30, height: 'inherit' }}
            label={t.canvas.previewLive}
          />
        )}
    </div>
  )};

export default PreviewSplitButton;
