import { store } from '../index';

export const authHeaders = () => {
	let token = store?.getState()?.auth.token;
	return {
		headers: {
			Accept: 'application/json',
			'Content-type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}
}
