import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Dashboard from "../../components/Dashboard/Dashboard";
import Button from "../../components/Button/Button";
import {getUser} from "../../actions/userActions";
import {useTranslation} from "../../utils/useTranslation";
import styles from "../PaySuccess/PaySuccess.module.scss";

function PaySuccess() {
  const t = useTranslation();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.auth.token);

  useEffect(()=>{
    // update user in Redux
    getUser(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dashboard>
      <div className={styles.container}>
        <h1 className={styles.title}>{t.paySuccess.paySuccess}</h1>
        <div className={styles.subscriptionContainer}>
          <div className={styles.subscriptionBox}>
            <div className={styles.subscriptionLevel}>
              {t.paySuccess.myCurrentLevel}:
            </div>
            <div className={styles.role}>
              {user.role}
            </div>
          </div>
        </div>
        <div className={styles.button}>
          <Button primary label={t.paySuccess.continue}
                  onClick={() => history.push('/projectList')}
          />
        </div>
      </div>
    </Dashboard>
  );
}

export default PaySuccess;
