// format date to "DD.MM.YYYY"
export const formatDate = (value) => {
  return dateFormatter(value, 'date')
}

// format date to "DD.MM.YYYY HH:MM"
export const formatDateHHMM = (value) => {
  return dateFormatter(value, 'date and hh:mm')
}

const dateFormatter = (value, type) => {
  let date = new Date(value);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  if (type === 'date') return day + '.' + month + '.' + year;
  const hour = date.toLocaleString('default', { hour: '2-digit', hourCycle: "h24" });
  const min = date.toLocaleString('default', { minute: '2-digit' });
  return day + '.' + month + '.' + year + ' ' + hour + ':' + min;
}

